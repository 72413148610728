import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridRowModel } from '@mui/x-data-grid-pro';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import SubNav from '../../components/SubNav';
import koText from '../../util/localizationKR';
import CustomToolbar from '../../components/CustomToolbar';
import NoDataOverlay from '../../components/NoDataOverlay';
import { ReservationResponse, ReserveType } from '../../util/Interface';
import { openReserveState } from '../../atom/Atom';
import { loadingState, reserveDetail, reserveTotal } from '../../atom';
import ReserveModal from '../../components/modal/ReserveModal';
import * as subscriptions from '../../graphql/subscriptions';
import { convertReserve, findUrlField, reserveTimeSequenceSort } from '../../util/ReplaceValue';
import ErrorCheck from '../../util/ErrorCheck';
import ReserveAllCol from '../../columns/admin/ReserveAllCol';
import { fetchUser } from '../../selector';

function AdminReserveAll() {
	const [reservations, setReservations] = useRecoilState(reserveTotal);
	const [loading] = useRecoilState(loadingState);
	const setOpen = useSetRecoilState(openReserveState);
	const setScheduleDetail = useSetRecoilState(reserveDetail);
	const reset = useResetRecoilState(reserveTotal);

	useEffect(() => {
		reset();
	}, [reset]);

	const onUpdateReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateReservation,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateReservation as ReservationResponse;
					let newArray: ReserveType[] = [...reservations];
					const index = newArray.findIndex((reserve) => reserve.id === item.id);
					const reserve = convertReserve([item])[0];
					if (index !== undefined && index !== null) {
						newArray[index] = reserve;
						newArray = reserveTimeSequenceSort(newArray);
						setReservations([...newArray]);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[reservations, setReservations]
	);

	const onDeleteReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onDeleteReservation,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onDeleteReservationByDay as ReservationResponse;
					const newArray = [...reservations];
					const index = newArray.findIndex((reserve) => reserve.id === item.id);
					if (index > -1) newArray.splice(index, 1);
					setReservations(newArray);
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[reservations, setReservations]
	);

	useEffect(() => {
		const update = onUpdateReserve();
		const remove = onDeleteReserve();
		return () => {
			update.unsubscribe();
			remove.unsubscribe();
		};
	}, [onDeleteReserve, onUpdateReserve]);

	const userInfo = useRecoilValue(fetchUser);

	const location = useLocation();
	const field = findUrlField(location);

	if (!userInfo) {
		return null;
	}

	return (
		<>
			<Box px={5}>
				<SubNav />
				<div style={{ height: '80vh', width: '100%' }}>
					<DataGridPro
						rows={(reservations as GridRowModel[]) || []}
						columns={ReserveAllCol()}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						style={{ borderLeft: 'none', borderRight: 'none' }}
						localeText={koText}
						disableColumnMenu
						density={userInfo.dataGridState?.[`${field}`]?.density || 'standard'}
						initialState={{
							columns: {
								...userInfo.dataGridState?.[`${field}`]?.columns,
								columnVisibilityModel: userInfo.dataGridState?.[`${field}`]?.columns?.columnVisibilityModel || {
									staff: false,
								},
							},
							filter: userInfo.dataGridState?.[`${field}`]?.filter,
						}}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay: NoDataOverlay,
						}}
						componentsProps={{
							toolbar: { field },
						}}
						onCellClick={(params) => {
							setScheduleDetail(params.row as ReserveType);
							setOpen(true);
						}}
						hideFooter
						loading={loading}
					/>
				</div>
			</Box>
			<ReserveModal />
		</>
	);
}

export default AdminReserveAll;
