import React, { useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import SubNav from '../components/SubNav';
import RadioInput from '../components/form/RadioInput';
import MultiCheckboxInput from '../components/form/MultiCheckboxInput';
import exportAsImage from '../util/exportAsImage';
import { SurveyType } from '../util/Interface';

function Survey() {
	const defaultValues = {
		first: '',
		second: [],
		third: [],
		fourth: [],
		fifth: '',
		sixth: '',
	};
	const methods = useForm<SurveyType>({ defaultValues });
	const onSubmit: SubmitHandler<SurveyType> = (data) => {
		console.log(data);
	};

	const { handleSubmit, reset, watch } = methods;

	const exportRef = useRef();
	const [open, setOpen] = useState<boolean>(false);
	const [imgUrl, setImgUrl] = useState<string>('');

	const firstOption = [
		{ value: '1', label: '지인 및 주변분 소개' },
		{ value: '2', label: '방송, 신문 기사' },
		{ value: '3', label: '오프라인 광고' },
		{ value: '4', label: '온라인 광고' },
		{ value: '5', label: '온라인 후기' },
	];

	const firstFourOption = [
		{ value: '1', label: 'SNS' },
		{ value: '2', label: '유튜브' },
		{ value: '3', label: '웹사이트' },
	];

	const firstFiveOption = [
		{ value: '1', label: 'SNS' },
		{ value: '2', label: '블로그' },
		{ value: '3', label: '카페/커뮤니티' },
		{ value: '4', label: '웹사이트' },
	];

	const secondOption = [
		{ value: '1', label: '운전, 수업 등 먼 거리를 볼 때' },
		{ value: '2', label: '자외선 햇빛 및 블루라이트 등 눈을 보호할 때' },
		{ value: '3', label: '항상 일상생활에서 착용' },
		{ value: '4', label: 'PC, 독서 등 가까운 거리를 볼 때' },
		{ value: '5', label: '거의 착용하지 않음' },
	];

	const thirdOption = [
		{ value: '1', label: '사이즈 안 맞음' },
		{ value: '2', label: '좌우 균형 안 맞음' },
		{ value: '3', label: '무거움' },
		{ value: '4', label: '볼이 안경테에 닿음' },
		{ value: '5', label: '속눈썹이 렌즈에 닿음' },
		{ value: '6', label: '흘러내림' },
		{ value: '7', label: '코 부분 불편' },
		{ value: '8', label: '귀 부분 불편' },
		{ value: '9', label: '피부 알러지 있음' },
		{ value: '10', label: '별다른 불편 없음' },
	];

	const fourthOption = [
		{ value: '1', label: '일상용 안경' },
		{ value: '2', label: '돋보기 안경' },
		{ value: '3', label: '블루라이트, 보호용 안경' },
		{ value: '4', label: '실내용 안경' },
		{ value: '5', label: '운전용 안경' },
		{ value: '6', label: '패션을 위한 안경' },
		{ value: '7', label: '선글라스' },
	];

	const fifthOption = [
		{ value: '1', label: '예, 안경테와 렌즈 모두 상담 받고 싶어요' },
		{ value: '2', label: '아니요, 안경테만 상담 받고 싶어요' },
	];
	const sixthOption = [
		{ value: '1', label: '네, 상세하고 자세한 상담을 받고 싶어요' },
		{ value: '2', label: '아니요, 간결하고 필요한 사항만 받고 싶어요.' },
	];

	const handleImg = () => {
		exportAsImage(exportRef.current, 'img').then((res) => {
			if (res) {
				setImgUrl(res);
				setOpen(true);
			}
		});
	};

	return (
		<Box px={5}>
			<SubNav />
			<div style={{ height: '80vh', width: '100%' }}>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box mt={2}>
							<Grid container spacing={1} direction="row-reverse">
								<Grid item>
									<Button variant="contained" onClick={handleImg}>
										사진저장
									</Button>
								</Grid>
								<Grid item>
									<Button variant="contained" onClick={() => reset()}>
										리셋
									</Button>
								</Grid>
							</Grid>
						</Box>
						<Box mt={2} ref={exportRef}>
							<Box mt={1} mb={2}>
								<Box>
									<Typography fontSize={24} color="#0A3AB6" fontWeight={600}>
										<strong style={{ fontSize: '32px', fontFamily: 'Manrope', fontWeight: 500 }}>1.</strong> 브리즘을
										어떻게 알게 되셨나요?
									</Typography>
								</Box>
								<Box pl={4}>
									<RadioInput type="radio" grid="auto" field="first" options={firstOption} />
									{watch('first') === '4' && (
										<>
											<Box>
												<Typography fontSize={20}>*온라인광고를 어떤 매체에서 접하셨나요?</Typography>
											</Box>
											<RadioInput type="radio" grid="auto" field="ad" options={firstFourOption} />
										</>
									)}
									{watch('first') === '5' && (
										<>
											<Box>
												<Typography fontSize={20}>*온라인 후기를 어떤 매체에서 접하셨나요?</Typography>
											</Box>
											<RadioInput type="radio" grid="auto" field="review" options={firstFiveOption} />
										</>
									)}
								</Box>
							</Box>
							<Box mt={1} mb={2}>
								<Typography fontSize={24} color="#0A3AB6" fontWeight={600}>
									<strong style={{ fontSize: '32px', fontFamily: 'Manrope', fontWeight: 500 }}>2.</strong>{' '}
									안경(콘택트렌즈)을 언제 착용하시나요?
									<small style={{ color: '#0A3AB6', fontSize: 14, fontWeight: 500 }}> *중복 선택 가능</small>
								</Typography>
								<Box pl={4}>
									<MultiCheckboxInput type="checkbox" grid="auto" field="second" options={secondOption} />
								</Box>
							</Box>
							<Box mt={1} mb={2}>
								<Typography fontSize={24} color="#0A3AB6" fontWeight={600}>
									<strong style={{ fontSize: '32px', fontFamily: 'Manrope', fontWeight: 500 }}>3.</strong>{' '}
									안경·선글라스를 착용하실 때 불편한 점은 무엇인가요?{' '}
									<small style={{ color: '#0A3AB6', fontSize: 14, fontWeight: 500 }}> *중복 선택 가능</small>
								</Typography>
								<Box pl={4}>
									<MultiCheckboxInput type="checkbox" grid="auto" field="third" options={thirdOption} />
								</Box>
							</Box>
							<Box mt={1} mb={2}>
								<Typography fontSize={24} color="#0A3AB6" fontWeight={600}>
									<strong style={{ fontSize: '32px', fontFamily: 'Manrope', fontWeight: 500 }}>4.</strong> 오늘 어떤
									용도의 안경에 대해 상담하길 원하시나요?
									<small style={{ color: '#0A3AB6', fontSize: 14, fontWeight: 500 }}> *중복 선택 가능</small>
								</Typography>
								<Box pl={4}>
									<MultiCheckboxInput type="checkbox" grid="auto" field="fourth" options={fourthOption} />
								</Box>
							</Box>
							<Box mt={1} mb={2}>
								<Typography fontSize={24} color="#0A3AB6" fontWeight={600}>
									<strong style={{ fontSize: '32px', fontFamily: 'Manrope', fontWeight: 500 }}>5.</strong> 시력 검사와
									안경 렌즈 상담도 함께 진행되길 원하시나요?
								</Typography>
								<Box pl={4}>
									<RadioInput type="radio" grid="auto" field="fifth" options={fifthOption} />
								</Box>
							</Box>
							<Box mt={1} mb={2}>
								<Typography fontSize={24} color="#0A3AB6" fontWeight={600}>
									<strong style={{ fontSize: '32px', fontFamily: 'Manrope', fontWeight: 500 }}>6.</strong> 상세한 상담과
									설명을 원하시나요?
								</Typography>
								<Box pl={4}>
									<RadioInput type="radio" grid="auto" field="sixth" options={sixthOption} />
								</Box>
							</Box>
						</Box>
					</form>
				</FormProvider>
			</div>
		</Box>
	);
}

export default Survey;
