import { GridColDef } from '@mui/x-data-grid-pro';

const StoreCol = (): GridColDef[] => {
	return [
		{ field: 'id', headerName: '아이디', flex: 1, type: 'string' },
		{ field: 'name', headerName: '이름', flex: 1, type: 'string' },
		{ field: 'code', headerName: '코드', flex: 1, type: 'string' },
		{ field: 'tel', headerName: '전화번호', flex: 1, type: 'string' },
		{ field: 'mobile', headerName: '휴대전화', flex: 1, type: 'string' },
	];
};

export default StoreCol;
