import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, Chip, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { changeValueToLabel, convertAsType, convertCustomType, makePhoneNumber } from '../util/ReplaceValue';
import StoreStaffChip from '../components/StoreStaffChip';
import { selectList } from '../util/Array';
import { fetchUser } from '../selector';

const ReserveTotalCol = (): GridColDef[] => {
	const userInfo = useRecoilValue(fetchUser);
	return [
		{
			field: 'reserveType',
			headerName: '구분',
			flex: 0.7,
			type: 'singleSelect',
			valueOptions: selectList.reserveType
				.filter((option) => option.value !== 'attention')
				.concat({ value: 'cancel', label: '취소', color: '' }),
			valueGetter: (params) => {
				if (params.row.cancel) return 'cancel';
				return params.row.reserveType;
			},
			renderCell: (params: GridRenderCellParams) => {
				if (!params.row.cancel) {
					const obj = changeValueToLabel(params.value, 'reserveType');
					return <Chip label={obj?.label} style={{ backgroundColor: `${obj?.color}` }} />;
				}
				return <Chip label="취소" style={{ backgroundColor: '#CCCCCC' }} />;
			},
		},
		{
			field: 'name',
			headerName: '고객명',
			flex: 0.7,
			renderCell: (params) => (
				<Box display="flex" width="100%">
					<Typography mr={2}>{params.value}</Typography>
					{(params.row.care || false) && <StarIcon style={{ color: '#FF8126' }} />}
				</Box>
			),
		},
		{
			field: 'phone',
			headerName: '연락처',
			flex: 1.2,
			renderCell: (params: GridRenderCellParams) => makePhoneNumber(params.value) || '',
		},
		{
			field: 'reserveDate',
			headerName: '예약일시',
			flex: 1.5,
			type: 'dateTime',
			renderCell: (params: GridRenderCellParams) =>
				`${moment(new Date(String(params.value))).format('YY.MM.DD(dd) Ahh:mm')}`,
		},
		{
			field: 'reserveMedia',
			headerName: '예약경로',
			type: 'singleSelect',
			valueOptions: selectList.reserveMedia,
			flex: 1.2,
			renderCell: (params: GridRenderCellParams) => {
				const obj = changeValueToLabel(params.value, 'reserveMedia');
				return <Chip label={obj?.label} style={{ backgroundColor: `${obj?.color}` }} />;
			},
		},
		{
			field: 'note',
			headerName: '예약노트',
			flex: 1.4,
		},
		{
			field: 'customerNote',
			headerName: '고객비고',
			flex: 1.4,
			renderCell: (params: GridRenderCellParams) => {
				const customType = convertCustomType(params.value?.customType);
				const asType = convertAsType(params.value?.asType);
				return `${[customType, asType, params.value?.request, params.value?.smsg].filter((value) => value).join(', ')}`;
			},
		},
		{
			field: 'staff',
			headerName: '예약 담당',
			flex: 1,
			type: 'singleSelect',
			valueOptions: userInfo?.coworker,
			renderCell: (params) => <StoreStaffChip params={params} />,
		},
		{
			field: 'frameStaff',
			headerName: '프레임 담당',
			flex: 1,
			type: 'singleSelect',
			valueOptions: userInfo?.coworker,
			renderCell: (params) => <StoreStaffChip params={params} />,
		},
		{
			field: 'lensStaff',
			headerName: '렌즈 담당',
			flex: 1,
			type: 'singleSelect',
			valueOptions: userInfo?.coworker,
			renderCell: (params) => <StoreStaffChip params={params} />,
		},
	];
};

export default ReserveTotalCol;
