import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, AutocompleteChangeDetails, Grid, TextField, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import { fetchCustomerBySearch } from '../../query';
import { fetchUser } from '../../selector';
import { CustomerType, InputType, ReserveType } from '../../util/Interface';
import { checkNFC, makePhoneNumber } from '../../util/ReplaceValue';

function LoadAutoCompleteInput({ field, label, grid, variant }: InputType) {
	const [loading, setLoading] = useState<boolean>(false);
	const [searchOptions, setSearchOptions] = useState<CustomerType[]>([]);

	const userInfo = useRecoilValue(fetchUser);
	const { pathname } = useLocation();
	const {
		control,
		setValue,
		formState: { errors },
	} = useFormContext();

	const makeOptions = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			if (e.target.value.length > 1 && e.target.value !== '01' && e.target.value !== '010') {
				setLoading(true);
				const searchValue = e.target.value;
				fetchCustomerBySearch({ searchValue, fields: field }).then((result) => {
					if (result) {
						setLoading(false);
						setSearchOptions(result);
					}
				});
			} else {
				setLoading(false);
				setSearchOptions([]);
			}
		},
		[field]
	);
	const delayMakeOption = useMemo(() => debounce(makeOptions, 200), [makeOptions]);

	useEffect(() => {
		return () => {
			delayMakeOption.cancel();
		};
	}, [delayMakeOption]);

	const setCustomerValue = (details: AutocompleteChangeDetails<CustomerType | ReserveType> | undefined) => {
		const loadedCustomer: {
			name: string;
			customerId: string;
			phone: string;
			care: string;
			staff: string;
			preferContact: string;
		} = {
			name: details?.option.name || '',
			phone: details?.option.phone || '',
			care: (details?.option.care as string) || '',
			customerId: details?.option.id || '',
			staff: (details?.option.staffId as string) || (userInfo?.id as string) || '',
			preferContact: details?.option.preferContact || '',
		};
		Object.entries(loadedCustomer).map(([key, value]) => setValue(key, value, { shouldValidate: true }));
	};

	const setLabelFontSize = () => {
		if (pathname === '/store/reserve') {
			return { fontSize: 14 };
		}
		return {};
	};

	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid } }) => (
					<Autocomplete
						freeSolo
						disableClearable
						options={searchOptions}
						size="small"
						loading={loading}
						inputValue={field === 'phone' ? makePhoneNumber(value as string) || '' : (value as string) || ''}
						onInputChange={(_, inputValue) => onChange(checkNFC(inputValue))}
						onChange={(event, _, reason, details) => setCustomerValue(details)}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id}>
									<Grid container alignItems="center">
										<Grid item xs={12}>
											<Typography>{option.name}</Typography>
											<Typography fontSize={12}>{`(${option?.phone || ''})`}</Typography>
										</Grid>
									</Grid>
								</li>
							);
						}}
						getOptionLabel={(option) => {
							return ((option as CustomerType)[`${field}`] as string) || '';
						}}
						isOptionEqualToValue={(option: any, input: any) => option.value === input.id}
						renderInput={(params) => (
							<TextField
								variant={variant}
								{...params}
								name={field}
								label={label}
								onChange={delayMakeOption}
								inputRef={ref}
								onBlur={onBlur}
								InputLabelProps={{ style: setLabelFontSize() }}
								InputProps={{
									...params.InputProps,
								}}
								error={invalid && Boolean(errors[`${field}`] || false)}
								helperText={invalid && errors[`${field}`]?.message}
							/>
						)}
					/>
				)}
			/>
		</Grid>
	);
}

export default LoadAutoCompleteInput;
