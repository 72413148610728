import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import ErrorCheck from '../util/ErrorCheck';

const deleteReserve = async (id: string) => {
	try {
		await API.graphql({
			query: mutations.deleteReservation,
			variables: {
				input: { id },
			},
		});
	} catch (e) {
		ErrorCheck(e);
	}
};

export default deleteReserve;
