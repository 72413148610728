import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

interface thumbnailProps {
	file: File | null;
	label: string | undefined;
}

function Thumbnail(props: thumbnailProps) {
	const { file, label } = props;
	const [thumb, setThumb] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (file) {
			setLoading(true);
			const reader = new FileReader();
			reader.onloadend = () => {
				setLoading(false);
				setThumb(reader.result as string);
			};
			reader.readAsDataURL(file);
		} else {
			setThumb(undefined);
		}
	}, [file]);

	if (loading) {
		return <CircularProgress size={14} />;
	}
	return file && !loading ? (
		<img src={thumb} alt="logo" style={{ width: '100%', height: 'auto' }} />
	) : (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			style={{
				backgroundColor: 'grey',
				width: '100%',
				borderRadius: '4px',
				aspectRatio: '4/3',
			}}
		>
			<CropOriginalIcon style={{ color: 'white' }} />
			<Typography align="center" color="white" fontSize={10}>
				{`${label}을 넣어주세요.`}
			</Typography>
		</Box>
	);
}

export default Thumbnail;
