import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
	DataGridPro,
	GridColumnMenuProps,
	GridRenderCellParams,
	GridRowParams,
	GridState,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	useGridApiContext,
	useGridApiRef,
	useGridRootProps,
} from '@mui/x-data-grid-pro';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEqual } from 'lodash';
import { designCol } from '../columns/Columns';
import koText from '../util/localizationKR';
import SubNav from '../components/SubNav';
import { openDesignDetailState } from '../atom/Atom';
import DesignModal from '../components/modal/DesignModal';
import { StaffType, UrlDataGrid } from '../util/Interface';
import { toServerStaff } from '../util/ReplaceValue';
import { fetchUser } from '../selector';
import { updateStaff } from '../query';

function Design() {
	return (
		<Box px={5}>
			<SubNav />
			<div style={{ height: 1500, width: '100%' }} />
		</Box>
	);
}

export default Design;
