import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useStyles } from './InfoAgree';

function OfferAgree() {
	const classes = useStyles();

	const title = [
		{ id: 1, value: '제공받는 제3자' },
		{ id: 2, value: '제공받는 개인정보 항목' },
		{ id: 3, value: '이용 목적' },
		{ id: 4, value: '보유 및 이용 기간' },
	];

	const contents = [
		[
			{ id: '1-1', value: '주식회사 콥틱\n주식회사 더메이크' },
			{ id: '1-2', value: '고객명\n휴대전화번호\n3D Face Scanning Data\n자각식 굴절 검사 기록\n설문 결과', rowSpan: 2 },
			{ id: '1-3', value: '아이웨어 주문 제작, 서비스의 향상\n및 상품서비스의 제공 등', rowSpan: 2 },
			{ id: '1-4', value: '고객 개인정보 동의 철회시까지', rowSpan: 4 },
		],
		[
			{
				id: '2-1',
				value: '[브리즘 특약점]\n- 서울시청점\n- 삼성정\n- 여의도점\n- 역삼점\n- 판교점\n- 신규 출점 특약점',
			},
		],
		[
			{
				id: '3-1',
				value: '(주)휴머스온\n(주) 카카오',
			},
			{
				id: '3-2',
				value: '이메일\n휴대전화번호',
			},
			{
				id: '3-3',
				value: '제작 현황 알림, 고객 상담,\n서비스 만족도 조사 등\n고객 지원 서비스 제공 ',
			},
		],
		[
			{
				id: '4-1',
				value: 'CJ대한통운\n롯데택배\n로젠택배\n우체국택배',
			},
			{
				id: '4-2',
				value: '고객명\n휴대전화번호\n배송지 주소',
			},
			{
				id: '4-3',
				value: '제품 배송 서비스 제공',
			},
		],
	];

	return (
		<Box>
			<Typography fontWeight="bold" mb={1}>
				2. 개인정보의 제3자 제공에 대한 동의
			</Typography>
			<Typography className={classes.contents} fontSize={15} mb={4}>
				본 판매점은 서비스의 향상, 원활한 금융거래의 설정·유지·이행·관리 및 상품서비스의 제공 등을 위해 개인정보를
				아래와 같이 제3자에게 제공하고 있습니다. 귀하는 위의 개인정보 수집‧이용에 대한 동의를 거부할 권리가 있습니다.
				그러나 위 수집 및 이용 동의를 거부하실 경우, 서비스 이용이 제한될 수 있습니다.
			</Typography>
			<Typography className={classes.subTitle} mb={1} fontWeight="bold">
				개인정보의 제3자 제공에 대한 동의
			</Typography>
			<Box>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow className={classes.tableTitle}>
								{title.map((obj) => (
									<TableCell width="25%" key={obj.id}>
										<Typography fontWeight="bold">{obj.value}</Typography>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{contents.map((rows) => {
								return (
									<TableRow key={rows[0].id}>
										{rows.map((row, index) => {
											const { id, value } = row;
											return (
												<TableCell
													className={`${index === 0 && classes.leftContents} ${classes.tableContents} ${
														index === 3 && classes.rightContents
													} ${(id.includes('4-') || id.includes('1-4')) && classes.bottomContents}`}
													key={id}
													rowSpan={row.rowSpan ? (row.rowSpan as number) : undefined}
												>
													<Typography className={classes.contentText} fontSize={15} py={1}>
														{value}
													</Typography>
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<Typography className={classes.caption} mt={1} fontSize={14}>
					* 개인정보의 제3자 제공에 대해 중지 요청이 있는 경우, 기간 중에도 제공을 중단할 수 있습니다. 귀하는 위의
					개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다. 그러나 위 제3자 제공에 대한 동의를 거부하실 경우,
					서비스의 이용이 어려울 수 있습니다.
				</Typography>
			</Box>
		</Box>
	);
}

export default OfferAgree;
