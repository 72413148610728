import React from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Checkbox } from '@mui/material';

const ContactCol = (): GridColDef[] => [
	{
		field: 'id',
		headerName: '주문번호',
		type: 'string',
		flex: 1,
	},
	{
		field: 'name',
		headerName: '고객명',
		type: 'string',
		flex: 1,
	},
	{
		field: 'phone',
		headerName: '연락처',
		type: 'string',
		flex: 1,
	},
	{
		field: 'totalDuration',
		headerName: '총경과일',
		type: 'string',
		flex: 1,
	},
	{
		field: 'contactNote',
		headerName: '수령/발송 비고',
		type: 'string',
		flex: 1,
	},
	{
		field: 'frameStaff',
		headerName: '프레임담당',
		type: 'singleSelect',
		flex: 1,
	},
	{
		field: 'delivery',
		headerName: '배송구분',
		type: 'singleSelect',
		flex: 1,
	},
	{
		field: 'preferContact',
		headerName: '선호연락',
		type: 'singleSelect',
		flex: 1,
	},
	{
		field: 'alarm',
		headerName: '알람끄기',
		type: 'boolean',
		flex: 1,
		renderCell: (params) => (
			<Checkbox
				// checked={}
				// onChange={this.handleChangeRadio.bind(this, params.id)}
				size="small"
			/>
		),
	},
	{
		field: 'message',
		headerName: '제작현황 메시지',
		type: 'string',
		flex: 1,
	},
	{
		field: 'completeContact',
		headerName: '완성연락',
		flex: 1,
	},
];

export default ContactCol;
