import React from 'react';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ko } from 'date-fns/esm/locale';
import { useFormik } from 'formik';
import { useRecoilState } from 'recoil';
import { lensAsDataState, openAsLensState } from '../../atom/Atom';

function LensAsModal() {
	const [openAsLens, setOpenAsLens] = useRecoilState(openAsLensState);
	const [lensAsData] = useRecoilState(lensAsDataState);
	const formik = useFormik({
		initialValues: lensAsData,
		onSubmit: (values, { resetForm }) => {
			resetForm({});
		},
		enableReinitialize: true,
	});
	const { values, setFieldValue, handleSubmit, resetForm } = formik;

	const handleClose = () => {
		resetForm({});
		setOpenAsLens(false);
	};

	const makeComma = (value: string, field: string) => {
		setFieldValue(field, value.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
	};

	const lensASContextCheck = (array: string[]) => {
		const asContext: string[] = [];
		array.forEach((value: string) => {
			switch (value) {
				case 'blur':
					asContext.push('흐림');
					break;
				case 'dizzy':
					asContext.push('어지러움');
					break;
				case 'pain':
					asContext.push('압박/통증');
					break;
				case 'imbalance':
					asContext.push('양안불균형');
					break;
				case 'dazzle':
					asContext.push('눈부심');
					break;
				case 'glare':
					asContext.push('빛번짐');
					break;
				case 'reflect':
					asContext.push('얼비침');
					break;
				case 'etcC':
					asContext.push('기타(코멘트)');
					break;
				case 'dispenseErr':
					asContext.push('가공오류');
					break;
				case 'orderErr':
					asContext.push('주문오류');
					break;
				case 'powerChange':
					asContext.push('도수변경');
					break;
				case 'ocChange':
					asContext.push('초점변경');
					break;
				case 'specChange':
					asContext.push('사양변경');
					break;
				case 'colorChange':
					asContext.push('색상변경');
					break;
				case 'etcN':
					asContext.push('기타(코멘트)');
					break;
				case 'powerFaulty':
					asContext.push('도수불량');
					break;
				case 'coatingFaulty':
					asContext.push('코팅불량');
					break;
				case 'coloringFaulty':
					asContext.push('착색불량');
					break;
				case 'etcQ':
					asContext.push('기타(코멘트)');
					break;
				default:
					break;
			}
		});
		return (asContext as string[]).join(',');
	};
	return (
		<Dialog open={openAsLens} onClose={handleClose} fullWidth maxWidth="lg">
			<form onSubmit={handleSubmit}>
				<DialogTitle>렌즈 AS</DialogTitle>
				<DialogContent>
					<Box py={1}>
						<Grid container columnSpacing={1} rowSpacing={2}>
							{/* {lensAsArray.map((row, index) => {
                  const { grid, label, array, type, field, dynamicArray } = row;
                  return (
                    <Grid
                      item
                      xs={grid}
                      key={`lensAsModal${index}`}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {type === 'singleSelect' && (
                        <TextField
                          id={`${field}`}
                          name={`${field}`}
                          select
                          size={'small'}
                          label={label}
                          value={values[`${field}`]}
                          onChange={(e) => {
                            setFieldValue(`${field}`, e.target.value);
                          }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          fullWidth
                        >
                          <MenuItem key={''} value={''}>
                            미선택
                          </MenuItem>
                          {array?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {type === 'text' && (
                        <TextField
                          type={'number'}
                          id={`${field}`}
                          name={`${field}`}
                          value={values[`${field}`]}
                          size={'small'}
                          label={label}
                          onChange={(e) => {
                            setFieldValue(`${field}`, e.target.value);
                          }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          fullWidth
                        />
                      )}
                      {type === 'label' && <Typography>{label}</Typography>}
                      {type === 'longText' && (
                        <TextField
                          type={'text'}
                          multiline
                          value={values[`${field}`]}
                          size={'small'}
                          label={label}
                          placeholder={`${label} 내용을 입력해주세요.`}
                          rows={2}
                          onChange={(e) => {
                            setFieldValue(`${field}`, e.target.value);
                          }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          fullWidth
                        />
                      )}
                      {type === 'date' && (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ko}
                        >
                          <DatePicker
                            value={values[`${field}`]}
                            label={label}
                            onChange={(value) => {
                              setFieldValue(`${field}`, value);
                            }}
                            inputFormat={'yy-MM-dd'}
                            mask={'__-__-__'}
                            renderInput={(props) => (
                              <TextField
                                id={`${field}`}
                                name={`${field}`}
                                {...props}
                                size={'small'}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                      {type === 'currency' && (
                        <TextField
                          type={'text'}
                          id={`${field}`}
                          name={`${field}`}
                          value={String(values[`${field}`]).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ',',
                          )}
                          size={'small'}
                          label={label}
                          onChange={(e) => {
                            setFieldValue(`${field}`, e.target.value);
                          }}
                          onBlur={(e) => makeComma(e.target.value, `${field}`)}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          fullWidth
                        />
                      )}
                      {type === 'dynamicMultiSelect' &&
                        field === 'lensAsContext' && (
                          <FormControl
                            variant={'outlined'}
                            fullWidth
                            size={'small'}
                          >
                            <InputLabel disableAnimation={false}>
                              {label}
                            </InputLabel>
                            <Select
                              multiple
                              name={`${field}`}
                              value={
                                values[`${field}`]
                                  ? (values[`${field}`] as string[])
                                  : ([] as string[])
                              }
                              onChange={(e) => {
                                setFieldValue(`${field}`, e.target.value);
                              }}
                              input={<OutlinedInput label={label} />}
                              renderValue={(selected: string[]) => {
                                return lensASContextCheck(selected);
                              }}
                            >
                              {dynamicArray?.[`${values.lensAsType}`]?.map(
                                (option, index) => {
                                  const { label, value } = option;
                                  return (
                                    <MenuItem
                                      key={`${field}_${index}`}
                                      value={value}
                                    >
                                      <Checkbox
                                        checked={
                                          (
                                            values[`${field}`] as string[]
                                          ).indexOf(value) > -1
                                        }
                                      />
                                      <ListItemText>{label}</ListItemText>
                                    </MenuItem>
                                  );
                                },
                              )}
                            </Select>
                          </FormControl>
                        )}
                    </Grid>
                  );
                })} */}
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleClose}>
						취소
					</Button>
					<Button variant="contained" type="submit">
						입력
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

export default LensAsModal;
