import React from 'react';
import { Chip } from '@mui/material';
import { StaffChipProps } from '../util/Interface';

function StaffChip(props: StaffChipProps) {
	const { params } = props;
	return <Chip sx={{ backgroundColor: params.row.color }} label={params.value} />;
}

export default StaffChip;
