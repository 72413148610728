import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	contents: {
		whiteSpace: 'pre-wrap',
		color: '#696969',
	},
	subTitle: {
		color: '#233E9F',
	},
	tableTitle: {
		borderTop: 'solid 2px #000000',
		borderBottom: 'solid 2px #000000',
	},
	tableContents: { border: 'solid 0.5px #CBCBCB', height: '80px' },
	leftContents: {
		borderLeft: '#000000',
	},
	rightContents: {
		borderRight: '#000000',
	},
	bottomContents: {
		borderBottom: 'solid 2px #CBCBCB',
	},
	contentText: {
		whiteSpace: 'pre-wrap',
		color: '#616161',
	},
	caption: {
		color: '#A6A6A6',
	},
});

function InfoAgree() {
	const classes = useStyles();

	const title = ['구분', '개인정보 항목', '수집 및 이용 목적', '보유기간'];
	const contents = [
		{ id: 1, value: '고객공통' },
		{ id: 2, value: '고객명, 생년월일, 휴대전화번호,\ne-Mail 주소' },
		{ id: 3, value: '고객 식별, 서비스 관련 안내 및 상담' },
		{ id: 4, value: '고객 개인정보 동의 철회시까지' },
		{ id: 5, value: '안경테 제작 주문' },
		{ id: 6, value: '고객명, 휴대전화번호, 3D Face Scanning Data, 설문 결과,\n배송지 주소' },
		{ id: 7, value: '아이웨어 주문 제작 및 제품 배송\n서비스' },
		{ id: 8, value: '' },
		{ id: 9, value: '렌즈 제작 주문' },
		{ id: 10, value: '고객명, 휴대전화번호, 3D Face Scanning Data, 자각식 굴절 검사 기록, 설문 결과' },
		{ id: 11, value: '렌즈 제작 및 제품 배송 서비스' },
		{ id: 12, value: '' },
		{ id: 13, value: '주문' },
		{ id: 14, value: '신용카드 결제기록' },
		{ id: 15, value: '결제 및 결제 취소' },
		{ id: 16, value: '법정 의무 보유 기간' },
	];

	return (
		<>
			<Box>
				<Typography fontWeight="bold" mb={1}>
					1. 개인정보 수집·이용에 대한 동의
				</Typography>
				<Typography mb={4} className={classes.contents} fontSize={15}>
					{`[브리즘 역삼점]은 개인정보 보호법에 따라 개인정보 처리에 관한 사항을 고지하고 고객으로부터 동의를 받는 절차를 마련하고 있습니다. \n[브리즘 역삼점]은 아이웨어를 제작 주문하시는 경우 아래의 개인정보 항목을 다음과 같은 목적으로 수집하고 있으며 개인 정보 수집은 그 목적에 필요한 최소한의 정보만을 수집합니다. 개인정보 처리에 대하여 동의를 거부하실 수 있으며, 이 경우 본 판매점이 제공하는 서비스의 전부 또는 일부의 제공이 제한될 수 있습니다.`}
				</Typography>
			</Box>
			<Box>
				<Typography className={classes.subTitle} mb={1} fontWeight="bold">
					개인정보 수집·이용 동의 사항
				</Typography>
				<Box>
					<Grid container>
						{title.map((value) => {
							return (
								<Grid className={classes.tableTitle} item xs={3} key={value}>
									<Typography fontWeight="bold" px={2} py={1}>
										{value}
									</Typography>
								</Grid>
							);
						})}
						{contents.map((obj, index) => {
							const { value, id } = obj;
							return (
								<Grid
									className={`${index % 4 === 0 && classes.leftContents} ${classes.tableContents} ${
										index % 4 === 3 && classes.rightContents
									} ${index > 11 && classes.bottomContents}`}
									item
									xs={3}
									key={id}
									display="flex"
									alignItems="center"
								>
									<Typography className={classes.contentText} fontSize={15} px={2} py={3}>
										{value}
									</Typography>
								</Grid>
							);
						})}
						<Typography className={classes.caption} mt={1} fontSize={14}>
							* 귀하는 위의 개인정보 수집‧이용에 대한 동의를 거부하실 수 있으며. 기간 중에도 이용 및 보유를 중단 할 수
							있습니다. 그러나 개인정보 수집 및 이용 동의를 거부하실 경우, 제공받으실 수 있는 서비스 이용이 어려울 수
							있습니다.
						</Typography>
					</Grid>
				</Box>
			</Box>
		</>
	);
}

export default InfoAgree;
