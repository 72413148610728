import React from 'react';
import { Checkbox, FormControlLabel, FormHelperText, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputType } from '../../util/Interface';

function CheckBoxInput({ field, label, grid, withButton }: InputType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	if (!field || !label) return null;
	return (
		<Grid item={withButton ? undefined : true} display="flex" alignItems="center" xs={withButton ? undefined : grid}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value } }) => {
					return (
						<FormControlLabel
							control={<Checkbox size="small" checked={value || (false as boolean)} onChange={onChange} />}
							label={label}
						/>
					);
				}}
			/>
			{Boolean(errors[`${field}`]) && <FormHelperText error>{errors[`${field}`]?.message}</FormHelperText>}
		</Grid>
	);
}

export default CheckBoxInput;
