import React from 'react';
import { Box, Typography } from '@mui/material';

interface SurveyChipProps {
	label: string;
}

function SurveyChip(props: SurveyChipProps) {
	const { label } = props;
	return (
		<Box sx={{ border: '1px solid #C9C9C9', borderRadius: '22px', px: 2, py: 1 }}>
			<Typography color="#303030">{label}</Typography>
		</Box>
	);
}

export default SurveyChip;
