import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Typography,
	DialogContent,
	Grid,
	Box,
	Divider,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { lensDataState, openLensState } from '../../atom/Atom';
import { lensArray, lensPrescription } from '../../util/Array';
import { AutoCompleteInput, DateInput, SingleSelectInput, TextInput } from '../form';
import MultiSelect from '../form/MultiSelect';
import LongTextInput from '../form/LongTextInput';
import { LensType } from '../../util/Interface';
import DynamicSingleSelect from '../form/DynamicSingleSelect';
import { forDynamicField } from '../../util/ReplaceValue';
import ReqArrayInput from '../form/ReqArrayInput';
import { lensValidation } from '../../util/Validation';

function LensModal() {
	const [openLens, setOpenLens] = useRecoilState(openLensState);
	const defaultValues = useRecoilValue(lensDataState);
	const resetDefaultValues = useResetRecoilState(lensDataState);

	const methods = useForm<LensType>({
		defaultValues,
		mode: 'all',
		reValidateMode: 'onChange',
		shouldFocusError: true,
		resolver: yupResolver(lensValidation),
	});

	const handleClose = () => {
		methods.reset();
		resetDefaultValues();
		setOpenLens(false);
	};

	const onSubmit: SubmitHandler<LensType> = (data) => {
		if (data.id) {
			// @todo  id 있을때
			console.log(data);
		} else {
			// @todo  id 없을때
			console.log(data);
		}
	};

	return (
		<Dialog open={openLens} onClose={handleClose} fullWidth maxWidth="lg">
			<DialogTitle>
				<Typography fontSize={24}>렌즈 주문</Typography>
			</DialogTitle>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<DialogContent>
						<Box py={1}>
							<Grid container rowSpacing={2} columnSpacing={1}>
								{lensArray.map((input, index) => {
									const { type, field, label, options, grid, condition } = input;
									switch (type) {
										case 'dynamicSingleSelect':
											if (condition !== undefined) {
												if (forDynamicField(condition, methods.watch)) {
													return (
														<DynamicSingleSelect key={field} type={type} grid={grid} field={field} label={label} />
													);
												}
												return null;
											}
											return null;
										case 'autoComplete':
											return <AutoCompleteInput key={field} type={type} grid={grid} field={field} label={label} />;
										case 'label':
											return (
												<Grid key={field} item xs={grid}>
													<Typography>{label}</Typography>
												</Grid>
											);
										case 'text':
											return <TextInput key={field} grid={grid} field={field} label={label} type={type} size="small" />;
										case 'singleSelect':
											return (
												<SingleSelectInput
													key={field}
													grid={grid}
													field={field}
													options={options}
													label={label}
													type={type}
													size="small"
												/>
											);
										case 'multiSelect':
											return (
												<MultiSelect
													key={field}
													type={type}
													grid={grid}
													options={options}
													field={field}
													label={label}
												/>
											);
										case 'blank':
											// eslint-disable-next-line react/no-array-index-key
											return <Grid key={`${type}_${index}`} item xs={grid} />;
										case 'longText':
											return <LongTextInput key={field} field={field} type={type} grid={grid} label={label} />;
										case 'date':
											return <DateInput key={field} type={type} grid={grid} field={field} size="small" label={label} />;
										default:
											return null;
									}
								})}
							</Grid>
							<Box py={3}>
								<Divider />
								<Typography pt={3} component="h3" fontSize={25}>
									처방값
								</Typography>
							</Box>
							<Grid container rowSpacing={2} columnSpacing={1}>
								{lensPrescription.map((input, index) => {
									const { type, field, label, options, grid, condition } = input;
									switch (type) {
										case 'dynamicSingleSelect':
											if (condition) {
												if (forDynamicField(condition, methods.watch)) {
													return (
														<DynamicSingleSelect key={field} type={type} grid={grid} field={field} label={label} />
													);
												}
												return null;
											}
											return null;
										case 'autoComplete':
											return <AutoCompleteInput key={field} type={type} grid={grid} field={field} label={label} />;
										case 'label':
											return (
												<Grid key={field} item xs={grid} display="flex" justifyContent="center" alignItems="center">
													<Typography>{label}</Typography>
												</Grid>
											);
										case 'text':
											return <TextInput key={field} grid={grid} field={field} label={label} type={type} size="small" />;
										case 'singleSelect':
											return (
												<SingleSelectInput
													key={field}
													grid={grid}
													field={field}
													options={options}
													label={label}
													type={type}
													size="small"
												/>
											);
										case 'multiSelect':
											return (
												<MultiSelect
													key={field}
													type={type}
													grid={grid}
													options={options}
													field={field}
													label={label}
												/>
											);
										case 'blank':
											// eslint-disable-next-line react/no-array-index-key
											return <Grid key={`${type}_${index}`} item xs={grid} />;
										case 'longText':
											return <LongTextInput key={field} field={field} type={type} grid={grid} label={label} />;
										case 'date':
											return <DateInput key={field} type={type} grid={grid} field={field} size="small" label={label} />;
										case 'ref':
											if (!options) return null;
											return <ReqArrayInput key={field} options={options} grid={grid} type={type} />;
										default:
											return null;
									}
								})}
							</Grid>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={handleClose}>
							취소
						</Button>
						<Button variant="contained" type="submit">
							입력
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
}

export default LensModal;
