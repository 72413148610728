import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { makePhoneNumber } from '../../util/ReplaceValue';
import { storeListState } from '../../atom';
import StoreChip from '../../components/StoreChip';
import StaffChip from '../../components/StaffChip';

const StaffCol = (): GridColDef[] => {
	const stores = useRecoilValue(storeListState);
	const storeNameOptions = stores?.map((store) => store.name);
	return [
		{
			field: 'id',
			headerName: '아이디',
			flex: 1,
			type: 'string',
			renderCell: (params) => <StaffChip params={params} />,
		},
		{ field: 'name', headerName: '이름', flex: 1, type: 'string' },
		{
			field: 'phone',
			headerName: '전화번호',
			flex: 1,
			type: 'string',
			renderCell: (params: GridRenderCellParams) => makePhoneNumber(params.value) || '',
		},
		{
			field: 'email',
			headerName: '이메일',
			flex: 1,
			type: 'string',
		},
		{
			field: 'storeId',
			headerName: '근무지',
			flex: 1,
			type: 'singleSelect',
			valueOptions: storeNameOptions,
			valueGetter: (params) => {
				const storeObj = stores?.find((store) => store.id === params.row.storeId);
				return storeObj?.name || '';
			},
			renderCell: (params) => <StoreChip params={params} />,
		},
	];
};

export default StaffCol;
