import html2canvas from 'html2canvas';

const downloadImage = (blob: string, fileName: any) => {
	const link = window.document.createElement('a');
	link.download = fileName;
	link.href = blob;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	link.remove();
};

const exportAsImage = async (element: HTMLElement | undefined, imageFileName: string) => {
	if (element !== undefined) {
		const canvas = await html2canvas(element);
		return canvas.toDataURL('image/png', 1.0);
		// downloadImage(image, imageFileName);
	}
	return null;
};

export default exportAsImage;
