import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DatesSetArg, EventInput } from '@fullcalendar/common';
import { Box } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import koLocale from '@fullcalendar/core/locales/ko';
import { API } from 'aws-amplify';
import { fetchScheduler } from '../../query';
import { convertReserve, convertReserveToSchedule } from '../../util/ReplaceValue';
import ErrorCheck from '../../util/ErrorCheck';
import { loadingState, loginUser, selectedStore, storeListState } from '../../atom';
import { fetchUser } from '../../selector';
import SubNav from '../../components/SubNav';
import Loading from '../../components/Loading';
import * as subscriptions from '../../graphql/subscriptions';
import { ReservationResponse } from '../../util/Interface';

function ReserveDayView() {
	const userInfo = useRecoilValue(fetchUser);
	const [dateArg, setDateArg] = useState<{ start: Date; end: Date } | null>(null);
	const [loading, setLoading] = useRecoilState(loadingState);
	const storeId = useRecoilValue(selectedStore);
	const storeList = useRecoilValue(storeListState);
	const [schedule, setSchedule] = useState<EventInput[]>([]);
	const [storeOpenTime, setStoreOpenTime] = useState('11:00:00');
	const [storeCloseTime, setStoreCloseTime] = useState('20:00:00');
	const [storeDurationTime, setStoreDurationTime] = useState('00:10:00');
	const user = useRecoilValue(loginUser);

	useEffect(() => {
		if (storeList) {
			const currentStore = storeList.filter((store) => store.id === storeId);
			const { openTime, closeTime, durationTime } = currentStore[0];
			setStoreOpenTime(openTime);
			setStoreCloseTime(closeTime);
			setStoreDurationTime(durationTime);
		}
	}, [storeId, storeList]);

	useEffect(() => {
		if (dateArg && storeId) {
			setLoading(true);
			fetchScheduler({ storeId, dateArg })
				.then((reserveRes) => {
					if (reserveRes && userInfo) {
						setSchedule(convertReserveToSchedule(reserveRes, userInfo.coworker));
						setLoading(false);
					}
				})
				.catch((err) => ErrorCheck(err));
		}
	}, [dateArg, setLoading, storeId, userInfo]);

	const handleDate = (arg: DatesSetArg) => {
		const { start, end } = arg;
		setDateArg({ start, end });
	};

	const onCreateSchedule = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onCreateReservationByStore as ReservationResponse;
					if (userInfo) {
						const newSchedule = convertReserveToSchedule(convertReserve([item]), userInfo.coworker);
						setSchedule([...schedule, ...newSchedule]);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
					if (user) {
						window.location.reload();
					}
				},
			}),
		[schedule, storeId, user, userInfo]
	);

	const onUpdateSchedule = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateReservationByStore as ReservationResponse;
					if (userInfo) {
						const newArray = [...schedule];
						const index = newArray.findIndex(({ id }) => id === item.id);
						const newSchedule = convertReserveToSchedule(convertReserve([item]), userInfo.coworker)[0];
						newArray[index] = newSchedule;
						setSchedule([...newArray]);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[schedule, storeId, userInfo]
	);

	const onDeleteSchedule = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onDeleteReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onDeleteReservationByStore as ReservationResponse;
					const newArray = [...schedule];
					const index = newArray.findIndex(({ id }) => id === item.id);
					if (index > -1) newArray.splice(index, 1);
					setSchedule(newArray);
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[schedule, storeId]
	);

	useEffect(() => {
		const create = onCreateSchedule();
		const update = onUpdateSchedule();
		const remove = onDeleteSchedule();
		return () => {
			create.unsubscribe();
			update.unsubscribe();
			remove.unsubscribe();
		};
	}, [onCreateSchedule, onDeleteSchedule, onUpdateSchedule]);

	return (
		<Box px={5}>
			<SubNav />
			{loading && <Loading />}
			<FullCalendar
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				headerToolbar={{
					left: 'timeGridWeek,timeGridDay,dayGridMonth',
					center: 'title',
					right: 'prev,next today',
				}}
				events={schedule}
				eventTextColor="black"
				initialView="timeGridWeek"
				buttonText={{
					today: '오늘',
					month: '월간',
					week: '주간',
					day: '일간',
				}}
				datesSet={handleDate}
				eventStartEditable={false}
				allDaySlot={false}
				slotMinTime={storeOpenTime}
				slotMaxTime={storeCloseTime}
				contentHeight="auto"
				droppable
				selectable
				editable
				selectLongPressDelay={25}
				slotDuration={storeDurationTime}
				firstDay={1}
				locale={koLocale}
			/>
		</Box>
	);
}

export default ReserveDayView;
