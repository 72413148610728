import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Auth } from 'aws-amplify';
import { fetchUser } from '../selector';
import logo from '../images/logo.png';
import NavAvatar from './NavAvatar';
import ErrorCheck from '../util/ErrorCheck';
import { GRID_SIZES } from '../util/Interface';
import { loginUser, surveyModalState } from '../atom';
import ImageModal from './modal/ImageModal';

const useStyles = makeStyles({
	button: {
		cursor: 'pointer',
	},
});

interface navProps {
	title: string;
	link: string;
	grid: GRID_SIZES;
}

function Navigation() {
	const classes = useStyles();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const userInfo = useRecoilValue(fetchUser);
	const setUser = useSetRecoilState(loginUser);
	const setOpenSurvey = useSetRecoilState(surveyModalState);

	const handleLogout = async () => {
		try {
			await Auth.signOut()
				.then(() => {
					setUser(null);
					navigate('/');
				})
				.catch((e) => ErrorCheck(e));
		} catch (e) {
			ErrorCheck(e);
		}
	};

	// 이 부분 나중에 해당 사용자의 부서에 따라 메뉴 바뀌도록 설정
	let nav: navProps[] = [
		{ title: '예약', link: '/store/reserve', grid: 1 },
		{ title: '고객', link: '/store/customer', grid: 1 },
		{ title: '주문', link: '/store/frame-order', grid: 1 },
		{ title: '관리', link: '/store/manage', grid: 1 },
		{ title: '설문', link: '/store/survey', grid: 1 },
		{ title: '설계', link: '/store/fitting', grid: 2 },
	];

	if (pathname.includes('admin')) {
		nav = [
			{ title: '예약', link: '/admin/reserve', grid: 1 },
			{
				title: '직원',
				link: '/admin/staff',
				grid: 1,
			},
			{
				title: '매장',
				link: '/admin/store',
				grid: 1,
			},
			{
				title: '제품',
				link: '/admin/product',
				grid: 1,
			},
			{
				title: '필드관리',
				link: '/admin/field',
				grid: 1,
			},
			{
				title: '레이아웃',
				link: '/admin/layout',
				grid: 2,
			},
		];
	} else if (pathname.includes('product') || pathname.includes('sample') || pathname.includes('statistics')) {
		nav = [
			{
				title: '주문생산',
				link: '/product/all',
				grid: 1,
			},
			{
				title: '샘플',
				link: '/sample',
				grid: 1,
			},
			{
				title: '통계',
				link: '/statistics',
				grid: 5,
			},
		];
	} else if (pathname.includes('design')) {
		nav = [
			{
				title: '설계',
				link: '/design',
				grid: 1,
			},
			{
				title: '검수',
				link: '/design/inspection',
				grid: 1,
			},
			{
				title: 'AS 및 추가주문',
				link: '/design/as',
				grid: 2,
			},
			{
				title: '설계이슈',
				link: '/design/issue',
				grid: 3,
			},
		];
	} else if (pathname === '/total-schedule') {
		nav = [{ title: '', link: '', grid: 7 }];
	}
	if (pathname !== '/') {
		return (
			<>
				<Container maxWidth="xl" sx={{ padding: '0!important' }}>
					<Box px={5} py={2} display="flex" alignItems="center">
						<Grid container spacing={1} alignItems="center">
							<Grid item xs={2}>
								<Button onClick={() => navigate('/')}>
									<img src={logo} alt="logo" />
								</Button>
							</Grid>
							{nav.map((obj) => {
								const { title, link, grid } = obj;
								return (
									<Grid item xs={grid} key={`nav_${title}`}>
										{link === '' ? null : (
											<Button
												onClick={() => {
													if (link === '/store/survey') {
														setOpenSurvey(true);
													} else {
														navigate(`${link}`);
													}
												}}
											>
												<Typography
													className={classes.button}
													fontSize={18}
													color={pathname.includes(link) ? '#0960E3' : '#B0B0B0'}
													fontWeight={pathname.includes(link) ? 'bold' : 'normal'}
												>
													{title}
												</Typography>
											</Button>
										)}
									</Grid>
								);
							})}
							<Grid item xs={2}>
								<Box display="flex" alignItems="center">
									<NavAvatar />
									<Typography ml={1} fontSize={18}>
										{userInfo?.labelKr}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={1}>
								<Typography className={classes.button} fontSize={18} onClick={handleLogout}>
									logout
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Container>
				<ImageModal />
			</>
		);
	}
	return null;
}

export default Navigation;
