import React from 'react';
import { useRecoilValue } from 'recoil';
import { fetchUser } from '../selector';
import NoAuthPage from '../page/error/NoAuthPage';

function RequireAdmin({ children }: { children: JSX.Element }) {
	const userInfo = useRecoilValue(fetchUser);

	if (!(userInfo?.storeType === 'office')) {
		return <NoAuthPage />;
	}

	return children;
}

export default RequireAdmin;
