import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function AdditionArrayInput() {
	const { control, setValue } = useFormContext();
	const { fields, append, remove } = useFieldArray({ control, name: 'additionRange' });

	const rangesValue = useWatch({ control, name: 'ranges' });

	useEffect(() => {
		if (rangesValue === 'addition') {
			append({ addMin: '', addMax: '' });
			setValue('ranges', '');
		}
	}, [append, rangesValue, setValue]);

	return (
		<>
			{fields.map((item, index) => {
				return (
					<Grid container key={item.id} spacing={2} pl={2} pt={2}>
						<Grid item xs={2}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										name={`additionRange[${index}].addMin`}
										label="add최소"
										size="small"
										fullWidth
									/>
								)}
								name={`additionRange[${index}].addMin`}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										name={`additionRange[${index}].addMax`}
										label="add최대"
										size="small"
										fullWidth
									/>
								)}
								name={`additionRange[${index}].addMax`}
							/>
						</Grid>
						<Grid item>
							<Button onClick={() => remove(index)}>
								<DeleteIcon />
							</Button>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
}

export default AdditionArrayInput;
