import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import SubNav from '../../components/SubNav';
import koText from '../../util/localizationKR';
import { customerCol, customerFrameCol, customerLensCol } from '../../columns/Columns';
import {
	eyesDataProps,
	frameDataState,
	lensDataState,
	openCustomerState,
	openFrameState,
	openLensState,
} from '../../atom/Atom';
import FrameModal from '../../components/modal/FrameModal';
import LensModal from '../../components/modal/LensModal';
import FrameAsModal from '../../components/modal/FrameAsModal';
import LensAsModal from '../../components/modal/LensASModal';
import CustomerModal from '../../components/modal/CustomerModal';
import { customerState } from '../../atom';
import AutoSearchBar from '../../components/AutoSearchBar';
import { CustomerType, LensType } from '../../util/Interface';

function CustomerCheck() {
	const setOpenCustomer = useSetRecoilState(openCustomerState);
	const setOpenFrame = useSetRecoilState(openFrameState);
	const setOpenLens = useSetRecoilState(openLensState);
	const setFrameData = useSetRecoilState(frameDataState);
	const setEyesData = useSetRecoilState(lensDataState);
	const [customer, setCustomer] = useRecoilState(customerState);

	return (
		<>
			<Box px={5}>
				<SubNav />
				<AutoSearchBar />
				<div style={{ height: 200, width: '100%' }}>
					<DataGridPro
						rows={customer ? [customer] : []}
						columns={customerCol}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						disableColumnMenu
						style={{
							border: 'none',
						}}
						localeText={koText}
						hideFooter
						onCellClick={(params) => {
							setOpenCustomer(true);
							setCustomer(params.row as CustomerType);
						}}
					/>
				</div>
				<Grid container spacing={1} alignItems="center">
					<Grid item xs={1}>
						<Typography align="center">{`프레임 ${'갯수'}`}</Typography>
					</Grid>
					<Grid item xs={5}>
						<Button fullWidth variant="contained" size="small" onClick={() => setOpenFrame(true)} color="primary">
							프레임 주문하기
						</Button>
					</Grid>
					<Grid item xs={1}>
						<Typography align="center">{`렌즈 ${'갯수'}`}</Typography>
					</Grid>
					<Grid item xs={5}>
						<Button fullWidth variant="contained" onClick={() => setOpenLens(true)} color="primary">
							렌즈 주문하기
						</Button>
					</Grid>
				</Grid>
				<Box display="flex">
					<div style={{ height: 200, width: '50%' }}>
						<DataGridPro
							rows={[]}
							columns={customerFrameCol}
							checkboxSelection
							disableColumnFilter
							disableColumnMenu
							style={{
								border: 'none',
							}}
							localeText={koText}
							hideFooter
							autoHeight
							onCellClick={(params) => {
								// setFrameData(params.row as frameDataProps);
								setOpenFrame(true);
							}}
						/>
					</div>
					<div style={{ height: 200, width: '50%' }}>
						<DataGridPro
							rows={[]}
							columns={customerLensCol}
							checkboxSelection
							disableColumnFilter
							disableColumnMenu
							style={{
								border: 'none',
							}}
							localeText={koText}
							hideFooter
							autoHeight
							onCellClick={(params) => {
								setEyesData(params.row as LensType);
								setOpenLens(true);
							}}
						/>
					</div>
				</Box>
			</Box>
			<CustomerModal />
			<FrameModal />
			<LensModal />
			<FrameAsModal />
			<LensAsModal />
		</>
	);
}

export default CustomerCheck;
