import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function ArrayInput() {
	const { control, setValue } = useFormContext();
	const { fields, append, remove } = useFieldArray({ control, name: 'rectangleRange' });

	const rangesValue = useWatch({ control, name: 'ranges' });

	useEffect(() => {
		if (rangesValue === 'rectangle') {
			append({ sphMax: '', sphMin: '', cylMax: '', cylMin: '' });
			setValue('ranges', '');
		}
	}, [append, rangesValue, setValue]);

	return (
		<>
			{fields.map((item, index) => {
				return (
					<Grid container key={item.id} spacing={2} pl={2} pt={2}>
						<Grid item xs={2}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										name={`rectangleRange[${index}].sphMin`}
										label="SPH최소"
										size="small"
										fullWidth
									/>
								)}
								name={`rectangleRange[${index}].sphMin`}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										name={`rectangleRange[${index}].sphMax`}
										label="SPH최대"
										size="small"
										fullWidth
									/>
								)}
								name={`rectangleRange[${index}].sphMax`}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										name={`rectangleRange[${index}].cylMin`}
										label="CYL최소"
										size="small"
										fullWidth
									/>
								)}
								name={`rectangleRange[${index}].cylMin`}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										name={`rectangleRange[${index}].cylMax`}
										label="CYL최대"
										size="small"
										fullWidth
									/>
								)}
								name={`rectangleRange[${index}].cylMax`}
							/>
						</Grid>
						<Grid item>
							<Button onClick={() => remove(index)}>
								<DeleteIcon />
							</Button>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
}

export default ArrayInput;
