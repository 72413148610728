import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridRowModel } from '@mui/x-data-grid-pro';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import SubNav from '../../components/SubNav';
import koText from '../../util/localizationKR';
import CustomToolbar from '../../components/CustomToolbar';
import NoDataOverlay from '../../components/NoDataOverlay';
import StaffCol from '../../columns/admin/StaffCol';
import { adminStaffState, openStaffModal, staffDetail, staffListState } from '../../atom';
import StaffModal from '../../components/modal/StaffModal';
import { StaffType } from '../../util/Interface';
import { fetchUser } from '../../selector';
import { findUrlField } from '../../util/ReplaceValue';

function StaffManager() {
	const [staffList] = useRecoilState(staffListState);
	const setStaffObj = useSetRecoilState(staffDetail);
	const setOpenModal = useSetRecoilState(openStaffModal);
	const [adminStaff, setAdminStaff] = useRecoilState(adminStaffState);

	useEffect(() => {
		setAdminStaff(staffList);
	}, [setAdminStaff, staffList]);

	const userInfo = useRecoilValue(fetchUser);

	const location = useLocation();
	const field = findUrlField(location);

	if (!userInfo) {
		return null;
	}

	return (
		<>
			<Box px={5}>
				<SubNav />
				<div style={{ height: '80vh', width: '100%' }}>
					<DataGridPro
						rows={adminStaff ? (adminStaff as GridRowModel[]) : []}
						columns={StaffCol()}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						style={{ borderLeft: 'none', borderRight: 'none' }}
						localeText={koText}
						disableColumnMenu
						density={userInfo.dataGridState?.[`${field}`]?.density || 'standard'}
						initialState={{
							columns: {
								...userInfo.dataGridState?.[`${field}`]?.columns,
								columnVisibilityModel: userInfo.dataGridState?.[`${field}`]?.columns?.columnVisibilityModel,
							},
							filter: userInfo.dataGridState?.[`${field}`]?.filter,
						}}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay: NoDataOverlay,
						}}
						componentsProps={{
							toolbar: { field },
						}}
						onCellClick={(params) => {
							setStaffObj(params.row as StaffType);
							setOpenModal(true);
						}}
						hideFooter
					/>
				</div>
			</Box>
			<StaffModal />
		</>
	);
}

export default StaffManager;
