import { atom } from 'recoil';
import moment from 'moment';
import { ReserveType } from '../util/Interface';

const date: Date = new Date();
const remainder = 10 - (moment(date).minute() % 10);
const reserveDate = moment(date).add(remainder, 'minutes').toDate();

export default atom<ReserveType>({
	key: 'scheduleDetail',
	default: {
		storeId: '',
		customerId: '',
		reserveType: '',
		name: '',
		phone: '',
		reserveDate,
		reserveMedia: '',
		person: 1,
		staff: '',
		frameStaff: '',
		lensStaff: '',
		note: '',
		preferContact: '',
		cancel: false,
	},
});
