import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { CustomFieldType } from '../../util/Interface';

function CustomFieldTextInput({ field, label }: CustomFieldType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const handleValue = (value: any) => {
		if (field === 'colorCode') {
			if (value === Array) {
				return value.join(',');
			}
			return value || '';
		}
		return value || '';
	};

	return (
		<Grid item xs={2}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid, isTouched } }) => (
					<TextField
						name={field}
						onChange={field !== 'colorCode' ? onChange : (e) => onChange(e.target.value.split(','))}
						value={handleValue(value)}
						label={label}
						size="small"
						inputRef={ref}
						onBlur={onBlur}
						// InputLabelProps={{ style: { fontSize: 14 } }}
						fullWidth
						error={(invalid || isTouched) && Boolean(errors[`${field}`] || false)}
						helperText={(invalid || isTouched) && errors[`${field}`]?.message}
					/>
				)}
			/>
		</Grid>
	);
}

export default CustomFieldTextInput;
