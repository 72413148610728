import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { CustomerType, CustomerResponse, UserData } from '../util/Interface';
import { toServerCustomer } from '../util/ReplaceValue';
import ErrorCheck from '../util/ErrorCheck';

type createCustomerQuery = {
	data: {
		createCustomer: CustomerResponse;
	};
};

const createCustomer = async (data: CustomerType, userInfo: UserData | null) => {
	const input = toServerCustomer(data, userInfo);
	try {
		const customer = (await API.graphql({
			query: mutations.createCustomer,
			variables: { input },
		})) as createCustomerQuery;
		return customer.data.createCustomer;
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default createCustomer;
