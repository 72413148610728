import { atom } from 'recoil';

export interface ScheduleDate {
	start: Date;
	end: Date;
	type: string;
}

export default atom<ScheduleDate | null>({
	key: 'scheduleDate',
	default: null,
});
