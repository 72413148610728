import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputType } from '../../util/Interface';

function NumberInput({ field, label, grid }: InputType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value } }) => (
					<TextField
						value={value || 0}
						size="small"
						type="number"
						label={label}
						onChange={(e) => onChange(Number(e.target.value))}
						InputLabelProps={{ style: { fontSize: 14 } }}
						fullWidth
						error={Boolean(errors[`${field}`] || false)}
						helperText={errors[`${field}`]?.message}
					/>
				)}
			/>
		</Grid>
	);
}

export default NumberInput;
