import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { StaffType } from '../../util/Interface';
import { staffValidation } from '../../util/Validation';
import { loginUser, openStaffModal, staffDetail } from '../../atom';
import { staffForm } from '../../util/Array';
import { createStaff, updateStaff } from '../../query';
import ErrorCheck from '../../util/ErrorCheck';
import { toServerStaff } from '../../util/ReplaceValue';
import { SingleSelectInput, TextInput, PhoneInput } from '../form';

function StaffModal() {
	const userId = useRecoilValue(loginUser);
	const defaultValues = useRecoilValue(staffDetail);
	const resetDefaultValues = useResetRecoilState(staffDetail);
	const [openModal, setOpenModal] = useRecoilState(openStaffModal);
	const methods = useForm<StaffType>({
		defaultValues,
		resolver: yupResolver(staffValidation),
	});

	const { handleSubmit, reset } = methods;

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	const onSubmit: SubmitHandler<StaffType> = (data) => {
		const staff = { ...data, id: data.id.toLowerCase() };
		if (!defaultValues.createdAt && userId) {
			createStaff(staff, userId)
				.then()
				.catch((err) => ErrorCheck(err));
		} else if (userId) {
			const input = toServerStaff(staff, userId);
			updateStaff(input)
				.then()
				.catch((err) => ErrorCheck(err));
		}
		reset();
		resetDefaultValues();
		setOpenModal(false);
	};

	const handleClose = () => {
		reset();
		resetDefaultValues();
		setOpenModal(false);
	};

	return (
		<Dialog open={openModal} fullWidth maxWidth="lg">
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography>{defaultValues.createdAt ? '직원 데이터 수정' : '직원 데이터 추가'}</Typography>
					<Button onClick={handleClose}>
						<ClearOutlinedIcon color="action" />
					</Button>
				</Box>
			</DialogTitle>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid container spacing={2}>
							{staffForm.map((input) => {
								const { type, field, label, grid, options } = input;
								switch (type) {
									case 'text':
										if (!field || !label) return null;
										return (
											<TextInput key={`staffInput_${field}`} field={field} label={label} grid={grid} type={type} />
										);
									case 'phone':
										if (!field || !label) return null;
										return (
											<PhoneInput key={`staffInput_${field}`} field={field} label={label} grid={grid} type={type} />
										);
									case 'singleSelect':
										if (!field || !label) return null;
										return (
											<SingleSelectInput
												key={`staffInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												options={options}
												type={type}
												size="small"
											/>
										);
									default:
										return null;
								}
							})}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" type="submit" color="primary">
							{defaultValues.createdAt ? '수정' : '등록'}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
}

export default StaffModal;
