import { API } from 'aws-amplify';
import { StoreType } from '../util/Interface';
import { toServerStore } from '../util/ReplaceValue';
import * as mutations from '../graphql/mutations';
import ErrorCheck from '../util/ErrorCheck';

const updateStore = async (store: StoreType, userId: string) => {
	const input = toServerStore(store, userId);
	try {
		await API.graphql({
			query: mutations.updateStore,
			variables: { input },
		});
	} catch (err) {
		ErrorCheck(err);
	}
};

export default updateStore;
