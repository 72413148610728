import { API } from 'aws-amplify';
import { CustomerType, updateCustomerQuery, UserData } from '../util/Interface';
import { toServerCustomer } from '../util/ReplaceValue';
import * as mutations from '../graphql/mutations';
import ErrorCheck from '../util/ErrorCheck';

const updateCustomer = async (data: CustomerType, userInfo: UserData | null) => {
	const input = toServerCustomer(data, userInfo);
	try {
		const customer = (await API.graphql({
			query: mutations.updateCustomer,
			variables: { input },
		})) as updateCustomerQuery;
		return customer.data.updateCustomer;
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default updateCustomer;
