import { API } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../graphql/queries';
import { ReservationResponse, fetchRangeScheduleQuery } from '../util/Interface';
import { convertReserve } from '../util/ReplaceValue';

const fetchReserveByRange = async (storeId: string, start: Date, end: Date) => {
	const response: ReservationResponse[] = [];
	let token = '';
	while (token != null) {
		const data = (await API.graphql({
			query: queries.listReservationByStoreWithReserveDateRange,
			variables: {
				storeId,
				reserveDateFrom: moment(start).toISOString(),
				reserveDateTo: moment(end).add(1, 'days').toISOString(),
				limit: 100,
				nextToken: token === '' ? null : token,
			},
		})) as fetchRangeScheduleQuery;
		response.push(...data.data.listReservationByStoreWithReserveDateRange.items);
		token = data.data.listReservationByStoreWithReserveDateRange.nextToken;

		if (token === null) {
			break;
		}
	}
	return convertReserve(response).filter((reserve) => reserve.reserveType !== 'attention');
};

export default fetchReserveByRange;
