import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InfoAgree from '../InfoAgree';
import OfferAgree from '../OfferAgree';
import AdAgree from '../adAgree';

interface AgreeModalProps {
	open: boolean;
	setOpen: (value: boolean) => void;
	field: string;
}

function AgreeModal({ open, setOpen, field }: AgreeModalProps) {
	const handleClose = () => {
		setOpen(false);
	};

	const renderFieldAgree = () => {
		switch (field) {
			case 'infoAgree':
				return <InfoAgree />;
			case 'offerAgree':
				return <OfferAgree />;
			case 'adAgree':
				return <AdAgree />;
			default:
				return null;
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography fontSize={18}>브리즘 아이웨어 제작 동의서</Typography>
					<Button onClick={handleClose}>
						<ClearOutlinedIcon color="action" />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>{renderFieldAgree()}</DialogContent>
		</Dialog>
	);
}

export default AgreeModal;
