import { atom } from 'recoil';
import { StoreType } from '../util/Interface';

export default atom<StoreType>({
	key: 'storeState',
	default: {
		id: '',
		code: '',
		mobile: '',
		tel: '',
		name: '',
		status: '',
		storeType: '',
		openTime: '',
		closeTime: '',
		durationTime: '',
	},
});
