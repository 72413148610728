import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSetRecoilState } from 'recoil';
import { debounce } from 'lodash';
import { CustomerType } from '../util/Interface';
import { fetchCustomerBySearch } from '../query';
import { customerState } from '../atom';

function AutoSearchBar() {
	const [value, setValue] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [searchOptions, setSearchOptions] = useState<CustomerType[]>([]);
	const setCustomer = useSetRecoilState(customerState);

	const makeOptions = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (e.target.value && e.target.value.length > 1) {
			setLoading(true);
			const searchValue = e.target.value;
			fetchCustomerBySearch({ searchValue }).then((result) => {
				if (result) {
					setLoading(false);
					setSearchOptions(result);
				}
			});
		} else {
			setLoading(false);
			setSearchOptions([]);
		}
	};

	const delayMakeOption = useMemo(() => debounce(makeOptions, 200), []);

	useEffect(() => {
		return () => {
			delayMakeOption.cancel();
		};
	}, [delayMakeOption]);

	return (
		<Autocomplete
			freeSolo
			disableClearable
			options={searchOptions}
			size="small"
			loading={loading}
			inputValue={value || ''}
			onInputChange={(_, inputValue) => {
				return setValue(inputValue);
			}}
			onChange={(event, searchValue, reason, details) => {
				if (details?.option) {
					setCustomer(details.option);
				}
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						startAdornment: <SearchIcon />,
					}}
					placeholder="(고객명, 전화번호)"
					fullWidth
					onChange={delayMakeOption}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id}>
						<Grid container>
							<Grid item xs={6}>
								<Typography>{option.name}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography fontSize={12}>{`(${option?.phone || ''})`}</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
			getOptionLabel={(option) => {
				if (/^\d+$/.test(value)) {
					return (option as CustomerType).phone || '';
				}
				return (option as CustomerType).name || '';
			}}
		/>
	);
}

export default AutoSearchBar;
