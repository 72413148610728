import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { getStoreQuery } from '../util/Interface';
import ErrorCheck from '../util/ErrorCheck';
import { convertStore } from '../util/ReplaceValue';

const getStore = async () => {
	try {
		const storeData = (await API.graphql({
			query: queries.listStore,
		})) as getStoreQuery;

		const storeResponses = storeData.data.listStore.items;
		return convertStore(storeResponses);
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default getStore;
