import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { NestArrayProps } from '../../util/Interface';

function NestAdditionArray({ nestIndex }: NestArrayProps) {
	const { control, setValue } = useFormContext();
	const { fields, remove, append } = useFieldArray({
		control,
		name: `lensOptionArray[${nestIndex}].additionRange`,
	});

	const rangeValue = useWatch({ control, name: `lensOptionArray[${nestIndex}].ranges` });

	useEffect(() => {
		if (rangeValue === 'addition') {
			append({ addMax: '', addMin: '', addUnit: '' });
			setValue(`lensOptionArray[${nestIndex}].ranges`, '');
		}
	}, [append, nestIndex, rangeValue, setValue]);

	return (
		<>
			{fields.map((item, index) => {
				return (
					<Grid container spacing={2} pt={2} key={item.id}>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].additionRange[${index}].addMin`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].additionRange[${index}].addMin`}
										label="ADD 최소"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].additionRange[${index}].addMax`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].additionRange[${index}].addMax`}
										label="ADD 최대"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].additionRange[${index}].addUnit`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].additionRange[${index}].addUnit`}
										label="ADD 단위"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item>
							<Button onClick={() => remove(index)}>
								<DeleteIcon />
							</Button>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
}

export default NestAdditionArray;
