import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { Chip } from '@mui/material';
import React from 'react';
import { changeValueToLabel, makePhoneNumber } from '../util/ReplaceValue';

const row: any = [];

export const customerCol: GridColDef[] = [
	{
		field: 'name',
		headerName: '이름',
		flex: 1,
	},
	{
		field: 'gender',
		headerName: '성별',
		flex: 1,
		renderCell: (params) => changeValueToLabel(params.value, 'gender')?.label,
	},
	{
		field: 'phone',
		headerName: '전화번호',
		flex: 1,
		renderCell: (params) => makePhoneNumber(params.value as string),
	},
	{
		field: 'address',
		headerName: '주소',
		flex: 3,
		valueGetter: (params) => {
			const { extraAddress, detailAddress, address } = params.row;
			if (address && extraAddress && detailAddress) {
				return `${address} (${extraAddress}) ${detailAddress}`;
			}
			return address;
		},
	},
	{
		field: 'note',
		headerName: '고객비고',
		flex: 2,
	},
	{
		field: 'care',
		headerName: '고객케어',
		flex: 2,
	},
	{
		field: 'rcgPath',
		headerName: '인지경로',
		flex: 1,
		renderCell: (params) => changeValueToLabel(params.value, 'rcgPath')?.label,
	},
];

export const customerFrameCol: GridColDef[] = [
	{
		field: 'status',
		headerName: '상태',
		hideSortIcons: true,
		flex: 2,
	},
	{
		field: 'orderDate',
		headerName: '주문일자',
		flex: 2,
		hideSortIcons: true,
	},
	{
		field: 'name',
		headerName: '모델명',
		flex: 2,
		hideSortIcons: true,
	},
	{
		field: 'size',
		headerName: '사이즈',
		flex: 2,
		hideSortIcons: true,
	},
	{
		field: 'color',
		headerName: '컬러',
		flex: 2,
		hideSortIcons: true,
	},
	{
		field: 'as',
		headerName: 'AS',
		flex: 2,
		hideSortIcons: true,
	},
];
export const customerLensCol: GridColDef[] = [
	{
		field: 'orderDate',
		headerName: '주문일자',
		flex: 2,
		hideSortIcons: true,
	},
	{
		field: 'brand',
		headerName: '브랜드',
		flex: 2,
		hideSortIcons: true,
	},
	{
		field: 'power',
		headerName: '굴절률',
		flex: 2,
		hideSortIcons: true,
	},
	{
		field: 'lensDesign',
		headerName: '설계',
		flex: 3,
		hideSortIcons: true,
	},
	{
		field: 'as',
		headerName: 'AS',
		flex: 1,
		hideSortIcons: true,
	},
];

export const frameModalCol: GridColDef[] = [
	{
		field: 'customKinds',
		headerName: '맞춤종류',
		hideSortIcons: true,
		flex: 1,
		editable: true,
	},
	{
		field: 'productName',
		headerName: '모델명',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'size',
		headerName: '사이즈',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'color',
		headerName: '모델컬러',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'release',
		headerName: '출고',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'templeType',
		headerName: '템플 종류',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'templeSize',
		headerName: '템플 길이',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'nosePad',
		headerName: '코패드',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'bridge',
		headerName: '브릿지',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
	{
		field: 'lens',
		headerName: '렌즈',
		flex: 1,
		hideSortIcons: true,
		editable: true,
	},
];

export const productCol: GridColDef[] = [
	{
		field: 'orderNumber',
		headerName: '주문번호',
		hideSortIcons: true,
		width: 120,
	},
	{
		field: 'formula',
		headerName: '포뮬라',
		width: 120,
		hideSortIcons: true,
		renderCell: (params) =>
			String(params.getValue(params.id, 'orderNumber')).slice(
				-3,
				String(params.getValue(params.id, 'orderNumber')).length
			),
	},
	{
		field: 'store',
		headerName: '매장',
		width: 120,
		hideSortIcons: true,
	},
	{
		field: 'status',
		headerName: '진행상태',
		width: 120,
		hideSortIcons: true,
		renderCell: (params) => <Chip label={params.value} style={{ backgroundColor: '#FBEBBC' }} />,
	},
	{
		field: 'productName',
		headerName: '모델명',
		width: 120,
		hideSortIcons: true,
	},
	{
		field: '사이즈',
		headerName: 'size',

		hideSortIcons: true,
	},
	{
		field: 'color',
		headerName: '색상',
		width: 120,
		hideSortIcons: true,
	},
	{
		field: 'nosePad',
		headerName: '코패드',
		width: 120,
		hideSortIcons: true,
	},
	{
		field: 'request',
		headerName: '요청사항',
		width: 120,
		hideSortIcons: true,
	},
	{
		field: 'hopeDate',
		headerName: '희망수령일',
		width: 120,
		hideSortIcons: true,
		renderCell: (params: GridRenderCellParams) => `${moment(new Date(String(params.value))).format('YY.MM.DD')}`,
	},
	{
		field: 'productDate',
		headerName: '생산경과일',
		width: 120,
		hideSortIcons: true,
	},
	{
		field: 'totalDate',
		headerName: '총 경과일',
		width: 120,
		hideSortIcons: true,
	},
];

export const designCol: GridColDef[] = [
	{
		field: 'orderNumber',
		headerName: '주문번호',
		flex: 1,
	},
	{
		field: 'type',
		headerName: '맞춤종류',

		flex: 1,
	},
	{
		field: 'orderDate',
		headerName: '주문날짜',
		flex: 1,
		renderCell: (params) => `${moment(String(params.value)).format('YY.MM.DD')}`,
	},
	{
		field: 'name',
		headerName: '고객명',
		flex: 1,
	},
	{
		field: 'orderStaff',
		headerName: '주문 담당자',
		flex: 1,
	},
	{
		field: 'fittingStaff',
		type: 'singleSelect',
		headerName: '설계담당자',
		valueOptions: [],
		flex: 1,
		editable: true,
	},
	{
		field: 'designStaff',
		type: 'singleSelect',
		headerName: 'PD 담당자',
		valueOptions: [],
		flex: 1,
		editable: true,
	},
	{
		field: 'designDate',
		headerName: '설계일자',
		flex: 1,
		renderCell: (params) => `${moment(String(params.value)).format('YY.MM.DD')}`,
	},
];

export default row;
