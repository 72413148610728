/* eslint-disable */

export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
  ) {
    createAttachment(input: $input) {
      id
      tableLink
      tableName
      tableId
      tableField
      name
      extension
      awsKey
      etag
      link
      thumbnail
      preview
      fileSize
      sharedScope
      downloadCount
      googleDriveId
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      tableLink
      tableName
      tableId
      tableField
      name
      extension
      awsKey
      etag
      link
      thumbnail
      preview
      fileSize
      sharedScope
      downloadCount
      googleDriveId
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      tableLink
      tableName
      tableId
      tableField
      name
      extension
      awsKey
      etag
      link
      thumbnail
      preview
      fileSize
      sharedScope
      downloadCount
      googleDriveId
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createCare = /* GraphQL */ `
  mutation CreateCare(
    $input: CreateCareInput!
  ) {
    createCare(input: $input) {
      id
      field
      careId
      careOption
      careValue
      careType
      score
      note
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateCare = /* GraphQL */ `
  mutation UpdateCare(
    $input: UpdateCareInput!
    $condition: ModelCareConditionInput
  ) {
    updateCare(input: $input, condition: $condition) {
      id
      field
      careId
      careOption
      careValue
      careType
      score
      note
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteCare = /* GraphQL */ `
  mutation DeleteCare(
    $input: DeleteCareInput!
    $condition: ModelCareConditionInput
  ) {
    deleteCare(input: $input, condition: $condition) {
      id
      field
      careId
      careOption
      careValue
      careType
      score
      note
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
  ) {
    createComment(input: $input) {
      id
      field
      comment
      score
      note
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      field
      comment
      score
      note
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      field
      comment
      score
      note
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createCustomField = /* GraphQL */ `
  mutation CreateCustomField(
    $input: CreateCustomFieldInput!
  ) {
    createCustomField(input: $input) {
      id
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateCustomField = /* GraphQL */ `
  mutation UpdateCustomField(
    $input: UpdateCustomFieldInput!
    $condition: ModelCustomFieldConditionInput
  ) {
    updateCustomField(input: $input, condition: $condition) {
      id
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteCustomField = /* GraphQL */ `
  mutation DeleteCustomField(
    $input: DeleteCustomFieldInput!
    $condition: ModelCustomFieldConditionInput
  ) {
    deleteCustomField(input: $input, condition: $condition) {
      id
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createCustomLayout = /* GraphQL */ `
  mutation CreateCustomLayout(
    $input: CreateCustomLayoutInput!
  ) {
    createCustomLayout(input: $input) {
      id
      page
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateCustomLayout = /* GraphQL */ `
  mutation UpdateCustomLayout(
    $input: UpdateCustomLayoutInput!
    $condition: ModelCustomLayoutConditionInput
  ) {
    updateCustomLayout(input: $input, condition: $condition) {
      id
      page
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteCustomLayout = /* GraphQL */ `
  mutation DeleteCustomLayout(
    $input: DeleteCustomLayoutInput!
    $condition: ModelCustomLayoutConditionInput
  ) {
    deleteCustomLayout(input: $input, condition: $condition) {
      id
      page
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
  ) {
    createCustomer(input: $input) {
      id
      name
      phone
      gender
      birthday
      email
      address
      orgName
      agreement
      signature
      channel
      referrer
      preferContact
      detail
      status
      note
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      phone
      gender
      birthday
      email
      address
      orgName
      agreement
      signature
      channel
      referrer
      preferContact
      detail
      status
      note
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      phone
      gender
      birthday
      email
      address
      orgName
      agreement
      signature
      channel
      referrer
      preferContact
      detail
      status
      note
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const sendSMS = /* GraphQL */ `
  mutation SendSMS($sender: String!, $receiver: String!, $contents: String!) {
    sendSMS(sender: $sender, receiver: $receiver, contents: $contents)
  }
`;

export const createProductInfo = /* GraphQL */ `
  mutation CreateProductInfo(
    $input: CreateProductInfoInput!
  ) {
    createProductInfo(input: $input) {
      id
      category
      line
      parts
      material
      label
      company
      brand
      detail
      status
      launchDate
      expireDate
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateProductInfo = /* GraphQL */ `
  mutation UpdateProductInfo(
    $input: UpdateProductInfoInput!
    $condition: ModelProductInfoConditionInput
  ) {
    updateProductInfo(input: $input, condition: $condition) {
      id
      category
      line
      parts
      material
      label
      company
      brand
      detail
      status
      launchDate
      expireDate
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteProductInfo = /* GraphQL */ `
  mutation DeleteProductInfo(
    $input: DeleteProductInfoInput!
    $condition: ModelProductInfoConditionInput
  ) {
    deleteProductInfo(input: $input, condition: $condition) {
      id
      category
      line
      parts
      material
      label
      company
      brand
      detail
      status
      launchDate
      expireDate
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
  ) {
    createReservation(input: $input) {
      id
      storeId
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      storeId
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      storeId
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customerId
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
  ) {
    createStaff(input: $input) {
      id
      name
      phone
      gender
      birthday
      email
      profile
      address
      cognitoId
      lastLogin
      lastLoginIP
      duty
      detail
      status
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      name
      phone
      gender
      birthday
      email
      profile
      address
      cognitoId
      lastLogin
      lastLoginIP
      duty
      detail
      status
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
      id
      name
      phone
      gender
      birthday
      email
      profile
      address
      cognitoId
      lastLogin
      lastLoginIP
      duty
      detail
      status
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
  ) {
    createStore(input: $input) {
      id
      name
      detail
      storeType
      status
      code
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      name
      detail
      storeType
      status
      code
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      name
      detail
      storeType
      status
      code
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const createVersion = /* GraphQL */ `
  mutation CreateVersion(
    $input: CreateVersionInput!
  ) {
    createVersion(input: $input) {
      id
      version
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      added
      updated
      deleted
      status
      createdAt
      updatedAt
    }
  }
`;

export const updateVersion = /* GraphQL */ `
  mutation UpdateVersion(
    $input: UpdateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    updateVersion(input: $input, condition: $condition) {
      id
      version
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      added
      updated
      deleted
      status
      createdAt
      updatedAt
    }
  }
`;

export const deleteVersion = /* GraphQL */ `
  mutation DeleteVersion(
    $input: DeleteVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    deleteVersion(input: $input, condition: $condition) {
      id
      version
      staffId
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      added
      updated
      deleted
      status
      createdAt
      updatedAt
    }
  }
`;

