import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { CustomFieldType } from '../../util/Interface';
import { productInfoListState } from '../../atom';

function CustomFieldMultiSelect({ field, label, option }: CustomFieldType) {
	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const productList = useRecoilValue(productInfoListState);
	const makeOption = (value: any) => {
		if (field.toLowerCase().includes('color')) {
			const colorList = productList
				.filter((product) => product.parts === 'color')
				.filter((product) => product.status === 'valid')
				.filter((product) => product.material === watch().material);
			return colorList
				?.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
				.map((obj) => {
					return (
						<MenuItem key={obj.id} value={obj.id}>
							<Checkbox checked={value?.indexOf(obj.id) > -1} />
							<Box sx={{ backgroundColor: `${obj.labelColor}`, width: '10px', height: '10px', marginLeft: '4px' }} />
							<ListItemText primary={obj.label} />
						</MenuItem>
					);
				});
		}
		if (field === 'connectParts') {
			const partsList = productList
				.filter((product) => product.parts === 'parts')
				.filter((product) => product.status === 'valid');
			return partsList.map((obj) => {
				return (
					<MenuItem key={obj.id} value={obj.id}>
						<Checkbox checked={value?.indexOf(obj.id) > -1} />
						<ListItemText primary={obj.label} />
					</MenuItem>
				);
			});
		}
		return option?.map((obj) => {
			return (
				<MenuItem key={obj.value} value={obj.value}>
					<Checkbox checked={value?.indexOf(obj.value) > -1} />
					<ListItemText primary={obj.label} />
				</MenuItem>
			);
		});
	};

	const makePartsList = (selected: any, value: string) => {
		const list = productList
			.filter((product) => product.parts === value)
			.filter((product) => product.status === 'valid');
		return list
			.filter((product) => selected.includes(product.id))
			.map((product) => product.label)
			.join(', ');
	};

	const handleRenderValue = (selected: any) => {
		if (field.toLowerCase().includes('color')) {
			const colorList = productList
				.filter((product) => product.parts === 'color')
				.filter((product) => product.material === watch().material);
			return colorList
				.filter((color) => selected.includes(color.id))
				.map((color) => color.label)
				.join(', ');
		}
		if (field === 'connectParts') {
			const partsList = productList
				.filter((product) => product.parts === 'parts')
				.filter((product) => product.status === 'valid');
			return partsList
				.filter((parts) => selected.includes(parts.id))
				.map((parts) => parts.label)
				.join(', ');
		}

		if (option?.length === 0) {
			return option
				.filter((product) => selected.includes(product.value))
				.map((product) => product.label)
				.join(', ');
		}
		if (option) {
			return option
				.filter((product) => selected.includes(product.value))
				.map((product) => product.label)
				.join(', ');
		}
		return makePartsList(selected, field);

		// return (selected as string[]).join(', ');
	};

	return (
		<Grid item xs={2}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
					<FormControl fullWidth size="small">
						<InputLabel>{label}</InputLabel>
						<Select
							name={field}
							onChange={onChange}
							value={value || []}
							label={label}
							inputRef={ref}
							multiple
							renderValue={(selected) => handleRenderValue(selected)}
							error={invalid && Boolean(errors[`${field}`] || false)}
						>
							{makeOption(value)}
						</Select>
					</FormControl>
				)}
			/>
		</Grid>
	);
}

export default CustomFieldMultiSelect;
