import { selector } from 'recoil';
import { reserveDetail, storeListState, staffListState, loginUser } from '../atom';
import { StoreType, UserData } from '../util/Interface';

export default selector<UserData | null>({
	key: 'fetchUser',
	get: ({ get }) => {
		const userId = get(loginUser);
		const staffList = get(staffListState);
		const stores = get(storeListState);
		if (userId === '' || staffList === null) return null;

		const userData = staffList.find((staff) => staff.id === userId);
		if (!userData || !stores) return null;
		const { storeId } = userData;
		const storeTel = stores.find((store: StoreType) => store?.id === storeId)?.tel || '';
		const storeType = stores.find((store: StoreType) => store?.id === storeId)?.storeType || '';
		const coworker = staffList
			.filter((staff) => staff.storeId === storeId)
			.concat(staffList.filter((staff) => staff.storeId !== storeId))
			.filter((staff) => staff.status === 'valid')
			.map((staff) => ({
				value: staff.id,
				label: staff.labelKr,
				color: staff.color || '',
			}));

		const storeOpenTime = stores.find((store: StoreType) => store?.id === storeId)?.openTime || '11:00:00';
		const storeCloseTime = stores.find((store: StoreType) => store?.id === storeId)?.closeTime || '20:00:00';
		const storeDurationTime = stores.find((store: StoreType) => store?.id === storeId)?.durationTime || '00:10:00';

		const staffCount: number = staffList.filter((staff) => staff.storeId === storeId).length;

		return {
			...userData,
			coworker,
			storeTel,
			storeType,
			staffCount,
			storeOpenTime,
			storeCloseTime,
			storeDurationTime,
		} as UserData;
	},
	set: ({ get, set }) => {
		const userInfo = get(loginUser);
		if (userInfo) {
			set(reserveDetail, (prevValue) => ({ ...prevValue, staff: userInfo }));
		}
	},
});
