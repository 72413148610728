import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { StaffResponse, ToServerStaff } from '../util/Interface';
import ErrorCheck from '../util/ErrorCheck';
import { convertStaff } from '../util/ReplaceValue';

export type UpdateStaff = {
	data: {
		updateStaff: StaffResponse;
	};
};

const updateStaff = async (input: ToServerStaff) => {
	try {
		const data = (await API.graphql({
			query: mutations.updateStaff,
			variables: {
				input,
			},
		})) as UpdateStaff;
		const staff: StaffResponse[] = [];
		staff.push(data.data.updateStaff);
		return convertStaff(staff)[0];
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default updateStaff;
