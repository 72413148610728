import React from 'react';
import { Box } from '@mui/material';
import PremiumCalendar from '../../components/PremiumCalendar';

function FieldManage() {
	return (
		<Box>
			<PremiumCalendar />
		</Box>
	);
}

export default FieldManage;
