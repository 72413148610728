import { atom } from 'recoil';
import { ProductInfoType } from '../util/Interface';

export default atom<ProductInfoType>({
	key: 'productDetail',
	default: {
		id: '',
		brand: '',
		label: '',
		category: '',
		company: '',
		expireDate: '2100-01-01T00:00:00.000Z',
		status: 'valid',
		launchDate: '',
		line: '',
		material: '',
		note: '',
		parts: '',
		updatedAt: '',
		auto: false,
	},
});
