import { InputType } from './Interface';

export interface optionProps {
	label: string;
	value: string;
	color?: string;
}

const reserveType = [
	{ label: '맞춤', value: 'custom', color: '#8DCFEF' },
	{ label: '수령', value: 'package', color: '#A4DE91' },
	{ label: 'AS', value: 'as', color: '#ECA1DE' },
	{ label: '단순방문', value: 'attention', color: '#F1A2B7' },
];

const colorList = [
	{ color: '#D2DFFC', label: 'color', value: '#D2DFFC' },
	{ color: '#A5C6FA', label: 'color', value: '#A5C6FA' },
	{ color: '#D6EFFB', label: 'color', value: '#D6EFFB' },
	{ color: '#8DCFEF', label: 'color', value: '#8DCFEF' },
	{ color: '#CCF4E9', label: 'color', value: '#CCF4E9' },
	{ color: '#90DCC6', label: 'color', value: '#90DCC6' },
	{ color: '#D8F6C9', label: 'color', value: '#D8F6C9' },
	{ color: '#A4DE91', label: 'color', value: '#A4DE91' },
	{ color: '#FBEBBC', label: 'color', value: '#FBEBBC' },
	{ color: '#F8D87E', label: 'color', value: '#F8D87E' },
	{ color: '#F9E3D7', label: 'color', value: '#F9E3D7' },
	{ color: '#F3AD88', label: 'color', value: '#F3AD88' },
	{ color: '#F9DDE5', label: 'color', value: '#F9DDE5' },
	{ color: '#F1A2B7', label: 'color', value: '#F1A2B7' },
	{ color: '#F9DBF4', label: 'color', value: '#F9DBF4' },
	{ color: '#ECA1DE', label: 'color', value: '#ECA1DE' },
	{ color: '#EBE2FC', label: 'color', value: '#EBE2FC' },
	{ color: '#C8B1FA', label: 'color', value: '#C8B1FA' },
	{ color: '#EEEEEE', label: 'color', value: '#EEEEEE' },
	{ color: '#CCCCCC', label: 'color', value: '#CCCCCC' },
];

// value에 att가 포함된 것들과 구분해야함
const reserveMedia = [
	{ label: '웹사이트', value: 'website', color: '#F9E3D7' },
	{ label: '매장전화/핸드폰', value: 'phone', color: '#D2DFFC' },
	{ label: '카카오톡/채널톡', value: 'kakao', color: '#FBEBBC' },
	{ label: '네이버예약/톡톡', value: 'naver', color: '#D8F6C9' },
	{ label: '미예약/직접방문', value: 'walk', color: '#EBE2FC' },
	{ label: '협찬/테스트', value: 'sponsor', color: '#F9DBF4' },
	{ label: '브리즘 문의/체험', value: 'attBreezm', color: '#D2DFFC' },
	{ label: '타사자테 문의/AS', value: 'attOtherFrame', color: '#FBEBBC' },
	{ label: '렌즈 문의/검안', value: 'attLens', color: '#CCF4E9' },
];

export const rcgPath = [
	{ label: '재방문', value: 'reVisit', color: '#D2DFFC' },
	{ label: '온라인광고', value: 'onlineAd', color: '#D8F6C9' },
	{ label: '커뮤니티', value: 'community', color: '#D8F6C9' },
	{ label: '방송/기사', value: 'broadcast', color: '#D8F6C9' },
	{ label: '소개(고객)', value: 'introduceC', color: '#FBEBBC' },
	{ label: '소개(임직원)', value: 'introduceB', color: '#FBEBBC' },
	{ label: '협찬/증정', value: 'sponsor', color: '#F9DDE5' },
	{ label: '테스트', value: 'test', color: '#F9DDE5' },
	{ label: '협력/입주사', value: 'coopCo', color: '#F9DDE5' },
	{ label: '해당없음', value: 'none', color: '#EBE2FC' },
];

const gender = [
	{ label: '남자', value: 'M', color: '#D2DFFC' },
	{ label: '여자', value: 'F', color: '#F9DBF4' },
];

const storeType = [
	{ value: '', label: '미선택', color: '' },
	{ value: 'store', label: '매장', color: '' },
	{ value: 'factory', label: '공장', color: '' },
	{ value: 'office', label: '사무실', color: '' },
	{ value: 'popup', label: '임시', color: '' },
	{ value: 'resign', label: '퇴사', color: '' },
];

export const preferContact = [
	{ label: '카카오톡', value: 'kakao', color: '#FBEBBC' },
	{ label: '전화', value: 'phone', color: '#D8F6C9' },
	{ label: '문자', value: 'text', color: '#F9DDE5' },
];

interface selectListProps {
	[index: string]: optionProps[];
	reserveType: optionProps[];
	gender: optionProps[];
	preferContact: optionProps[];
	reserveMedia: optionProps[];
	rcgPath: optionProps[];
}

export const selectList: selectListProps = {
	reserveType,
	gender,
	preferContact,
	reserveMedia,
	rcgPath,
};

export const customerInputsFir: InputType[] = [
	{
		label: '성함',
		type: 'loadAutocomplete',
		field: 'name',
		grid: 2,
	},
	{
		label: '전화번호',
		type: 'phoneButton',
		field: 'phone',
		grid: 2,
	},
	{
		label: '인증번호',
		type: 'textButton',
		field: 'certificationNumber',
		grid: 2,
	},
	{
		label: '성별',
		type: 'singleSelect',
		field: 'gender',
		options: gender,
		grid: 2,
	},
	{
		label: '생년월일',
		type: 'date',
		field: 'birthday',
		grid: 2,
	},
	{
		label: '이메일',
		type: 'text',
		field: 'email',
		grid: 2,
	},
	{
		label: '주소',
		type: 'address',
		field: 'address',
		grid: 5,
	},
	{
		label: '상세',
		type: 'text',
		field: 'detailAddress',
		grid: 5,
	},
];

export const customerInputsSec: InputType[] = [
	{
		label: '[필수] 고객 정보 활용 동의(개인정보수집/이용)',
		type: 'checkbox',
		field: 'infoAgree',
		grid: 6,
	},
	/* {
		label: '[필수] 브리즘의 as 및 환불규정',
		type: 'checkbox',
		field: 'asAgree',
		grid: 6,
	}, */
	{
		label: '[필수] 개인정보 제3자 제공',
		type: 'checkbox',
		field: 'offerAgree',
		grid: 6,
	},
	{
		label: '[선택] 홍보성 정보에 대한 수신동의',
		type: 'checkbox',
		field: 'adAgree',
		grid: 6,
	},
];

export const customerInputsThr: InputType[] = [
	{
		label: '인지경로',
		type: 'singleSelect',
		options: rcgPath,
		field: 'rcgPath',
		grid: 2,
	},
	{
		label: '선호연락',
		type: 'singleSelect',
		options: preferContact,
		field: 'preferContact',
		grid: 2,
	},
	{
		label: '워크인',
		type: 'checkbox',
		field: 'noReserve',
		grid: 2,
	},
];

// 예약필드 값
export const reserveInputs: InputType[] = [
	{
		label: '예약유형',
		type: 'singleSelect',
		options: reserveType,
		field: 'reserveType',
		grid: 2,
	},
	{
		label: '고객명',
		type: 'loadAutocomplete',
		field: 'name',
		grid: 2,
	},
	{
		label: '연락처',
		type: 'loadAutocomplete',
		field: 'phone',
		grid: 2,
	},
	{
		label: '예약일시',
		type: 'datetime',
		field: 'reserveDate',
		grid: 2,
	},
	{
		label: '예약경로',
		type: 'singleSelect',
		options: reserveMedia,
		field: 'reserveMedia',
		grid: 2,
	},
	{
		label: '예약인원',
		type: 'number',
		field: 'person',
		grid: 2,
	},
	{
		label: '예약담당',
		type: 'singleSelect',
		field: 'staff',
		grid: 2,
	},
	{
		label: '프레임담당',
		type: 'autocomplete',
		field: 'frameStaff',
		grid: 2,
	},
	{
		label: '렌즈담당',
		type: 'autocomplete',
		field: 'lensStaff',
		grid: 2,
	},
	{
		label: '노트',
		type: 'text',
		field: 'note',
		grid: 12,
	},
	{
		label: '고객 비고',
		type: 'text',
		field: 'customerNote',
		grid: 12,
	},
	{
		label: '고객 케어',
		type: 'text',
		field: 'care',
		grid: 12,
	},
	{
		label: '선호 연락',
		type: 'singleSelect',
		options: preferContact,
		field: 'preferContact',
		grid: 12,
	},
];

// 관심고객 필드

export const attArray: InputType[] = [
	{
		label: '예약유형',
		type: 'singleSelect',
		options: reserveType,
		field: 'reserveType',
		grid: 2,
	},
	{
		label: '예약일시',
		type: 'datetime',
		field: 'reserveDate',
		grid: 2,
	},
	{
		label: '예약경로',
		type: 'singleSelect',
		options: reserveMedia,
		field: 'reserveMedia',
		grid: 2,
	},
];

// 직원정보관련

export const staffForm: InputType[] = [
	{
		label: '아이디',
		type: 'text',
		field: 'id',
		grid: 2,
	},
	{
		label: '이름',
		type: 'text',
		field: 'name',
		grid: 2,
	},
	{
		label: '한글아이디',
		type: 'text',
		field: 'labelKr',
		grid: 2,
	},
	{
		label: '연락처',
		type: 'phone',
		field: 'phone',
		grid: 2,
	},
	{
		label: '근무지',
		type: 'singleSelect',
		field: 'storeId',
		options: [],
		grid: 2,
	},
	{
		label: '색상',
		type: 'singleSelect',
		field: 'color',
		options: colorList,
		grid: 2,
	},
];

export const storeForm: InputType[] = [
	{
		label: '아이디',
		type: 'text',
		field: 'id',
		grid: 2,
	},
	{
		label: '코드',
		type: 'text',
		field: 'code',
		grid: 2,
	},
	{
		label: '휴대전화',
		type: 'phone',
		field: 'mobile',
		grid: 2,
	},
	{
		label: '전화번호',
		type: 'phone',
		field: 'tel',
		grid: 2,
	},
	{
		label: '지점명',
		type: 'text',
		field: 'name',
		grid: 2,
	},
	{
		label: '구분',
		type: 'singleSelect',
		field: 'storeType',
		options: storeType,
		grid: 2,
	},
	{
		label: '영업시작',
		type: 'time',
		field: 'openTime',
		grid: 2,
	},
	{
		label: '영업종료',
		type: 'time',
		field: 'closeTime',
		grid: 2,
	},
	{
		label: '스케줄간격',
		type: 'durationTime',
		field: 'durationTime',
		grid: 2,
	},
];

// 고객정보

export const customerArray: InputType[] = [
	{
		label: '고객명',
		type: 'text',
		field: 'name',
		grid: 2,
	},
	{
		label: '연락처',
		type: 'phone',
		field: 'phone',
		grid: 2,
	},
	{
		label: '생년월일',
		type: 'date',
		field: 'birthday',
		grid: 2,
	},
	{
		label: '성별',
		type: 'singleSelect',
		options: gender,
		field: 'gender',
		grid: 2,
	},
	{
		label: '선호연락',
		type: 'singleSelect',
		field: 'preferContact',
		options: preferContact,
		grid: 2,
	},
	{
		label: '이메일',
		type: 'text',
		field: 'email',
		grid: 2,
	},
	{
		label: '주소',
		type: 'address',
		field: 'address',
		grid: 6,
	},
	{
		label: '상세주소',
		type: 'text',
		field: 'detailAddress',
		grid: 4,
	},
	{
		label: '인지경로',
		type: 'singleSelect',
		options: rcgPath,
		field: 'rcgPath',
		grid: 2,
	},
	{
		label: '개인정보활용동의',
		type: 'checkBox',
		field: 'infoAgree',
		grid: 3,
	},
	{
		label: '제3자개인정보활용동의',
		type: 'checkBox',
		field: 'offerAgree',
		grid: 3,
	},
	{
		label: '홍보정보수신동의',
		type: 'checkBox',
		field: 'adAgree',
		grid: 3,
	},
];

export const frameArray: InputType[] = [
	{
		label: '모델명',
		type: 'autoComplete',
		field: 'name',
		grid: 2,
	},
	{
		label: '사이즈',
		type: 'singleSelect',
		field: 'size',
		grid: 2,
	},
	{
		label: '프론트색상',
		type: 'singleSelect',
		field: 'frontColor',
		grid: 2,
	},
	{
		label: '프론트파츠',
		type: 'singleSelect',
		field: 'frontParts',
		grid: 2,
	},
	{
		label: '프론트파츠컬러',
		type: 'singleSelect',
		field: 'frontPartsColor',
		grid: 2,
	},
	{
		label: '템플종류',
		type: 'singleSelect',
		field: 'temple',
		grid: 2,
	},
	{ label: '템플컬러', type: 'singleSelect', field: 'templeColor', grid: 2 },
	{
		label: '템플길이',
		type: 'singleSelect',
		field: 'templeLength',
		grid: 2,
	},
	{
		label: '템플파츠',
		type: 'singleSelect',
		field: 'templeParts',
		grid: 2,
	},
	{
		label: '템플파츠컬러',
		type: 'singleSelect',
		field: 'templePartsColor',
		grid: 2,
	},
	{
		label: '템플팁',
		type: 'singleSelect',
		field: 'tip',
		grid: 2,
	},
	{
		label: '팁컬러',
		type: 'singleSelect',
		field: 'tipColor',
		grid: 2,
	},
	{
		label: '코패드',
		type: 'singleSelect',
		field: 'nosepad',
		grid: 2,
	},
	{
		label: '브릿지',
		type: 'singleSelect',
		field: 'bridge',
		grid: 2,
	},
	{
		label: '브릿지컬러',
		type: 'singleSelect',
		field: 'bridgeColor',
		grid: 2,
	},
	{
		label: '설계요청',
		type: 'multiSelect',
		field: 'designReq',
		options: [
			{ label: '경사각(6±2)', value: 'pt' },
			{ label: 'VD(12±1)', value: 'vd' },
			{ label: '브릿지 폭(-)', value: 'bridgeX' },
			{ label: '코패드 상하위치(-)', value: 'padY' },
			{ label: '코패드 좌우위치(자동)', value: 'padX' },
			{ label: '코패드 전후위치(자동)', value: 'padZ' },
			{ label: '코패드 각도(자동)', value: 'padA' },
			{ label: '엔드피스 좌우길이(-)', value: 'endX' },
			{ label: '엔드피스 전후길이(자동)', value: 'endZ' },
			{ label: '엔드피스 평면각도(자동)', value: 'endP' },
			{ label: '엔드피스 측면각도(자동)', value: 'endS' },
		],
		grid: 2,
	},
	{
		type: 'ref',
		field: 'reqArray',
		options: [
			{ label: '경사각(6±2)', value: 'pt' },
			{ label: 'VD(12±1)', value: 'vd' },
			{ label: '브릿지 폭(-)', value: 'bridgeX' },
			{ label: '코패드 상하위치(-)', value: 'padY' },
			{ label: '코패드 좌우위치(자동)', value: 'padX' },
			{ label: '코패드 전후위치(자동)', value: 'padZ' },
			{ label: '코패드 각도(자동)', value: 'padA' },
			{ label: '엔드피스 좌우길이(-)', value: 'endX' },
			{ label: '엔드피스 전후길이(자동)', value: 'endZ' },
			{ label: '엔드피스 평면각도(자동)', value: 'endP' },
			{ label: '엔드피스 측면각도(자동)', value: 'endS' },
		],
		grid: 'auto',
	},
	{
		label: '정면',
		type: 'img',
		field: 'front',
		grid: 2,
	},
	{
		label: '좌측',
		type: 'img',
		field: 'left',
		grid: 2,
	},
	{
		label: '우측',
		type: 'img',
		field: 'right',
		grid: 2,
	},
	{
		label: '자테정면',
		type: 'img',
		field: 'beforeFront',
		grid: 2,
	},
	{
		label: '자테좌측',
		type: 'img',
		field: 'beforeLeft',
		grid: 2,
	},
	{
		label: '자테우측',
		type: 'img',
		field: 'beforeRight',
		grid: 2,
	},
	{
		label: '주문금액',
		type: 'currency',
		field: 'price',
		grid: 2,
	},
	{
		label: '배송구분',
		type: 'singleSelect',
		field: 'post',
		options: [
			{ label: '방문', value: 'visit' },
			{ label: '택배', value: 'delivery' },
		],
		grid: 2,
	},
	{
		label: '희망수령일',
		type: 'date',
		field: 'hopeDate',
		grid: 2,
	},
	{
		label: '설계자',
		type: 'autoComplete',
		field: 'designStaff',
		grid: 2,
	},
];

export const frameAsArray: InputType[] = [
	{
		label: 'FAS 구분',
		type: 'singleSelect',
		options: [
			{ label: '재설계-고객', value: 'reDesignC' },
			{ label: '재설계-내부', value: 'reDesignI' },
			{ label: '파손-고객', value: 'damageC' },
			{ label: '파손-내부', value: 'damageI' },
			{ label: '피팅', value: 'fitting' },
			{ label: '품질', value: 'quality' },
			{ label: '부품교체', value: 'partsReplace' },
			{ label: '교환', value: 'exchange' },
			{ label: '분실', value: 'lost' },
			{ label: '환불', value: 'refund' },
		],
		field: 'frameAsType',
		grid: 2,
	},
	{
		label: 'FAS 내용',
		type: 'dynamicMultiSelect',
		field: 'frameAsContext',
		grid: 2,
	},
	{
		label: 'FAS비고',
		type: 'longText',
		field: 'frameASComment',
		grid: 12,
	},
];

export const lensArray: InputType[] = [
	{
		label: '제품명',
		type: 'autoComplete',
		field: 'name',
		grid: 4,
	},
	{
		label: '코팅',
		type: 'singleSelect',
		field: 'lensCoating',
		grid: 2,
	},
	{
		label: '옵션1',
		type: 'dynamicSingleSelect',
		field: 'firstOption',
		condition: [{ field: 'lensCoating', value: '' }],
		grid: 2,
	},
	{
		label: '옵션2',
		type: 'dynamicSingleSelect',
		condition: [{ field: 'firstOption', value: '' }],
		field: 'secondOption',
		grid: 2,
	},
	{
		label: '옵션3',
		type: 'dynamicSingleSelect',
		condition: [{ field: 'secondOption', value: '' }],
		field: 'thirdOption',
		grid: 2,
	},
	{
		label: '베이스컬러',
		type: 'dynamicSingleSelect',
		condition: [
			{ field: 'name', value: 'tinted' },
			{ field: 'firstOption', value: 'disc' },
			{ field: 'secondOption', value: 'disc' },
			{ field: 'thirdOption', value: 'disc' },
		],
		field: 'baseColor',
		grid: 2,
	},
	{
		label: '미러컬러',
		type: 'dynamicSingleSelect',
		condition: [
			{ field: 'firstOption', value: 'mir' },
			{ field: 'secondOption', value: 'mir' },
			{ field: 'thirdOption', value: 'mir' },
		],
		field: 'mirColor',
		grid: 2,
	},
	{
		label: '여벌/주문',
		type: 'singleSelect',
		options: [
			{ label: '매장여벌', value: 'stock', color: '#D6EFFB' },
			{ label: '주문여벌', value: 'stockOrder', color: '#FBEBBC' },
			{ label: 'RX', value: 'order', color: '#F9DBF4' },
		],
		field: 'lensOrderType',
		grid: 2,
	},
	{
		label: '좌우유형',
		type: 'singleSelect',
		options: [
			{ label: '양쪽', value: 'both', color: '#EEEEEE' },
			{ label: '좌측', value: 'left', color: '#CCCCCC' },
			{ label: '우측', value: 'right', color: '#CCCCCC' },
		],
		field: 'lensCategory',
		grid: 2,
	},
	{
		label: '가격',
		type: 'text',
		field: 'price',
		grid: 2,
	},
	{
		type: 'multiSelect',
		field: 'lensExaminationInfo',
		label: '고객정보 추가',
		options: [
			{ label: '검안값', value: 'Measure' },
			{ label: '자도수', value: 'Before' },
		],
		grid: 'auto',
	},
];

export const lensPrescription: InputType[] = [
	{
		label: 'R',
		type: 'label',
		field: 'rightLabel',
		grid: 1,
	},
	{
		label: 'SPH',
		type: 'text',
		field: 'rSph',
		grid: 1,
	},
	{
		label: 'CYL',
		type: 'text',
		field: 'rCyl',
		grid: 1,
	},
	{
		label: 'AXI',
		type: 'text',
		field: 'rAxi',
		grid: 1,
	},
	{
		label: 'ADD',
		type: 'text',
		field: 'rAdd',
		grid: 1,
	},
	{
		label: '내외',
		type: 'singleSelect',
		options: [
			{ label: 'IN', value: 'in' },
			{ label: 'OUT', value: 'out' },
		],
		field: 'rInOut',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'rPrism1',
		grid: 1,
	},
	{
		label: 'P.D',
		type: 'text',
		field: 'rPd',
		grid: 1,
	},
	{
		label: 'O.H',
		type: 'text',
		field: 'rOh',
		grid: 1,
	},
	{
		label: 'C.V',
		type: 'text',
		field: 'rCv',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 4,
	},
	/* {
		label: 'S.O',
		type: 'label',
		grid: 1,
		field: 'rSoLabel',
	},
	{
		label: '특이사항',
		type: 'multiSelect',
		options: [
			{
				label: '눈부심 민감',
				value: 'light',
			},
			{
				label: '녹내장',
				value: 'eyeDisease1',
			},
			{
				label: '백내장, 라식/라섹 수술',
				value: 'eyeDisease2',
			},
			{
				label: '당뇨성 망막병',
				value: 'eyeDisease3',
			},
			{
				label: '황반변성, 망막색소변셩, 선천성 광과민',
				value: 'eyeDisease4',
			},
			{
				label: '하이커브',
				value: 'highCurve',
			},
			{
				label: '특수컬러',
				value: 'specialColor',
			},
			{
				label: '유리',
				value: 'glass',
			},
			{
				label: '근시완화(어린이)',
				value: 'children',
			},
		],
		field: 'rSo',
		grid: 2,
	}, */
	{
		type: 'blank',
		grid: 1,
	},
	{
		label: '상하',
		type: 'singleSelect',
		options: [
			{ label: 'UP', value: 'up' },
			{ label: 'DOWN', value: 'down' },
		],
		field: 'rUpDown',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'rPrism2',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 5,
	},
	{
		label: 'L',
		type: 'label',
		field: 'leftLabel',
		grid: 1,
	},
	{
		label: 'SPH',
		type: 'text',
		field: 'lSph',
		grid: 1,
	},
	{
		label: 'CYL',
		type: 'text',
		field: 'lCyl',
		grid: 1,
	},
	{
		label: 'AXI',
		type: 'text',
		field: 'lAxi',
		grid: 1,
	},
	{
		label: 'ADD',
		type: 'text',
		field: 'lAdd',
		grid: 1,
	},
	{
		label: '내외',
		type: 'singleSelect',
		options: [
			{ label: 'IN', value: 'in' },
			{ label: 'OUT', value: 'out' },
		],
		field: 'lInOut',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'lPrism1',
		grid: 1,
	},
	{
		label: 'P.D',
		type: 'text',
		field: 'lPd',
		grid: 1,
	},
	{
		label: 'O.H',
		type: 'text',
		field: 'lOh',
		grid: 1,
	},
	{
		label: 'C.V',
		type: 'text',
		field: 'lCv',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 4,
	},
	/* {
		label: 'S.O',
		type: 'label',
		field: 'lSoLabel',
		grid: 1,
	},
	{
		label: '특이사항',
		type: 'multiSelect',
		options: [
			{
				label: '눈부심 민감',
				value: 'light',
			},
			{
				label: '녹내장',
				value: 'eyeDisease1',
			},
			{
				label: '백내장, 라식/라섹 수술',
				value: 'eyeDisease2',
			},
			{
				label: '당뇨성 망막병',
				value: 'eyeDisease3',
			},
			{
				label: '황반변성, 망막색소변셩, 선천성 광과민',
				value: 'eyeDisease4',
			},
			{
				label: '하이커브',
				value: 'highCurve',
			},
			{
				label: '특수컬러',
				value: 'specialColor',
			},
			{
				label: '유리',
				value: 'glass',
			},
			{
				label: '근시완화(어린이)',
				value: 'children',
			},
		],
		field: 'lSo',
		grid: 2,
	}, */
	{ type: 'blank', grid: 1 },
	{
		label: '상하',
		type: 'singleSelect',
		options: [
			{ label: 'UP', value: 'up' },
			{ label: 'DOWN', value: 'down' },
		],
		field: 'lUpDown',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'lPrism2',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 5,
	},
	{
		type: 'text',
		field: 'lensComment',
		label: '렌즈코멘트',
		grid: 12,
	},
	{
		type: 'ref',
		field: 'lensExaminationInfo',
		label: '렌즈검사정보',
		options: [
			{ label: '검안값', value: 'Measure' },
			{ label: '자도수', value: 'Before' },
		],
		grid: 'auto',
	},
];

export const lensMeasure: InputType[] = [
	{
		label: 'R',
		type: 'label',
		field: 'rightLabel',
		grid: 1,
	},
	{
		label: 'SPH',
		type: 'text',
		field: 'rSph',
		grid: 1,
	},
	{
		label: 'CYL',
		type: 'text',
		field: 'rCyl',
		grid: 1,
	},
	{
		label: 'AXI',
		type: 'text',
		field: 'rAxi',
		grid: 1,
	},
	{
		label: 'ADD',
		type: 'text',
		field: 'rAdd',
		grid: 1,
	},
	{
		label: 'NRA',
		type: 'text',
		field: 'nra',
		grid: 1,
	},
	{
		label: '내외',
		type: 'singleSelect',
		options: [
			{ label: 'IN', value: 'in' },
			{ label: 'OUT', value: 'out' },
		],
		field: 'rInOut',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'rPrism1',
		grid: 1,
	},
	{
		label: 'P.D',
		type: 'text',
		field: 'rPd',
		grid: 1,
	},
	{
		label: 'O.H',
		type: 'text',
		field: 'rOh',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 6,
	},
	{
		label: '상하',
		type: 'singleSelect',
		options: [
			{ label: 'UP', value: 'up' },
			{ label: 'DOWN', value: 'down' },
		],
		field: 'rUpDown',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'rPrism2',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 4,
	},
	{
		label: 'L',
		type: 'label',
		field: 'leftLabel',
		grid: 1,
	},
	{
		label: 'SPH',
		type: 'text',
		field: 'lSph',
		grid: 1,
	},
	{
		label: 'CYL',
		type: 'text',
		field: 'lCyl',
		grid: 1,
	},
	{
		label: 'AXI',
		type: 'text',
		field: 'lAxi',
		grid: 1,
	},
	{
		label: 'ADD',
		type: 'text',
		field: 'lAdd',
		grid: 1,
	},
	{
		label: 'PRA',
		type: 'text',
		field: 'pra',
		grid: 1,
	},
	{
		label: '내외',
		type: 'singleSelect',
		options: [
			{ label: 'IN', value: 'in' },
			{ label: 'OUT', value: 'out' },
		],
		field: 'lInOut',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'lPrism1',
		grid: 1,
	},
	{
		label: 'P.D',
		type: 'text',
		field: 'lPd',
		grid: 1,
	},
	{
		label: 'O.H',
		type: 'text',
		field: 'lOh',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 4,
	},
	{ type: 'blank', grid: 2 },
	{
		label: '상하',
		type: 'singleSelect',
		options: [
			{ label: 'UP', value: 'up' },
			{ label: 'DOWN', value: 'down' },
		],
		field: 'lUpDown',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'lPrism2',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 5,
	},
];

export const lensBefore: InputType[] = [
	{
		label: 'R',
		type: 'label',
		field: 'rightLabel',
		grid: 1,
	},
	{
		label: 'SPH',
		type: 'text',
		field: 'rSph',
		grid: 1,
	},
	{
		label: 'CYL',
		type: 'text',
		field: 'rCyl',
		grid: 1,
	},
	{
		label: 'AXI',
		type: 'text',
		field: 'rAxi',
		grid: 1,
	},
	{
		label: 'ADD',
		type: 'text',
		field: 'rAdd',
		grid: 1,
	},
	{
		label: '내외',
		type: 'singleSelect',
		options: [
			{ label: 'IN', value: 'in' },
			{ label: 'OUT', value: 'out' },
		],
		field: 'rInOut',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'rPrism1',
		grid: 1,
	},
	{
		label: 'P.D',
		type: 'text',
		field: 'rPd',
		grid: 1,
	},
	{
		label: 'O.H',
		type: 'text',
		field: 'rOh',
		grid: 1,
	},
	{
		label: 'C.V',
		type: 'text',
		field: 'rCv',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 4,
	},
	{
		type: 'blank',
		grid: 1,
	},
	{
		label: '상하',
		type: 'singleSelect',
		options: [
			{ label: 'UP', value: 'up' },
			{ label: 'DOWN', value: 'down' },
		],
		field: 'rUpDown',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'rPrism2',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 5,
	},
	{
		label: 'L',
		type: 'label',
		field: 'leftLabel',
		grid: 1,
	},
	{
		label: 'SPH',
		type: 'text',
		field: 'lSph',
		grid: 1,
	},
	{
		label: 'CYL',
		type: 'text',
		field: 'lCyl',
		grid: 1,
	},
	{
		label: 'AXI',
		type: 'text',
		field: 'lAxi',
		grid: 1,
	},
	{
		label: 'ADD',
		type: 'text',
		field: 'lAdd',
		grid: 1,
	},
	{
		label: '내외',
		type: 'singleSelect',
		options: [
			{ label: 'IN', value: 'in' },
			{ label: 'OUT', value: 'out' },
		],
		field: 'lInOut',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'lPrism1',
		grid: 1,
	},
	{
		label: 'P.D',
		type: 'text',
		field: 'lPd',
		grid: 1,
	},
	{
		label: 'O.H',
		type: 'text',
		field: 'lOh',
		grid: 1,
	},
	{
		label: 'C.V',
		type: 'text',
		field: 'lCv',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 4,
	},
	{ type: 'blank', grid: 1 },
	{
		label: '상하',
		type: 'singleSelect',
		options: [
			{ label: 'UP', value: 'up' },
			{ label: 'DOWN', value: 'down' },
		],
		field: 'lUpDown',
		grid: 1,
	},
	{
		label: 'PRISM',
		type: 'text',
		field: 'lPrism2',
		grid: 1,
	},
	{
		type: 'blank',
		grid: 5,
	},
];

export const lensAsContextArray = {
	reCorrect: [
		{ label: '흐림', value: 'blur' },
		{ label: '어지러움', value: 'dizzy' },
		{ label: '압박/통증', value: 'pain' },
		{ label: '양안불균형', value: 'imbalance' },
		{ label: '눈부심', value: 'dazzle' },
		{ label: '빛번짐', value: 'glare' },
		{ label: '얼비침', value: 'reflect' },
		{ label: '기타(코멘트)', value: 'etcC' },
	],
	reOrder: [
		{ label: '가공오류', value: 'dispenseErr' },
		{ label: '주문오류', value: 'orderErr' },
	],
	noReason: [
		{ label: '도수변경', value: 'powerChange' },
		{ label: '초점변경', value: 'ocChange' },
		{ label: '사양변경', value: 'specChange' },
		{ label: '색상변경', value: 'colorChange' },
		{ label: '기타(코멘트)', value: 'etcN' },
	],
	quality: [
		{ label: '도수불량', value: 'powerFaulty' },
		{ label: '코팅불량', value: 'coatingFaulty' },
		{ label: '착색불량', value: 'coloringFaulty' },
		{ label: '기타(코멘트)', value: 'etcQ' },
	],
};
