import React from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SubNav from '../../components/SubNav';
import koText from '../../util/localizationKR';
import CustomToolbar from '../../components/CustomToolbar';
import NoDataOverlay from '../../components/NoDataOverlay';
import { ReleaseCol } from '../../columns';

function FrameOrder() {
	return (
		<Box px={5}>
			<SubNav />
			<div style={{ height: '80vh', width: '100%' }} />
		</Box>
	);
}

export default FrameOrder;
