import { Auth } from 'aws-amplify';
import { NavigateFunction } from 'react-router';

const checkUser = async (navigate: NavigateFunction) => {
	try {
		return await Auth.currentAuthenticatedUser();
	} catch (err) {
		navigate('/');
		return null;
	}
};

export default checkUser;
