import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputType, OptionType } from '../../util/Interface';
import { CheckBoxInput, SingleSelectInput, TextInput } from './index';
import { lensBefore, lensMeasure } from '../../util/Array';
import MultiSelect from './MultiSelect';
import LongTextInput from './LongTextInput';

interface designReqOptionsType {
	[index: string]: { first: OptionType[]; second?: OptionType[] };
}

function ReqArrayInput({ options }: InputType) {
	const { control, setValue } = useFormContext();
	const designReqList = useWatch({ control, name: 'designReq' }) as string[] | undefined;
	const lensExaminationInfoList = useWatch({ control, name: 'lensExaminationInfo' }) as string[] | undefined;

	const labelCheck = (name: string) => {
		return options?.filter((obj) => obj.value === name)[0].label;
	};

	const remove = (name: string) => {
		if (designReqList) {
			const removedList = designReqList.filter((value) => value !== name);
			setValue('designReq', removedList);
		}
	};

	const designReqOptions: designReqOptionsType = {
		pt: {
			first: [
				{ label: 'auto(6)', value: '6' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
				{ label: '7', value: '7' },
				{ label: '8', value: '8' },
				{ label: '9', value: '9' },
			],
		},
		vd: {
			first: [
				{ label: 'auto(12)', value: '12' },
				{ label: '9', value: '9' },
				{ label: '10', value: '10' },
				{ label: '11', value: '11' },
				{ label: '13', value: '13' },
				{ label: '14', value: '14' },
			],
		},
		bridgeX: {
			first: [
				{ label: '연장', value: 'plus' },
				{ label: '단축', value: 'minus' },
			],
			second: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
			],
		},
		padY: {
			first: [
				{ label: '상향', value: 'plus' },
				{ label: '하향', value: 'minus' },
			],
			second: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
			],
		},
		padX: {
			first: [
				{ label: '넓힘', value: 'plus' },
				{ label: '좁힘', value: 'minus' },
			],
		},
		padZ: {
			first: [
				{ label: '연장', value: 'plus' },
				{ label: '단축', value: 'minus' },
			],
			second: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
			],
		},
		padA: {
			first: [
				{ label: '눕힘', value: 'plus' },
				{ label: '세움', value: 'minus' },
			],
		},
		endX: {
			first: [
				{ label: '연장', value: 'plus' },
				{ label: '단축', value: 'minus' },
			],
			second: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
				{ label: '6', value: '6' },
			],
		},
		endZ: {
			first: [
				{ label: '연장', value: 'plus' },
				{ label: '단축', value: 'minus' },
			],
			second: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
				{ label: '6', value: '6' },
			],
		},
		endP: {
			first: [
				{ label: 'Auto', value: 'auto' },
				{ label: '넓힘', value: 'plus' },
				{ label: '좁힘', value: 'minus' },
			],
		},
		endS: {
			first: [
				{ label: '균형체크', value: 'adjust' },
				{ label: '상향', value: 'plus' },
				{ label: '하향', value: 'minus' },
			],
		},
	};

	return (
		<Grid container>
			{designReqList?.map((name) => {
				const label = labelCheck(name);
				return (
					<Grid container key={`${name}`} spacing={1} mt={1} ml={0.5}>
						<Grid item xs={2}>
							<Typography>{label}</Typography>
						</Grid>
						{Object.entries(designReqOptions[`${name}`]).map((obj) => {
							if (obj[0] === 'first') {
								return (
									<SingleSelectInput
										grid={2}
										type="singleSelect"
										options={obj[1]}
										field={designReqOptions[`${name}`]?.second ? `${name}Direction` : `${name}Value`}
										size="small"
										label={designReqOptions[`${name}`]?.second ? '조정방향' : '조정값'}
									/>
								);
							}
							if (obj[0] === 'second') {
								return (
									<SingleSelectInput
										grid={2}
										type="singleSelect"
										options={obj[1]}
										field={`${name}Direction`}
										size="small"
										label="조정값"
									/>
								);
							}
							return null;
						})}
						{name !== 'pt' &&
						name !== 'vd' &&
						name !== 'bridgeX' &&
						name !== 'padY' &&
						name !== 'endX' &&
						name !== 'endZ' &&
						name !== 'endP' ? (
							<Grid item xs={2} ml={2}>
								<CheckBoxInput type="checkBox" grid={12} field={`${name}Diff`} label="좌우차이" />
							</Grid>
						) : (
							<Grid item xs={2} ml={2} />
						)}
						<Grid item>
							<Button onClick={() => remove(name)}>
								<DeleteIcon />
							</Button>
						</Grid>
					</Grid>
				);
				/* switch (name) {
					case 'pt':
					case 'vd':
					case 'padX':
					case 'padZ':
					case 'padA':
					case 'endZ':
					case 'endP':
					case 'endS':
						return (
							<Grid container key={`${name}`} spacing={1} mt={1} ml={0.5}>
								<Grid item xs={2}>
									<Typography>{label}</Typography>
								</Grid>
								<SingleSelectInput
									grid={2}
									type="singleSelect"
									options={specialOption}
									field={`${name}Direction`}
									size="small"
									label="조정방향"
								/>
								<TextInput type="text" grid={2} field={`${name}Value`} size="small" label="조정값" />
								{name !== 'pt' && name !== 'vd' ? (
									<Grid item xs={2} ml={2}>
										<CheckBoxInput type="checkBox" grid={12} field={`${name}Diff`} label="좌우차이" />
									</Grid>
								) : (
									<Grid item xs={2} ml={2} />
								)}
								<Grid item>
									<Button onClick={() => remove(name)}>
										<DeleteIcon />
									</Button>
								</Grid>
							</Grid>
						);
					case 'bridgeX':
					case 'padY':
					case 'endX':
						return (
							<Grid container key={name} spacing={1} mt={1} ml={0.5}>
								<Grid item xs={2}>
									<Typography>{label}</Typography>
								</Grid>
								<SingleSelectInput
									grid={2}
									type="singleSelect"
									options={normalOption}
									field={`${name}Direction`}
									size="small"
									label="조정방향"
								/>
								<TextInput type="text" grid={2} field={`${name}Value`} size="small" label="조정값" />
								<Grid item xs={2} ml={2}>
									<CheckBoxInput type="checkBox" grid={12} field={`${name}Diff`} label="좌우차이" />
								</Grid>
								<Grid item>
									<Button onClick={() => remove(name)}>
										<DeleteIcon />
									</Button>
								</Grid>
							</Grid>
						);
					case 'designCmt':
					case 'productCmt':
						return (
							<Grid container key={name} spacing={1} mt={1} ml={0.5}>
								<TextInput type="text" grid={8} field={name} size="small" label={label} />
								<Grid item ml={2}>
									<Button onClick={() => remove(name)}>
										<DeleteIcon />
									</Button>
								</Grid>
							</Grid>
						);
					default:
						return null;
				} */
			})}
			{lensExaminationInfoList?.map((name) => {
				switch (name) {
					case 'Measure':
						return (
							<Box key={name}>
								<Box py={3}>
									<Divider />
									<Typography pt={3} component="h3" fontSize={25}>
										검안값
									</Typography>
								</Box>
								<Grid container rowSpacing={2} columnSpacing={1}>
									{lensMeasure.map((input, index) => {
										const { type, field, label, options: optionList, grid } = input;
										switch (type) {
											case 'label':
												return (
													<Grid key={field} item xs={grid} display="flex" justifyContent="center" alignItems="center">
														<Typography>{label}</Typography>
													</Grid>
												);
											case 'text':
												return (
													<TextInput
														key={field}
														grid={grid}
														field={`${field}${name}`}
														label={label}
														type={type}
														size="small"
													/>
												);
											case 'singleSelect':
												return (
													<SingleSelectInput
														key={field}
														grid={grid}
														field={`${field}${name}`}
														options={optionList}
														label={label}
														type={type}
														size="small"
													/>
												);
											case 'multiSelect':
												return (
													<MultiSelect
														key={field}
														type={type}
														grid={grid}
														options={options}
														field={`${field}${name}`}
														label={label}
													/>
												);
											case 'blank':
												// eslint-disable-next-line react/no-array-index-key
												return <Grid key={`${type}${index}`} item xs={grid} />;
											case 'longText':
												return (
													<LongTextInput key={field} field={`${field}${name}`} type={type} grid={grid} label={label} />
												);
											default:
												return null;
										}
									})}
								</Grid>
							</Box>
						);
					case 'Before':
						return (
							<Box key={name}>
								<Box py={3}>
									<Divider />
									<Typography pt={3} component="h3" fontSize={25}>
										자도수
									</Typography>
								</Box>
								<Grid container rowSpacing={2} columnSpacing={1}>
									{lensBefore.map((input, index) => {
										const { type, field, label, options: optionList, grid } = input;
										switch (type) {
											case 'label':
												return (
													<Grid key={field} item xs={grid} display="flex" justifyContent="center" alignItems="center">
														<Typography>{label}</Typography>
													</Grid>
												);
											case 'text':
												return (
													<TextInput
														key={field}
														grid={grid}
														field={`${field}${name}`}
														label={label}
														type={type}
														size="small"
													/>
												);
											case 'singleSelect':
												return (
													<SingleSelectInput
														key={field}
														grid={grid}
														field={`${field}${name}`}
														options={optionList}
														label={label}
														type={type}
														size="small"
													/>
												);
											case 'multiSelect':
												return (
													<MultiSelect
														key={field}
														type={type}
														grid={grid}
														options={options}
														field={`${field}${name}`}
														label={label}
													/>
												);
											case 'blank':
												// eslint-disable-next-line react/no-array-index-key
												return <Grid key={`${type}${index}`} item xs={grid} />;
											case 'longText':
												return (
													<LongTextInput key={field} field={`${field}${name}`} type={type} grid={grid} label={label} />
												);
											default:
												return null;
										}
									})}
								</Grid>
							</Box>
						);
					default:
						return null;
				}
			})}
		</Grid>
	);
}

export default ReqArrayInput;
