/* eslint-disable */

export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment {
    onCreateAttachment {
      id
      tableLink
      tableName
      tableId
      tableField
      name
      extension
      awsKey
      etag
      link
      thumbnail
      preview
      fileSize
      sharedScope
      downloadCount
      googleDriveId
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment {
    onUpdateAttachment {
      id
      tableLink
      tableName
      tableId
      tableField
      name
      extension
      awsKey
      etag
      link
      thumbnail
      preview
      fileSize
      sharedScope
      downloadCount
      googleDriveId
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment {
    onDeleteAttachment {
      id
      tableLink
      tableName
      tableId
      tableField
      name
      extension
      awsKey
      etag
      link
      thumbnail
      preview
      fileSize
      sharedScope
      downloadCount
      googleDriveId
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateCare = /* GraphQL */ `
  subscription OnCreateCare {
    onCreateCare {
      id
      field
      careId
      careOption
      careValue
      careType
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateCare = /* GraphQL */ `
  subscription OnUpdateCare {
    onUpdateCare {
      id
      field
      careId
      careOption
      careValue
      careType
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteCare = /* GraphQL */ `
  subscription OnDeleteCare {
    onDeleteCare {
      id
      field
      careId
      careOption
      careValue
      careType
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      field
      comment
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      field
      comment
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      field
      comment
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateCustomField = /* GraphQL */ `
  subscription OnCreateCustomField {
    onCreateCustomField {
      id
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateCustomField = /* GraphQL */ `
  subscription OnUpdateCustomField {
    onUpdateCustomField {
      id
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteCustomField = /* GraphQL */ `
  subscription OnDeleteCustomField {
    onDeleteCustomField {
      id
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateCustomLayout = /* GraphQL */ `
  subscription OnCreateCustomLayout {
    onCreateCustomLayout {
      id
      page
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateCustomLayout = /* GraphQL */ `
  subscription OnUpdateCustomLayout {
    onUpdateCustomLayout {
      id
      page
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteCustomLayout = /* GraphQL */ `
  subscription OnDeleteCustomLayout {
    onDeleteCustomLayout {
      id
      page
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      name
      phone
      gender
      birthday
      email
      address
      orgName
      agreement
      signature
      channel
      referrer
      preferContact
      detail
      status
      note
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      name
      phone
      gender
      birthday
      email
      address
      orgName
      agreement
      signature
      channel
      referrer
      preferContact
      detail
      status
      note
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      name
      phone
      gender
      birthday
      email
      address
      orgName
      agreement
      signature
      channel
      referrer
      preferContact
      detail
      status
      note
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateProductInfo = /* GraphQL */ `
  subscription OnCreateProductInfo {
    onCreateProductInfo {
      id
      category
      line
      parts
      material
      label
      company
      brand
      detail
      status
      launchDate
      expireDate
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateProductInfo = /* GraphQL */ `
  subscription OnUpdateProductInfo {
    onUpdateProductInfo {
      id
      category
      line
      parts
      material
      label
      company
      brand
      detail
      status
      launchDate
      expireDate
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteProductInfo = /* GraphQL */ `
  subscription OnDeleteProductInfo {
    onDeleteProductInfo {
      id
      category
      line
      parts
      material
      label
      company
      brand
      detail
      status
      launchDate
      expireDate
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateReservation = /* GraphQL */ `
  subscription OnCreateReservation {
    onCreateReservation {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateReservationByStore = /* GraphQL */ `
  subscription OnCreateReservationByStore($storeId: ID!) {
    onCreateReservationByStore(storeId: $storeId) {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateReservationByDay = /* GraphQL */ `
  subscription OnCreateReservationByDay($storeId: ID!, $reserveYMD: String!) {
    onCreateReservationByDay(storeId: $storeId, reserveYMD: $reserveYMD) {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateReservation = /* GraphQL */ `
  subscription OnUpdateReservation {
    onUpdateReservation {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateReservationByStore = /* GraphQL */ `
  subscription OnUpdateReservationByStore($storeId: ID!) {
    onUpdateReservationByStore(storeId: $storeId) {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateReservationByDay = /* GraphQL */ `
  subscription OnUpdateReservationByDay($storeId: ID!, $reserveYMD: String!) {
    onUpdateReservationByDay(storeId: $storeId, reserveYMD: $reserveYMD) {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteReservation = /* GraphQL */ `
  subscription OnDeleteReservation {
    onDeleteReservation {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteReservationByStore = /* GraphQL */ `
  subscription OnDeleteReservationByStore($storeId: ID!) {
    onDeleteReservationByStore(storeId: $storeId) {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteReservationByDay = /* GraphQL */ `
  subscription OnDeleteReservationByDay($storeId: ID!, $reserveYMD: String!) {
    onDeleteReservationByDay(storeId: $storeId, reserveYMD: $reserveYMD) {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateStaff = /* GraphQL */ `
  subscription OnCreateStaff {
    onCreateStaff {
      id
      name
      phone
      gender
      birthday
      email
      profile
      address
      cognitoId
      lastLogin
      lastLoginIP
      duty
      detail
      status
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateStaff = /* GraphQL */ `
  subscription OnUpdateStaff {
    onUpdateStaff {
      id
      name
      phone
      gender
      birthday
      email
      profile
      address
      cognitoId
      lastLogin
      lastLoginIP
      duty
      detail
      status
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteStaff = /* GraphQL */ `
  subscription OnDeleteStaff {
    onDeleteStaff {
      id
      name
      phone
      gender
      birthday
      email
      profile
      address
      cognitoId
      lastLogin
      lastLoginIP
      duty
      detail
      status
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateStore = /* GraphQL */ `
  subscription OnCreateStore {
    onCreateStore {
      id
      name
      detail
      storeType
      status
      code
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateStore = /* GraphQL */ `
  subscription OnUpdateStore {
    onUpdateStore {
      id
      name
      detail
      storeType
      status
      code
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteStore = /* GraphQL */ `
  subscription OnDeleteStore {
    onDeleteStore {
      id
      name
      detail
      storeType
      status
      code
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const onCreateVersion = /* GraphQL */ `
  subscription OnCreateVersion {
    onCreateVersion {
      id
      version
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      added
      updated
      deleted
      status
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateVersion = /* GraphQL */ `
  subscription OnUpdateVersion {
    onUpdateVersion {
      id
      version
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      added
      updated
      deleted
      status
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteVersion = /* GraphQL */ `
  subscription OnDeleteVersion {
    onDeleteVersion {
      id
      version
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      added
      updated
      deleted
      status
      createdAt
      updatedAt
    }
  }
`;

