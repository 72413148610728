import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ko } from 'date-fns/esm/locale';
import { Grid, TextField } from '@mui/material';
import { CustomFieldType } from '../../util/Interface';

function CustomFieldDateInput({ field, label }: CustomFieldType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	return (
		<Grid key={field} item xs={2}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value } }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
						<DesktopDatePicker
							value={value}
							label={label}
							inputFormat="yyyy-MM-dd"
							mask="____-__-__"
							onChange={(date) => onChange(date)}
							renderInput={(params) => (
								<TextField
									type="datetime-local"
									size="small"
									name={field}
									{...params}
									error={Boolean(errors[`${field}`] || false)}
									helperText={errors[`${field}`]?.message}
									fullWidth
								/>
							)}
						/>
					</LocalizationProvider>
				)}
			/>
		</Grid>
	);
}

export default CustomFieldDateInput;
