import { OptionProps } from './Interface';

export const lensBrandOption: OptionProps[] = [
	{ label: '토카이', value: 'tokai', color: '#D2DFFC' },
	{ label: '자이스', value: 'zeiss', color: '#FBEBBC' },
	{ label: '니콘', value: 'nikon', color: '#D8F6C9' },
	{ label: '바리락스', value: 'varilux', color: '#F9DBF4' },
	{ label: '케미', value: 'chemi', color: '#D2DFFC' },
	{ label: '다가스', value: 'dagas', color: '#D6EFFB' },
];

export const powerList: { label: string; value: string }[] = [
	{ label: '1.50', value: '1.50' },
	{ label: '1.56', value: '1.56' },
	{ label: '1.60', value: '1.60' },
	{ label: '1.67', value: '1.67' },
	{ label: '1.70', value: '1.70' },
	{ label: '1.74', value: '1.74' },
	{ label: '1.76', value: '1.76' },
	{ label: '1.80', value: '1.80' },
	{ label: '1.90', value: '1.90' },
	{ label: '기타', value: 'ect' },
];

export const tintedOption: OptionProps[] = [
	{ label: '투명', value: 'clear', color: '#D2DFFC' },
	{ label: '착색', value: 'tinted', color: '#FBEBBC' },
];

export const lensTypeOption: OptionProps[] = [
	{ label: '싱글', value: 'single', color: '#D2DFFC' },
	{ label: '다초점', value: 'progressive', color: '#FBEBBC' },
	{ label: '기능성', value: 'assist', color: '#D8F6C9' },
	{ label: '이중초점', value: 'bifocal', color: '#F9DBF4' },
	{ label: '근시완화', value: 'myopia', color: '#D2DFFC' },
	{ label: '실내다초점', value: 'office', color: '#D6EFFB' },
];
// 누진대
export const progressiveList: { label: string; value: string }[] = [
	{ label: '9mm', value: '9mm' },
	{ label: '10mm', value: '10mm' },
	{ label: '11mm', value: '11mm' },
	{ label: '12mm', value: '12mm' },
	{ label: '13mm', value: '13mm' },
	{ label: '14mm', value: '14mm' },
	{ label: '15mm', value: '15mm' },
	{ label: '16mm', value: '16mm' },
	{ label: '17mm', value: '17mm' },
	{ label: '18mm', value: '18mm' },
	{ label: '23mm', value: '23mm' },
	{ label: '22mm', value: '22mm' },
	{ label: '10.5mm', value: '10.5mm' },
	{ label: '+0.5mm', value: '+0.5mm' },
	{ label: '12.5mm', value: '12.5mm' },
	{ label: '12.5mm', value: '12.5mm' },
	{ label: 'L type', value: 'L type' },
	{ label: '11.5mm', value: '11.5mm' },
	{ label: '13.5mm', value: '13.5mm' },
	{ label: '14.5mm', value: '14.5mm' },
	{ label: '15.5mm', value: '15.5mm' },
];

/*
export const baseColor: OptionProps[] = [
	{ value: 'slgy75', label: 'SLGY75' },
	{ value: 'slgy85', label: 'SLGY85' },
	{ value: 'copr75', label: 'COPR75' },
	{ value: 'copr85', label: 'COPR85' },
	{ value: 'fore75', label: 'FORE75' },
	{ value: 'fore85', label: 'FORE85' },
	{ value: 'gbr1', label: 'GBR1' },
	{ value: 'gbr2', label: 'GBR2' },
	{ value: 'ccpTs', label: 'CCP TS' },
	{ value: 'ccpRs', label: 'CCP RS' },
	{ value: 'ccpAc', label: 'CCP AC' },
	{ value: 'ccpNa', label: 'CCP NA' },
	{ value: 'ccpNl', label: 'CCP NL' },
	{ value: 'ccpFl', label: 'CCP FL' },
	{ value: 'ccpLy', label: 'CCP LY' },
	{ value: 'ccpYg', label: 'CCP YG' },
	{ value: 'ccpUg', label: 'CCP UG' },
	{ value: 'feelGray', label: 'FEEL GRAY' },
	{ value: 'feelBrown', label: 'FEEL BROWN' },
	{ value: 'feelPurple', label: 'FEEL PURPLE' },
	{ value: 'feelPink', label: 'FEEL PINK' },
	{ value: 'gray', label: 'GRAY' },
	{ value: 'grey', label: 'GREY' },
	{ value: 'brown', label: 'BROWN' },
	{ value: 'green', label: 'GREEN' },
	{ value: 'sgy70', label: 'SGY70' },
	{ value: 'sgy80', label: 'SGY80' },
	{ value: 'sgy90', label: 'SGY90' },
	{ value: 'mbk70', label: 'MBK70' },
	{ value: 'mbk80', label: 'MBK80' },
	{ value: 'mbk90', label: 'MBK90' },
	{ value: 'btbr70', label: 'BTBR70' },
	{ value: 'btbr80', label: 'BTBR80' },
	{ value: 'btbr90', label: 'BTBR90' },
	{ value: 'sgn70', label: 'SGN70' },
	{ value: 'sgn80', label: 'SGN80' },
	{ value: 'sgn90', label: 'SGN90' },
	{ value: 'btbr75', label: 'BTBR75' },
	{ value: 'btbr85', label: 'BTBR85' },
	{ value: 'smog75', label: 'SMOG75' },
	{ value: 'smog85', label: 'SMOG85' },
	{ value: 'smog95', label: 'SMOG95' },
	{ value: 'brown75', label: 'BROWN75' },
	{ value: 'brown85', label: 'BROWN85' },
	{ value: 'brown95', label: 'BROWN95' },
	{ value: 'rayban75', label: 'RAYBAN75' },
	{ value: 'rayban85', label: 'RAYBAN85' },
	{ value: 'rayban95', label: 'RAYBAN95' },
	{ value: 'pioneer', label: 'PIONEER' },
	{ value: 'blue', label: 'BLUE' },
	{ value: 'extraGrey', label: 'EXTRAGREY' },
	{ value: 'grey75', label: 'GREY75' },
	{ value: 'grey85', label: 'GREY85' },
	{ value: 'grey95', label: 'GREY95' },
	{ value: 'black75', label: 'BLACK75' },
	{ value: 'black85', label: 'BLACK85' },
	{ value: 'black95', label: 'BLACK95' },
	{ value: 'pioneer75', label: 'PIONEER75' },
	{ value: 'pioneer85', label: 'PIONEER85' },
	{ value: 'pioneer95', label: 'PIONEER95' },
	{ value: 'f90', label: 'F90' },
	{ value: 'f80', label: 'F80' },
	{ value: 'f60', label: 'F60' },
	{ value: 'f580', label: 'F580' },
	{ value: 'f560', label: 'F560' },
	{ value: 'f540', label: 'F540' },
	{ value: 'skyLetFun70', label: 'SKYLET FUN70' },
	{ value: 'skyLetRoad80', label: 'SKYLET ROAD80' },
	{ value: 'skyLetSport90', label: 'SKYLET SPORT90' },
	{ value: 'proGolf40', label: 'PROGOLF40' },
	{ value: 'proGolf75/25', label: 'PROGOLF75/25' },
	{ value: 'sapphire', label: 'SAPPHIRE' },
	{ value: 'amber', label: 'AMBER' },
	{ value: 'emerald', label: 'EMERALD' },
	{ value: 'purple', label: 'PURPLE' },
];

export const mirColor: OptionProps[] = [
	{ label: '골드', value: 'gold' },
	{ label: '실버', value: 'silver' },
	{ label: '블루', value: 'blue' },
	{ label: '라이트골드', value: 'lightGold' },
	{ label: '라이트실버', value: 'lightSilver' },
	{ label: '브론즈', value: 'bronze' },
	{ label: '레드', value: 'red' },
	{ label: '그린', value: 'green' },
	{ label: '다이아몬드', value: 'diamond' },
	{ label: '제이드', value: 'jade' },
	{ label: '엠버', value: 'amber' },
	{ label: '사파이어', value: 'sapphire' },
	{ label: '루비', value: 'tinted' },
];
*/
