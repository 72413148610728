import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { InputType } from '../../util/Interface';
import { makePhoneNumber } from '../../util/ReplaceValue';

function PhoneInput({ field, label, grid }: InputType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid } }) => (
					<TextField
						value={makePhoneNumber(value as string) || ''}
						size="small"
						label={label}
						onChange={(e) => onChange(makePhoneNumber(e.target.value))}
						InputLabelProps={{ style: { fontSize: 14 } }}
						inputRef={ref}
						onBlur={onBlur}
						fullWidth
						error={invalid && Boolean(errors[`${field}`] || false)}
						helperText={invalid && errors[`${field}`]?.message ? errors[`${field}`]?.message : ' '}
					/>
				)}
			/>
		</Grid>
	);
}

export default PhoneInput;
