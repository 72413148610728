import React from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SubNav from '../components/SubNav';
import { productCol } from '../columns/Columns';
import koText from '../util/localizationKR';

function Polishing() {
	const rows = [
		{
			id: 1,
			orderNumber: 'YD2109031',
			store: 'YD',
			status: 'Dispatch',
			productName: 'W Welington',
			size: 5,
			color: 'Brown L',
			nosePad: '원형',
			request: '문제가 있습니다.',
			hopeDate: new Date(),
			productDate: 5,
			totalDate: 6,
		},
	];
	return (
		<Box px={5}>
			<SubNav />
			<div style={{ height: 1500, width: '100%' }}>
				<DataGridPro
					rows={rows}
					columns={productCol}
					checkboxSelection
					pageSize={5}
					rowsPerPageOptions={[5]}
					disableSelectionOnClick
					style={{ borderLeft: 'none', borderRight: 'none' }}
					localeText={koText}
				/>
			</div>
		</Box>
	);
}

export default Polishing;
