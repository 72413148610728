import { API } from 'aws-amplify';
import ErrorCheck from '../util/ErrorCheck';
import * as queries from '../graphql/queries';
import { CustomFieldType, GetCustomFieldQuery } from '../util/Interface';
import { convertCustomFields } from '../util/ReplaceValue';

const getCustomFields = async (): Promise<CustomFieldType[]> => {
	try {
		const productInfoData = (await API.graphql({
			query: queries.getCustomField,
			variables: {
				id: 'ProductInfo',
			},
		})) as GetCustomFieldQuery;

		return convertCustomFields(productInfoData.data.getCustomField.detail);
	} catch (err) {
		ErrorCheck(err);
		return [];
	}
};

export default getCustomFields;
