import { API } from 'aws-amplify';
import ErrorCheck from '../util/ErrorCheck';
import * as mutations from '../graphql/mutations';
import { ProductInfoResponse } from '../util/Interface';

const createProductInfo = async (productInfo: ProductInfoResponse) => {
	try {
		await API.graphql({
			query: mutations.createProductInfo,
			variables: {
				input: productInfo,
			},
		});
	} catch (err) {
		ErrorCheck(err);
	}
};
export default createProductInfo;
