import { atom } from 'recoil';

export default atom({
	key: 'customerDetail',
	default: {
		id: '',
		name: '',
		phone: '',
		certification: false,
		gender: '',
		birthday: null,
		email: '',
		address: '',
		detailAddress: '',
		postcode: '',
		extraAddress: '',
		infoAgree: false,
		asAgree: false,
		offerAgree: false,
		rcgPath: '',
		preferContact: '',
	},
});
