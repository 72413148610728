import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputType } from '../../util/Interface';

function TextBtnInput({ field, label, grid, random }: InputType) {
	const {
		control,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();

	const handleCheckNumber = (input: string) => {
		if (field === 'certificationNumber') {
			if (input === '') {
				alert('인증번호를 넣어주세요.');
			} else if (random === input) {
				alert('인증이 완료되었습니다.');
				setValue('certification', true);
			} else {
				alert('인증이 실패했습니다.');
			}
		}
	};
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value } }) => (
					<TextField
						value={value}
						variant="standard"
						size="small"
						label={label}
						onChange={onChange}
						fullWidth
						error={Boolean(errors.certification || false)}
						helperText={errors.certification?.message}
						InputProps={{
							disabled: getValues ? getValues('certification') : false,
							endAdornment: (
								<Button
									variant="outlined"
									size="small"
									style={{
										marginBottom: '5px',
										minWidth: 50,
									}}
									onClick={() => handleCheckNumber((value as string) || '')}
									disabled={getValues ? getValues('certification') : false}
								>
									인증
								</Button>
							),
						}}
					/>
				)}
			/>
		</Grid>
	);
}

export default TextBtnInput;
