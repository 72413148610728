import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ko } from 'date-fns/esm/locale';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { InputType } from '../../util/Interface';

function DateTimeInput({ field, label, grid }: InputType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value } }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
						<DesktopDateTimePicker
							value={value || ''}
							label={label}
							onChange={onChange}
							inputFormat="yy.MM.dd HH:mm"
							minutesStep={10}
							renderInput={(props) => (
								<TextField
									{...props}
									size="small"
									fullWidth
									error={Boolean(errors[`${field}`] || false)}
									helperText={errors[`${field}`]?.message}
									sx={{ button: { padding: '4px' } }}
								/>
							)}
							// OpenPickerButtonProps={{ sx: { '& *:focus': { outline: 'none' } } }}
						/>
					</LocalizationProvider>
				)}
			/>
		</Grid>
	);
}

export default DateTimeInput;
