/* eslint-disable */

export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      tableLink
      tableName
      tableId
      tableField
      name
      extension
      awsKey
      etag
      link
      thumbnail
      preview
      fileSize
      sharedScope
      downloadCount
      googleDriveId
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listAttachment = /* GraphQL */ `
  query ListAttachment($sortDirection: ModelSortDirection, $filter: ModelAttachmentFilterInput, $limit: Int, $nextToken: String) {
    listAttachment(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        tableLink
        tableName
        tableId
        tableField
        name
        extension
        awsKey
        etag
        link
        thumbnail
        preview
        fileSize
        sharedScope
        downloadCount
        googleDriveId
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listAttachmentByTableLink = /* GraphQL */ `
  query ListAttachmentByTableLink($tableLink: String!, $sortDirection: ModelSortDirection, $filter: ModelAttachmentFilterInput, $limit: Int, $nextToken: String) {
    listAttachmentByTableLink(tableLink: $tableLink, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        tableLink
        tableName
        tableId
        tableField
        name
        extension
        awsKey
        etag
        link
        thumbnail
        preview
        fileSize
        sharedScope
        downloadCount
        googleDriveId
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listAttachmentByTableLinkAndField = /* GraphQL */ `
  query ListAttachmentByTableLinkAndField($tableLink: String!, $tableField: String!, $sortDirection: ModelSortDirection, $filter: ModelAttachmentFilterInput, $limit: Int, $nextToken: String) {
    listAttachmentByTableLinkAndField(tableLink: $tableLink, tableField: $tableField, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        tableLink
        tableName
        tableId
        tableField
        name
        extension
        awsKey
        etag
        link
        thumbnail
        preview
        fileSize
        sharedScope
        downloadCount
        googleDriveId
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getCare = /* GraphQL */ `
  query GetCare($id: ID!) {
    getCare(id: $id) {
      id
      field
      careId
      careOption
      careValue
      careType
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listCare = /* GraphQL */ `
  query ListCare($sortDirection: ModelSortDirection, $filter: ModelCareFilterInput, $limit: Int, $nextToken: String) {
    listCare(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        field
        careId
        careOption
        careValue
        careType
        score
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listCareByIdOption = /* GraphQL */ `
  query ListCareByIdOption($careId: String!, $sortDirection: ModelSortDirection, $filter: ModelCareFilterInput, $limit: Int, $nextToken: String) {
    listCareByIdOption(careId: $careId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        field
        careId
        careOption
        careValue
        careType
        score
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listCareByStaff = /* GraphQL */ `
  query ListCareByStaff($staffId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCareFilterInput, $limit: Int, $nextToken: String) {
    listCareByStaff(staffId: $staffId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        field
        careId
        careOption
        careValue
        careType
        score
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listCareByCustomer = /* GraphQL */ `
  query ListCareByCustomer($customerId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCareFilterInput, $limit: Int, $nextToken: String) {
    listCareByCustomer(customerId: $customerId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        field
        careId
        careOption
        careValue
        careType
        score
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      field
      comment
      score
      note
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listComment = /* GraphQL */ `
  query ListComment($sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
    listComment(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        field
        comment
        score
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listCommentByStaff = /* GraphQL */ `
  query ListCommentByStaff($staffId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
    listCommentByStaff(staffId: $staffId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        field
        comment
        score
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listCommentByCustomer = /* GraphQL */ `
  query ListCommentByCustomer($customerId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
    listCommentByCustomer(customerId: $customerId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        field
        comment
        score
        note
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getCustomField = /* GraphQL */ `
  query GetCustomField($id: ID!) {
    getCustomField(id: $id) {
      id
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listCustomField = /* GraphQL */ `
  query ListCustomField($sortDirection: ModelSortDirection, $filter: ModelCustomFieldFilterInput, $limit: Int, $nextToken: String) {
    listCustomField(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        detail
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getCustomLayout = /* GraphQL */ `
  query GetCustomLayout($id: ID!) {
    getCustomLayout(id: $id) {
      id
      page
      detail
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listCustomLayout = /* GraphQL */ `
  query ListCustomLayout($sortDirection: ModelSortDirection, $filter: ModelCustomLayoutFilterInput, $limit: Int, $nextToken: String) {
    listCustomLayout(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        page
        detail
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      phone
      gender
      birthday
      email
      address
      orgName
      agreement
      signature
      channel
      referrer
      preferContact
      detail
      status
      note
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listCustomer = /* GraphQL */ `
  query ListCustomer($sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
    listCustomer(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listCustomerSearch = /* GraphQL */ `
  query ListCustomerSearch($status: String!, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
    listCustomerSearch(status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const searchCustomer = /* GraphQL */ `
  query SearchCustomer($search: String!, $table: String = "Customer", $fields: String = "name,phone,email", $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
    searchCustomer(search: $search, table: $table, fields: $fields, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const searchReservation = /* GraphQL */ `
  query SearchReservation($search: String!, $table: String = "Reservation", $fields: String = "name,phone", $sortDirection: ModelSortDirection, $filter: ModelReservationFilterInput, $limit: Int, $nextToken: String) {
    searchReservation(search: $search, table: $table, fields: $fields, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        reserveType
        reserveDate
        reserveMedia
        reserveYM
        reserveYMD
        name
        phone
        person
        cost
        cancel
        referrer
        channel
        detail
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getProductInfo = /* GraphQL */ `
  query GetProductInfo($id: ID!) {
    getProductInfo(id: $id) {
      id
      category
      line
      parts
      material
      label
      company
      brand
      detail
      status
      launchDate
      expireDate
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listProductInfo = /* GraphQL */ `
  query ListProductInfo($sortDirection: ModelSortDirection, $filter: ModelProductInfoFilterInput, $limit: Int, $nextToken: String) {
    listProductInfo(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        category
        line
        parts
        material
        label
        company
        brand
        detail
        status
        launchDate
        expireDate
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listProductInfoSearch = /* GraphQL */ `
  query ListProductInfoSearch($status: String!, $sortDirection: ModelSortDirection, $filter: ModelProductInfoFilterInput, $limit: Int, $nextToken: String) {
    listProductInfoSearch(status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        category
        line
        parts
        material
        label
        company
        brand
        detail
        status
        launchDate
        expireDate
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      store {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      customer {
        id
        name
        phone
        gender
        birthday
        email
        address
        orgName
        agreement
        signature
        channel
        referrer
        preferContact
        detail
        status
        note
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        editBy
        createdAt
        updatedAt
      }
      reserveType
      reserveDate
      reserveMedia
      reserveYM
      reserveYMD
      name
      phone
      person
      cost
      cancel
      referrer
      channel
      detail
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listReservationByStore = /* GraphQL */ `
  query ListReservationByStore($storeId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReservationFilterInput, $limit: Int, $nextToken: String) {
    listReservationByStore(storeId: $storeId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        reserveType
        reserveDate
        reserveMedia
        reserveYM
        reserveYMD
        name
        phone
        person
        cost
        cancel
        referrer
        channel
        detail
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listReservationByStoreWithReserveDateRange = /* GraphQL */ `
  query ListReservationByStoreWithReserveDateRange($storeId: ID!, $reserveDateFrom: AWSDateTime!, $reserveDateTo: AWSDateTime!, $sortDirection: ModelSortDirection, $filter: ModelReservationFilterInput, $limit: Int, $nextToken: String) {
    listReservationByStoreWithReserveDateRange(storeId: $storeId, reserveDateFrom: $reserveDateFrom, reserveDateTo: $reserveDateTo, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        reserveType
        reserveDate
        reserveMedia
        reserveYM
        reserveYMD
        name
        phone
        person
        cost
        cancel
        referrer
        channel
        detail
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listReservationByMonthWithStore = /* GraphQL */ `
  query ListReservationByMonthWithStore($reserveYM: String!, $storeId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReservationFilterInput, $limit: Int, $nextToken: String) {
    listReservationByMonthWithStore(reserveYM: $reserveYM, storeId: $storeId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        reserveType
        reserveDate
        reserveMedia
        reserveYM
        reserveYMD
        name
        phone
        person
        cost
        cancel
        referrer
        channel
        detail
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listReservationByDay = /* GraphQL */ `
  query ListReservationByDay($reserveYMD: String!, $sortDirection: ModelSortDirection, $filter: ModelReservationFilterInput, $limit: Int, $nextToken: String) {
    listReservationByDay(reserveYMD: $reserveYMD, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        reserveType
        reserveDate
        reserveMedia
        reserveYM
        reserveYMD
        name
        phone
        person
        cost
        cancel
        referrer
        channel
        detail
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listReservationByDayWithStore = /* GraphQL */ `
  query ListReservationByDayWithStore($reserveYMD: String!, $storeId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReservationFilterInput, $limit: Int, $nextToken: String) {
    listReservationByDayWithStore(reserveYMD: $reserveYMD, storeId: $storeId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        reserveType
        reserveDate
        reserveMedia
        reserveYM
        reserveYMD
        name
        phone
        person
        cost
        cancel
        referrer
        channel
        detail
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listReservationByCustomer = /* GraphQL */ `
  query ListReservationByCustomer($customerId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReservationFilterInput, $limit: Int, $nextToken: String) {
    listReservationByCustomer(customerId: $customerId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        store {
          id
          name
          detail
          storeType
          status
          code
          note
          editBy
          createdAt
          updatedAt
        }
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        customer {
          id
          name
          phone
          gender
          birthday
          email
          address
          orgName
          agreement
          signature
          channel
          referrer
          preferContact
          detail
          status
          note
          store {
            id
            name
            detail
            storeType
            status
            code
            note
            editBy
            createdAt
            updatedAt
          }
          staff {
            id
            name
            phone
            gender
            birthday
            email
            profile
            address
            cognitoId
            lastLogin
            lastLoginIP
            duty
            detail
            status
            note
            editBy
            createdAt
            updatedAt
          }
          editBy
          createdAt
          updatedAt
        }
        reserveType
        reserveDate
        reserveMedia
        reserveYM
        reserveYMD
        name
        phone
        person
        cost
        cancel
        referrer
        channel
        detail
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      name
      phone
      gender
      birthday
      email
      profile
      address
      cognitoId
      lastLogin
      lastLoginIP
      duty
      detail
      status
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listStaff = /* GraphQL */ `
  query ListStaff($sortDirection: ModelSortDirection, $filter: ModelStaffFilterInput, $limit: Int, $nextToken: String) {
    listStaff(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listStaffSearch = /* GraphQL */ `
  query ListStaffSearch($status: String!, $sortDirection: ModelSortDirection, $filter: ModelStaffFilterInput, $limit: Int, $nextToken: String) {
    listStaffSearch(status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      name
      detail
      storeType
      status
      code
      note
      editBy
      createdAt
      updatedAt
    }
  }
`;

export const listStore = /* GraphQL */ `
  query ListStore($sortDirection: ModelSortDirection, $filter: ModelStoreFilterInput, $limit: Int, $nextToken: String) {
    listStore(sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listStoreByStoreTypeWithStatus = /* GraphQL */ `
  query ListStoreByStoreTypeWithStatus($storeType: String!, $status: String!, $sortDirection: ModelSortDirection, $filter: ModelStoreFilterInput, $limit: Int, $nextToken: String) {
    listStoreByStoreTypeWithStatus(storeType: $storeType, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        detail
        storeType
        status
        code
        note
        editBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const getVersion = /* GraphQL */ `
  query GetVersion($id: ID!) {
    getVersion(id: $id) {
      id
      version
      staff {
        id
        name
        phone
        gender
        birthday
        email
        profile
        address
        cognitoId
        lastLogin
        lastLoginIP
        duty
        detail
        status
        note
        editBy
        createdAt
        updatedAt
      }
      added
      updated
      deleted
      status
      createdAt
      updatedAt
    }
  }
`;

export const listVersionByStaff = /* GraphQL */ `
  query ListVersionByStaff($staffId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVersionFilterInput, $limit: Int, $nextToken: String) {
    listVersionByStaff(staffId: $staffId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        version
        staff {
          id
          name
          phone
          gender
          birthday
          email
          profile
          address
          cognitoId
          lastLogin
          lastLoginIP
          duty
          detail
          status
          note
          editBy
          createdAt
          updatedAt
        }
        added
        updated
        deleted
        status
        createdAt
        updatedAt
      }
    }
  }
`;

