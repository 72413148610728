import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import error from '../../images/error.png';

function NoPage() {
	const navigate = useNavigate();

	const title = '찾으시는 페이지가 없습니다.';
	const helpText =
		'잘못된 접근이거나 요청하신 페이지를 찾을 수 없습니다. \n입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.';

	return (
		<Grid
			container
			spacing={2}
			direction="column"
			alignItems="center"
			justifyContent="center"
			style={{ minHeight: '80vh' }}
		>
			<Grid item xs={6}>
				<Typography color="#0960E3" fontSize={32} fontWeight="bold">
					{title}
				</Typography>
			</Grid>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={4}>
					{helpText.split('\n').map((sentence) => (
						<Typography key={`${sentence}`} color="#606060" fontSize={18} align="center">
							{sentence}
						</Typography>
					))}
				</Grid>
			</Grid>
			<Grid item xs={4}>
				<img src={error} alt="error" style={{ width: '243px', height: 'auto', objectFit: 'cover' }} />
			</Grid>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={3} lg={2}>
					<Button fullWidth variant="contained" onClick={() => navigate('/')}>
						메인메뉴로 돌아가기
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default NoPage;
