import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { RecoilRoot } from 'recoil';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { unstable_createMuiStrictModeTheme as createTheme } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { koKR } from '@mui/material/locale';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Scheduler from './page/reserve/Scheduler';
import Reserve from './page/reserve/Reserve';
import ReserveTotal from './page/reserve/ReserveTotal';
import CustomerCheck from './page/customer/CustomerCheck';
import CustomerList from './page/customer/CustomerList';
import CustomerInfo from './page/customer/CustomerInfo';
import Main from './page/Main';
import Product from './page/Product';
import Modeling from './page/Modeling';
import Polishing from './page/Polishing';
import Dyeing from './page/Dyeing';
import Dispatch from './page/Dispatch';
import CustomerRegister from './page/customer/CustomerRegister';
import Design from './page/Design';
import Test from './page/Test';
import FrameOrder from './page/order/FrameOrder';
import Loading from './components/Loading';
import LensOrder from './page/order/LensOrder';
import Progress from './page/order/Progress';
import Release from './page/order/Release';
import Contact from './page/order/Contact';
import Package from './page/order/Package';
import ModelingOrder from './page/order/ModelingOrder';
import AdminReserve from './page/admin/AdminReserve';
import StaffManager from './page/admin/StaffManager';
import StoreManager from './page/admin/StoreManager';
import RequireStore from './components/RequireStore';
import RequireAdmin from './components/RequireAdmin';
import AdminPage from './page/admin/AdminPage';
import NoPage from './page/error/404';
import StorePage from './page/StorePage';
import ReserveDayView from './page/admin/ReserveDayView';
import ProductInfo from './page/admin/ProductInfo';
import FieldManage from './page/admin/FieldManage';
import AdminReserveAll from './page/admin/AdminReserveAll';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Survey from './page/Survey';

const theme = createTheme(
	{
		components: {
			MuiPaper: {
				defaultProps: {
					sx: {
						'& *:focus': { outline: 'none' },
					},
				},
			},
		},
		typography: {
			fontFamily: ['Pretendard'].join(','),
			button: {
				textTransform: 'none',
			},
		},
	},
	koKR
);

/* const dark = createTheme(
  {
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    palette: {
      mode: 'dark',
    },
  },
  koKR,
); */

ReactDOM.render(
	<Suspense fallback={<Loading />}>
		<RecoilRoot>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					<App>
						<Routes>
							<Route path="/" element={<Main />} />
							{/* 안경사(store) */}
							<Route
								path="store"
								element={
									<RequireStore>
										<StorePage />
									</RequireStore>
								}
							>
								<Route path="reserve" element={<Reserve />} />
								<Route path="reserve-schedule" element={<Scheduler />} />
								<Route path="reserve-total" element={<ReserveTotal />} />
								<Route path="customer" element={<CustomerCheck />} />
								<Route path="customer-total" element={<CustomerList />} />
								<Route path="customer-info" element={<CustomerInfo />} />
								<Route path="customer-register" element={<CustomerRegister />} />
								<Route path="frame-order" element={<FrameOrder />} />
								<Route path="lens-order" element={<LensOrder />} />
								<Route path="progress" element={<Progress />} />
								<Route path="modeling" element={<ModelingOrder />} />
								<Route path="release" element={<Release />} />
								<Route path="contact" element={<Contact />} />
								<Route path="package" element={<Package />} />
								<Route path="survey" element={<Survey />} />
							</Route>
							{/* 더메이크(factory) */}
							<Route
								path="/product/all"
								element={
									<RequireStore>
										<Product />
									</RequireStore>
								}
							/>
							<Route
								path="/product/modeling"
								element={
									<RequireStore>
										<Modeling />
									</RequireStore>
								}
							/>
							<Route
								path="/product/polishing"
								element={
									<RequireStore>
										<Polishing />
									</RequireStore>
								}
							/>
							<Route
								path="/product/dyeing"
								element={
									<RequireStore>
										<Dyeing />
									</RequireStore>
								}
							/>
							<Route
								path="/product/dispatch"
								element={
									<RequireStore>
										<Dispatch />
									</RequireStore>
								}
							/>
							{/* 안경디자인(design) */}
							<Route
								path="/design"
								element={
									<RequireStore>
										<Design />
									</RequireStore>
								}
							/>
							<Route path="total-schedule" element={<ReserveDayView />} />
							{/* 테스트 */}
							<Route path="/test" element={<Test />} />
							{/* admin */}
							<Route
								path="admin"
								element={
									<RequireAdmin>
										<AdminPage />
									</RequireAdmin>
								}
							>
								<Route path="reserve" element={<AdminReserve />} />
								<Route path="reserve/all" element={<AdminReserveAll />} />
								<Route path="staff" element={<StaffManager />} />
								<Route path="store" element={<StoreManager />} />
								<Route path="product" element={<ProductInfo />} />
								<Route path="field" element={<FieldManage />} />
							</Route>
							<Route path="/*" element={<NoPage />} />
						</Routes>
					</App>
				</BrowserRouter>
			</ThemeProvider>
		</RecoilRoot>
	</Suspense>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();
