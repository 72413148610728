import { selector } from 'recoil';
import { EventSourceInput } from '@fullcalendar/react';
import moment from 'moment';
import { API } from 'aws-amplify';
import fetchUser from './fetchUser';
import { scheduleDate } from '../atom';
import * as queries from '../graphql/queries';
import {
	ReservationResponse,
	ReserveType,
	fetchMonthScheduleQuery,
	fetchRangeScheduleQuery,
	fetchScheduleType,
} from '../util/Interface';
import { changeValueToLabel, convertReserve } from '../util/ReplaceValue';

export default selector<fetchScheduleType | { reserve: []; schedule: [] }>({
	key: 'fetchSchedule',
	get: async ({ get }) => {
		const dateArg = get(scheduleDate);
		const storeId = get(fetchUser)?.storeId;
		const staffs = get(fetchUser)?.coworker;

		if (dateArg === null || storeId === undefined) return { reserve: [], schedule: [] };

		const { start, end, type } = dateArg;
		let response: ReservationResponse[] = [];
		let token = '';
		if (type === 'dayGridMonth') {
			const reserveYM = moment(start)
				.add(moment.duration(moment(end).diff(moment(start))).asDays() / 2, 'd')
				.format('yyyy-MM');

			while (token != null) {
				const data = (await API.graphql({
					query: queries.listReservationByMonthWithStore,
					variables: {
						reserveYM,
						storeId,
						limit: 100,
						nextToken: token === '' ? null : token,
					},
				})) as fetchMonthScheduleQuery;
				token = data.data.listReservationByMonthWithStore.nextToken;
				const value = data.data.listReservationByMonthWithStore.items.filter(
					(reserve) => reserve.reserveType !== 'attention'
				);
				response.push(...value);
				if (token === null) {
					break;
				}
			}
		} else {
			while (token != null) {
				const data = (await API.graphql({
					query: queries.listReservationByStoreWithReserveDateRange,
					variables: {
						storeId,
						reserveDateFrom: moment(start).toISOString(),
						reserveDateTo: moment(end).toISOString(),
						limit: 100,
						nextToken: token === '' ? null : token,
					},
				})) as fetchRangeScheduleQuery;
				response = data.data.listReservationByStoreWithReserveDateRange.items.filter(
					(reserve) => reserve.reserveType !== 'attention'
				);
				token = data.data.listReservationByStoreWithReserveDateRange.nextToken;
				if (token === null) {
					break;
				}
			}
		}
		const reserve: ReserveType[] = convertReserve(response).filter((obj) => obj.reserveType !== 'attention');
		const schedule: EventSourceInput = response.map((obj) => {
			const { name, reserveType, reserveDate, person, staff, id, cancel } = obj;
			const staffLabel = staffs?.find((optician) => optician.value === staff.id)?.label || '';
			return {
				title: `${name}(${staffLabel})`,
				id,
				start: reserveDate,
				end: moment(reserveDate).add(30, 'minute').format(),
				backgroundColor: !cancel ? changeValueToLabel(reserveType, 'reserveType')?.color : '#CCCCCC',
				time: reserveDate,
				assistant: staff.name,
				reserveType,
				amount: person,
			};
		});
		return { reserve, schedule };
	},
});
