import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MenuItem, TextField } from '@mui/material';
import { selectedStore, storeListState } from '../atom';

function ReserveStore() {
	const [value, setValue] = useRecoilState(selectedStore);
	const storeList = useRecoilValue(storeListState);
	if (storeList !== null) {
		return (
			<TextField
				select
				size="small"
				fullWidth
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value);
				}}
			>
				{storeList
					.filter((store) => store.storeType === 'store')
					.sort((a, b) => {
						if (a.id > b.id) return 1;
						if (a.id < b.id) return -1;
						return 0;
					})
					?.map((store) => (
						<MenuItem key={store.id} value={store.id}>
							{store.name}
						</MenuItem>
					))}
			</TextField>
		);
	}
	return null;
}

export default ReserveStore;
