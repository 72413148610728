import React from 'react';
import { TextField, Box } from '@mui/material';
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ko } from 'date-fns/esm/locale';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useRecoilState } from 'recoil';
import { reserveDateRange } from '../atom';

function ReserveDateRange() {
	const [value, setValue] = useRecoilState(reserveDateRange);
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
			<DateRangePicker
				startText="시작"
				endText="끝"
				value={value}
				onChange={(newValue) => {
					setValue(newValue);
				}}
				inputFormat="yyyy.MM.dd"
				mask="____.__.__"
				renderInput={(startProps, endProps) => (
					<>
						<TextField
							{...startProps}
							size="small"
							InputProps={{
								endAdornment: <DateRangeIcon />,
							}}
						/>
						<Box sx={{ mx: 2 }}> ~ </Box>
						<TextField
							{...endProps}
							size="small"
							InputProps={{
								endAdornment: <DateRangeIcon />,
							}}
						/>
					</>
				)}
			/>
		</LocalizationProvider>
	);
}

export default ReserveDateRange;
