import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormHelperText, Grid } from '@mui/material';
import { CustomFieldType } from '../../util/Interface';

function CustomFieldCheckBox({ field, label }: CustomFieldType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	return (
		<Grid item xs={2}>
			<Grid display="flex" alignItems="center">
				<Controller
					name={field}
					control={control}
					render={({ field: { onChange, value } }) => (
						<FormControlLabel control={<Checkbox size="small" checked={value} onChange={onChange} />} label={label} />
					)}
				/>
				{Boolean(errors[`${field}`]) && <FormHelperText error>{errors[`${field}`]?.message}</FormHelperText>}
			</Grid>
		</Grid>
	);
}

export default CustomFieldCheckBox;
