import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ko } from 'date-fns/esm/locale';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { InputType } from '../../util/Interface';

function DateInput({ field, label, grid, variant, size }: InputType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value } }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
						<DesktopDatePicker
							value={value}
							label={label}
							disableFuture={field === 'birthday'}
							inputFormat="yyyy-MM-dd"
							mask="____-__-__"
							onChange={(date) => onChange(date)}
							renderInput={(params) => (
								<TextField
									type="datetime-local"
									name={field}
									{...params}
									// variant={fieldType}
									error={Boolean(errors[`${field}`] || false)}
									helperText={errors[`${field}`]?.message}
									fullWidth
									size={size}
									variant={variant}
								/>
							)}
						/>
					</LocalizationProvider>
				)}
			/>
		</Grid>
	);
}

export default DateInput;
