import moment from 'moment';
import { EventInput } from '@fullcalendar/common';
import { sortBy } from 'lodash';
import { Location } from 'react-router-dom';
import { selectList } from './Array';

import {
	ADDRange,
	ConditionType,
	CustomerInput,
	CustomerResponse,
	CustomerType,
	CustomFieldType,
	LensOptionArrayType,
	objectDef,
	OptionProps,
	OptionType,
	PrismRange,
	ProductInfoResponse,
	ProductInfoType,
	ProgressiveRange,
	ReservationResponse,
	ReserveType,
	ServerStore,
	SPHCYLRange,
	StaffResponse,
	StaffType,
	StoreResponse,
	StoreType,
	ToServerReserve,
	ToServerStaff,
	UserData,
} from './Interface';

import uploadS3 from '../query/uploadS3';
import ErrorCheck from './ErrorCheck';
import { lensBrandOption, lensTypeOption, tintedOption } from './Definition';

// 전화번호 변경
export const makePhoneNumber = (value: string | null): string | null => {
	if (value) {
		if (value.indexOf('+') > -1 || value.indexOf('(') > -1) {
			return String(value).replace(/[^0-9()+-]/g, '');
		}
		return String(value)
			.replace(/[^0-9]/g, '')
			.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
			.replace('--', '-');
	}
	return null;
};

export const generateRandomCode = (n: number): string => {
	let str = '';
	for (let i = 0; i < n; i += 1) {
		str += Math.floor(Math.random() * 10);
	}
	return str;
};

export const changeValueToLabel = (value: string, field: string) =>
	selectList[`${field}`].find((obj) => obj.value === value);

export const convertCustomType = (value?: string[]) => {
	const convert = (val: string): 'F' | 'L' | 'C' | '' => {
		switch (val) {
			case 'frame':
				return 'F';
			case 'lens':
				return 'L';
			case 'clipOn':
				return 'C';
			default:
				return '';
		}
	};
	const order: { [key in 'F' | 'L' | 'C']: number } = { F: 1, L: 2, C: 3 };

	return (
		value
			?.map(convert)
			.filter((item): item is 'F' | 'L' | 'C' => item !== '')
			.sort((a, b) => order[a] - order[b])
			.join('') ?? ''
	);
};

export const convertAsType = (value: string | string[]): string => {
	const convert = (val: string): string => {
		switch (val) {
			case 'damage':
				return '파손';
			case 'fitting':
				return '피팅';
			case 'reOptometry':
				return '재검안';
			case 'optometry':
				return '정기검안';
			case 'refund':
				return '교환/환불';
			default:
				return '';
		}
	};

	if (typeof value === 'string') {
		return convert(value);
	}
	if (Array.isArray(value)) {
		return value.map(convert).join(', ');
	}
	return '';
};

export const convertReserve = (response: ReservationResponse[]): ReserveType[] => {
	return response.map((reserve) => {
		let { note } = reserve;
		const detail = JSON.parse(reserve.detail);
		if (JSON.parse(reserve.detail)?.age) {
			let ageText = '';
			if (JSON.parse(reserve.detail)?.age === '0') {
				ageText = '10세 이하';
			} else {
				ageText = `${JSON.parse(reserve.detail)?.age}대`;
			}
			if (reserve.note?.includes(ageText)) {
				note = reserve.note;
			} else {
				note = (reserve.note || '').concat(ageText);
			}
		}
		return {
			...reserve,
			store: reserve.store.code,
			storeId: reserve.store.id,
			staff: reserve.staff.id,
			customerId: reserve.customer.id,
			preferContact: reserve.customer.preferContact || '',
			lensStaff: (JSON.parse(reserve.detail)?.lensStaff as string) || '',
			frameStaff: (JSON.parse(reserve.detail)?.frameStaff as string) || '',
			reserveDate: moment(reserve.reserveDate).set('second', 0).toDate(),
			createdAt: moment(reserve.createdAt).toDate(),
			note,
			// website 관련
			customerNote: {
				request: detail?.request,
				customType: detail?.customType,
				asType: detail?.asType,
				smsg: detail?.smsg,
			},
			memberId: JSON.parse(reserve.detail)?.memberId,
			isSpecial: JSON.parse(reserve.detail)?.isSpecial,
			breezm: JSON.parse(reserve.detail)?.breezm,
			airtableId: JSON.parse(reserve.detail)?.airtableId,
			age: JSON.parse(reserve.detail)?.age,
			utm: JSON.parse(reserve.detail)?.utm,
		};
	});
};

export const convertCustomer = (res: CustomerResponse[]): CustomerType[] =>
	res.map((customer) => {
		const address = (JSON.parse(customer.address)?.address as string) || '';
		const detailAddress = (JSON.parse(customer.address)?.detailAddress as string) || '';
		const postcode = (JSON.parse(customer.address)?.postcode as string) || '';
		const extraAddress = (JSON.parse(customer.address)?.extraAddress as string) || '';
		const infoAgree = (JSON.parse(customer.agreement)?.infoAgree as boolean) || false;
		const adAgree = (JSON.parse(customer.agreement)?.adAgree as boolean) || false;
		const offerAgree = (JSON.parse(customer.agreement)?.offerAgree as boolean) || false;
		return {
			...customer,
			birthday: moment(customer.birthday).toDate(),
			signature: null,
			certification: true,
			rcgPath: customer.channel,
			address,
			detailAddress,
			postcode,
			extraAddress,
			infoAgree,
			adAgree,
			offerAgree,
		};
	});

export const convertProductInfo = (res: ProductInfoResponse[]): ProductInfoType[] => {
	return res.map((product): ProductInfoType => {
		const {
			id,
			label,
			company,
			category,
			launchDate,
			line,
			detail,
			expireDate,
			status,
			note,
			material,
			parts,
			brand,
			createdAt,
			updatedAt,
		} = product;

		const rectangleRange: SPHCYLRange[] = [];
		const triangleRange: SPHCYLRange[] = [];
		const additionRange: ADDRange[] = [];
		const prismRange: PrismRange[] = [];

		const range = JSON.parse(detail)?.range as Array<SPHCYLRange | ADDRange | PrismRange>;
		range?.forEach((object) => {
			if ('sphMin' && 'sphLine' in object) {
				triangleRange.push(object);
			} else if ('sphMin' in object) {
				rectangleRange.push(object);
			} else if ('addMin' in object) {
				additionRange.push(object);
			} else if ('prismMin' in object) {
				prismRange.push(object);
			}
		});

		const lensOptionArray = JSON.parse(detail)?.lensOptionArray as LensOptionArrayType[];

		const changedLensOptionArray = lensOptionArray?.map((object) => {
			const { oid, price, added, pre, rx, baseColor, mirColor } = object;
			const rectangle: SPHCYLRange[] = [];
			const triangle: SPHCYLRange[] = [];
			const addition: ADDRange[] = [];
			const prism: PrismRange[] = [];
			const progressive: ProgressiveRange[] = [];
			object.range.forEach((obj) => {
				if ('sphMin' && 'sphLine' in obj) {
					triangle.push(obj);
				} else if ('sphMin' in obj) {
					rectangle.push(obj);
				} else if ('addMin' in obj) {
					addition.push(obj);
				} else if ('prismMin' in obj) {
					prism.push(obj);
				} else if ('progressiveMin' in obj) {
					progressive.push(obj);
				}
			});
			return {
				oid,
				price,
				added,
				pre,
				rx,
				rectangleRange: rectangle,
				triangleRange: triangle,
				additionRange: addition,
				prismRange: prism,
				progressiveRange: progressive,
				mirColor,
				baseColor,
			};
		});

		const lensOptions = changedLensOptionArray?.map((option) => option.oid);

		return {
			id,
			label,
			airtableLabel: JSON.parse(detail)?.airtableLabel,
			company,
			category,
			launchDate,
			line,
			frontColor: JSON.parse(detail)?.frontColor,
			nosepad: JSON.parse(detail)?.nosepad,
			bridge: JSON.parse(detail)?.bridge,
			tip: JSON.parse(detail)?.tip,
			size: JSON.parse(detail)?.size,
			printer: JSON.parse(detail)?.printer,
			unit: JSON.parse(detail)?.unit,
			type: JSON.parse(detail)?.type,
			side: JSON.parse(detail)?.side,
			temple: JSON.parse(detail)?.temple,
			frontParts: JSON.parse(detail)?.frontParts,
			templeLength: JSON.parse(detail)?.templeLength,
			templeParts: JSON.parse(detail)?.templeParts,
			templePartsColor: JSON.parse(detail)?.templePartsColor,
			templeColor: JSON.parse(detail)?.templeColor,
			bridgeColor: JSON.parse(detail)?.bridgeColor,
			tipColor: JSON.parse(detail)?.tipColor,
			labelColor: JSON.parse(detail)?.labelColor,
			partsColor: JSON.parse(detail)?.partsColor,
			optionName: JSON.parse(detail)?.optionName,
			functions: JSON.parse(detail)?.functions,
			mandatory: JSON.parse(detail)?.mandatory,
			auto: JSON.parse(detail)?.auto,
			refractive: JSON.parse(detail)?.refractive,
			colorCode: JSON.parse(detail)?.colorCode,
			connectParts: JSON.parse(detail)?.connectParts,
			dfType: JSON.parse(detail)?.dfType,
			lensOptions,
			lensOptionArray: changedLensOptionArray as LensOptionArrayType[],
			rectangleRange,
			triangleRange,
			additionRange,
			prismRange,
			progressiveRange: JSON.parse(detail)?.progressiveRange,
			cid: JSON.parse(detail)?.cid,
			lensType: JSON.parse(detail)?.lensType,
			tintedCheck: JSON.parse(detail)?.tintedCheck,
			dfKey: JSON.parse(detail)?.dfKey,
			price: JSON.parse(detail)?.price,
			added: JSON.parse(detail)?.added,
			expireDate,
			status,
			note,
			material,
			parts,
			brand,
			createdAt,
			updatedAt,
		};
	});
};

export const toServerReserve = (
	values: ReserveType,
	storeId: string,
	staffId: string,
	customerId: string
): ToServerReserve => {
	const {
		reserveDate,
		reserveType,
		reserveMedia,
		name,
		frameStaff,
		lensStaff,
		note,
		person,
		phone,
		cancel,
		memberId,
		isSpecial,
		breezm,
		airtableId,
		age,
		utm,
	} = values;

	const detail = JSON.stringify({ frameStaff, lensStaff, memberId, isSpecial, breezm, airtableId, age, utm });
	return {
		storeId: values.storeId || storeId,
		staffId: values.staff || staffId,
		customerId: values.customerId || customerId,
		reserveDate: moment(reserveDate).set('second', 0).toISOString(),
		reserveType,
		reserveMedia,
		name,
		note,
		phone,
		person,
		detail,
		reserveYM: moment(values.reserveDate).format('yyyy-MM'),
		reserveYMD: moment(values.reserveDate).format('yyyy-MM-DD'),
		cancel: cancel === undefined ? false : cancel,
		editBy: staffId,
	};
};

export const toServerUpdateReserve = (
	values: ReserveType,
	customer: CustomerResponse | null,
	userId: string
): ToServerReserve => {
	const {
		id,
		reserveDate,
		reserveType,
		reserveMedia,
		frameStaff,
		lensStaff,
		note,
		person,
		cancel,
		storeId,
		memberId,
		isSpecial,
		breezm,
		customerNote,
		airtableId,
		age,
		utm,
	} = values;
	const detail = JSON.stringify({
		frameStaff,
		lensStaff,
		memberId,
		isSpecial,
		breezm,
		airtableId,
		age,
		utm,
		...customerNote,
	});
	return {
		id,
		storeId: storeId || '',
		staffId: userId,
		customerId: customer?.id || '',
		reserveDate: moment(reserveDate).set('second', 0).toISOString(),
		reserveType,
		reserveMedia,
		name: customer?.name || '',
		note,
		phone: customer?.phone || '',
		person,
		detail,
		reserveYM: moment(values.reserveDate).format('yyyy-MM'),
		reserveYMD: moment(values.reserveDate).format('yyyy-MM-DD'),
		cancel: cancel === undefined ? false : cancel,
		editBy: userId,
		// website 관련
	};
};

export const toServerHandleCancelReserve = (
	values: ReserveType,
	storeId: string,
	staffId: string,
	customerId: string
): ToServerReserve => {
	const {
		id,
		reserveDate,
		reserveType,
		reserveMedia,
		name,
		frameStaff,
		lensStaff,
		note,
		person,
		phone,
		cancel,
		memberId,
		isSpecial,
		breezm,
		airtableId,
		age,
		utm,
	} = values;
	const detail = JSON.stringify({ frameStaff, lensStaff, memberId, isSpecial, breezm, airtableId, age, utm });

	return {
		id,
		storeId,
		staffId,
		customerId,
		reserveDate: moment(reserveDate).set('second', 0).toISOString(),
		reserveType,
		reserveMedia,
		name,
		note,
		phone,
		person,
		detail,
		reserveYM: moment(values.reserveDate).format('yyyy-MM'),
		reserveYMD: moment(values.reserveDate).format('yyyy-MM-DD'),
		editBy: staffId,
		cancel: !cancel,
	};
};

export const toServerCustomer = (data: CustomerType, userInfo: UserData | null): CustomerInput => {
	const {
		id,
		name,
		phone,
		email,
		gender,
		address,
		extraAddress,
		detailAddress,
		postcode,
		infoAgree,
		offerAgree,
		adAgree,
		signature,
		preferContact,
		rcgPath,
	} = data;

	const storeId = userInfo?.storeId || '';
	const staffId = userInfo?.id || '';
	const birthday = data.birthday ? data.birthday.toString() : undefined;
	const agreement = JSON.stringify({ infoAgree, offerAgree, adAgree });
	const addressObj = JSON.stringify({
		address,
		extraAddress,
		detailAddress,
		postcode,
	});

	let s3Key: string | undefined;
	if (id && signature) {
		uploadS3(signature, `customer/${id}/signature.png`)
			.then((value) => {
				s3Key = value?.key;
			})
			.catch((e) => ErrorCheck(e));
	}
	return {
		id,
		name,
		phone,
		email,
		gender,
		birthday,
		channel: rcgPath,
		status: 'valid',
		agreement,
		preferContact,
		storeId,
		staffId,
		signature: s3Key,
		address: addressObj,
		editBy: staffId,
	};
};

export const makeStaffObj = (
	staffName: string,
	staffs: { value: string; label: string; color: string }[] | undefined
) => staffs?.find((staff) => staff.value === staffName);

export const convertStaff = (staffResponse: StaffResponse[]): StaffType[] => {
	return staffResponse
		.map((staffRes): StaffType => {
			const { id, name, profile, status, phone, cognitoId, detail, duty, createdAt } = staffRes;
			return {
				id,
				name,
				profile,
				status,
				phone,
				cognitoId,
				color: JSON.parse(detail).color || '',
				label: JSON.parse(detail).label || '',
				labelKr: JSON.parse(detail).labelKr || '',
				role: JSON.parse(duty).role[0]?.role || '',
				storeId: JSON.parse(duty).role[0]?.storeId || '',
				dataGridState: JSON.parse(detail).dataGridState,
				createdAt,
			};
		})
		.filter((staff) => staff.status === 'valid');
};

export const convertStore = (storeResponse: StoreResponse[]): StoreType[] => {
	return storeResponse.map((storeRes): StoreType => {
		const { id, name, code, detail, storeType, status, createdAt } = storeRes;
		return {
			id,
			name,
			code,
			status,
			storeType,
			tel: JSON.parse(detail).tel || '',
			mobile: JSON.parse(detail).mobile || '',
			openTime: JSON.parse(detail).openTime || '',
			closeTime: JSON.parse(detail).closeTime || '',
			durationTime: JSON.parse(detail).durationTime || '',
			createdAt,
		};
	});
};

export const toServerStaff = (staffInputs: StaffType, userId: string): ToServerStaff => {
	const { id, name, storeId, color, labelKr, phone, dataGridState } = staffInputs;
	const label = id.charAt(0).toUpperCase() + id.slice(1);
	const role = 'staff';
	const duty = JSON.stringify({ role: [{ storeId, role }] });
	const detail = JSON.stringify({ label, labelKr, color, dataGridState });
	const status = 'valid';
	return { id, name, duty, detail, cognitoId: id, status, phone, editBy: userId };
};

export const toServerStore = (store: StoreType, userId: string): ServerStore => {
	const { id, name, storeType, status, code, tel, mobile, openTime, closeTime } = store;
	return {
		id,
		name,
		storeType,
		status: status || 'valid',
		detail: JSON.stringify({ tel, mobile, openTime, closeTime }),
		code,
		editBy: userId,
	};
};

export const reserveTimeSequenceSort = (reserveList: ReserveType[]): ReserveType[] => {
	return reserveList.sort((a, b) => {
		if (a.reserveDate.toISOString().substring(0, 19) > b.reserveDate.toISOString().substring(0, 19)) {
			return 1;
		}
		if (a.reserveDate.toISOString().substring(0, 19) < b.reserveDate.toISOString().substring(0, 19)) {
			return -1;
		}
		if (a.reserveDate.toISOString().substring(0, 19) === b.reserveDate.toISOString().substring(0, 19)) {
			if (moment(a.createdAt).toISOString().substring(0, 19) > moment(b.createdAt).toISOString().substring(0, 19)) {
				return 1;
			}
			if (moment(a.createdAt).toISOString().substring(0, 19) < moment(b.createdAt).toISOString().substring(0, 19)) {
				return -1;
			}
			return 0;
		}
		return 0;
	});
};

export const convertReserveToSchedule = (reserveRes: ReserveType[], coworker: objectDef[]): EventInput[] => {
	return reserveRes.map((obj) => {
		const { name, reserveType, reserveDate, person, staff, id, cancel, customerNote } = obj;
		const staffLabel = coworker.find((optician) => optician.value === staff)?.label || '';
		let notice = '';
		let asType = '';
		if (customerNote?.request || customerNote?.smsg) {
			notice = '✅';
		}
		if (reserveType === 'as' && customerNote?.asType) {
			asType = `\n${convertAsType(customerNote?.asType)}`;
		}

		return {
			title: `${notice} ${name}(${staffLabel})${asType}`,
			id,
			start: reserveDate,
			end: moment(reserveDate).add(30, 'minute').format(),
			backgroundColor: !cancel ? changeValueToLabel(reserveType, 'reserveType')?.color : '#CCCCCC',
			time: reserveDate,
			assistant: staffLabel,
			reserveType,
			amount: person,
		};
	});
};

export const convertCustomFields = (detail: string): CustomFieldType[] => {
	return JSON.parse(detail).fields as CustomFieldType[];
};

const makeRange = (
	rectangleArray: SPHCYLRange[],
	triangleArray: SPHCYLRange[],
	additionArray: ADDRange[],
	prismArray: PrismRange[]
): Array<SPHCYLRange | ADDRange | PrismRange> => {
	return [...rectangleArray, ...triangleArray, ...additionArray, ...prismArray];
};

const lensOptionArrayMakeRange = (lensOptionArray: LensOptionArrayType[] | undefined) => {
	return lensOptionArray?.map((object) => {
		const {
			oid,
			price,
			added,
			pre,
			rx,
			prismRange,
			rectangleRange,
			triangleRange,
			additionRange,
			progressiveRange,
			mirColor,
			baseColor,
		} = object;
		const range: Array<SPHCYLRange | ADDRange | PrismRange> = [];
		if (rectangleRange.length > 0) {
			range.push(...rectangleRange);
		}
		if (triangleRange.length > 0) {
			range.push(...triangleRange);
		}
		if (additionRange.length > 0) {
			range.push(...additionRange);
		}
		if (prismRange.length > 0) {
			range.push(...prismRange);
		}
		return {
			oid,
			price,
			added,
			rx,
			pre,
			range,
			progressiveRange,
			mirColor,
			baseColor,
		};
	});
};

export const toServerProductInfo = (product: ProductInfoType, userId: string): ProductInfoResponse => {
	const {
		id,
		category,
		line,
		company,
		brand,
		launchDate,
		expireDate,
		note,
		material,
		parts,
		label,
		status,
		printer,
		frontColor,
		nosepad,
		bridge,
		tip,
		lensOptionArray,
		size,
		temple,
		templeLength,
		templePartsColor,
		templeParts,
		partsColor,
		frontParts,
		templeColor,
		bridgeColor,
		tipColor,
		optionName,
		functions,
		auto,
		rectangleRange,
		triangleRange,
		additionRange,
		progressiveRange,
		prismRange,
		cid,
		mandatory,
		refractive,
		lensType,
		tintedCheck,
		dfKey,
		price,
		added,
		colorCode,
		airtableLabel,
		dfType,
		connectParts,
		labelColor,
	} = product;

	let leftRight: string[] = [];
	if (parts === 'temple') {
		leftRight = ['right', 'left'];
	}

	const range: Array<SPHCYLRange | ADDRange | PrismRange> = makeRange(
		rectangleRange || [],
		triangleRange || [],
		additionRange || [],
		prismRange || []
	);
	const detail = JSON.stringify({
		printer,
		frontColor,
		nosepad,
		bridge,
		tip,
		size,
		temple,
		templeLength,
		templeParts,
		templePartsColor,
		side: leftRight.length < 1 ? undefined : leftRight,
		frontParts,
		partsColor,
		templeColor,
		bridgeColor,
		tipColor,
		range,
		optionName,
		functions,
		progressiveRange,
		cid,
		mandatory,
		auto,
		refractive,
		lensType,
		tintedCheck,
		lensOptionArray: lensOptionArrayMakeRange(lensOptionArray),
		dfType,
		dfKey,
		price,
		added,
		colorCode,
		airtableLabel,
		connectParts,
		labelColor,
	});

	return {
		id,
		category,
		line,
		company,
		brand,
		launchDate,
		expireDate,
		note,
		material,
		parts,
		detail,
		label,
		status,
		editBy: userId,
	};
};

export const checkCondition = (conditions: ConditionType[], watch: any): boolean => {
	const possible = conditions.map((condition): boolean => {
		const { value, field } = condition;
		if (typeof value === 'string') {
			return watch(field) === value;
		}
		if (Array.isArray(value)) {
			return value.includes(watch(field));
		}
		return false;
	});
	return possible.includes(true);
};

export const forDynamicField = (conditions: ConditionType[], watch: any): boolean => {
	const possible = conditions.map((condition): boolean => {
		const { value, field } = condition;
		if (watch(field) !== '') {
			if (watch(field) !== undefined) {
				return watch(field).includes(value);
			}
		}
		return value === '';
	});
	return possible.includes(true);
};

export const makeLensIdOption = (productList: ProductInfoType[]): OptionProps[] => {
	const lensList = productList
		.filter((product) => product.category === 'lens')
		.sort((a, b) => {
			if (a.refractive && b.refractive) {
				if (a.refractive > b.refractive) return 1;
				if (a.refractive < b.refractive) return -1;
				return 0;
			}
			return 0;
		})
		.sort((a, b) => {
			if (a.tintedCheck && b.tintedCheck) {
				if (a?.tintedCheck > b?.tintedCheck) return 1;
				if (a?.tintedCheck < b?.tintedCheck) return -1;
				return 0;
			}
			return 0;
		})
		.sort((a, b) => {
			if (a.label && b.label) {
				if (a.label > b.label) return 1;
				if (a.label < b.label) return -1;
				return 0;
			}
			return 0;
		})
		.sort((a, b) => {
			if (a.lensType && b.lensType) {
				if (a.lensType > b.lensType) return 1;
				if (a.lensType < b.lensType) return -1;
				return 0;
			}
			return 0;
		});
	const brandList: string[] = ['chemi', 'dagas', 'nikon', 'varilux', 'zeiss', 'tokai'];
	const typeList: string[] = ['single', 'assist', 'progressive', 'office', 'bifocal', 'myopia'];
	const labelColorList = [
		{
			brand: 'chemi',
			type: [
				{ name: 'single', color: '#F9DDE5' },
				{ name: 'assist', color: '#ECA1DE' },
				{ name: 'progressive', color: '#F1A2B7' },
				{ name: 'office', color: '#F1A2B7' },
				{ name: 'bifocal', color: '#F1A2B7' },
				{ name: 'myopia', color: '#ECA1DE' },
			],
		},
		{
			brand: 'dagas',
			type: [
				{ name: 'single', color: '#F9DDE5' },
				{ name: 'assist', color: '#ECA1DE' },
				{ name: 'progressive', color: '#F1A2B7' },
				{ name: 'office', color: '#F1A2B7' },
				{ name: 'bifocal', color: '#F1A2B7' },
				{ name: 'myopia', color: '#ECA1DE' },
			],
		},
		{
			brand: 'nikon',
			type: [
				{ name: 'single', color: '#F9E3D7' },
				{ name: 'assist', color: '#FBEBBC' },
				{ name: 'progressive', color: '#F8D87E' },
				{ name: 'office', color: '#F8D87E' },
				{ name: 'bifocal', color: '#F8D87E' },
				{ name: 'myopia', color: '#FBEBBC' },
			],
		},
		{
			brand: 'varilux',
			type: [
				{ name: 'single', color: '#CCF4E9' },
				{ name: 'assist', color: '#D8F6C9' },
				{ name: 'progressive', color: '#A4DE91' },
				{ name: 'office', color: '#A4DE91' },
				{ name: 'bifocal', color: '#A4DE91' },
				{ name: 'myopia', color: '#D8F6C9' },
			],
		},
		{
			brand: 'zeiss',
			type: [
				{ name: 'single', color: '#D6EFFB' },
				{ name: 'assist', color: '#D2DFFC' },
				{ name: 'progressive', color: '#A5C6FA' },
				{ name: 'office', color: '#A5C6FA' },
				{ name: 'bifocal', color: '#A5C6FA' },
				{ name: 'myopia', color: '#D2DFFC' },
			],
		},
		{
			brand: 'tokai',
			type: [
				{ name: 'single', color: '#F9DBF4' },
				{ name: 'assist', color: '#EBE2FC' },
				{ name: 'progressive', color: '#C8B1FA' },
				{ name: 'office', color: '#C8B1FA' },
				{ name: 'bifocal', color: '#C8B1FA' },
				{ name: 'myopia', color: '#EBE2FC' },
			],
		},
	];
	const sortedTypeList = sortBy(lensList, (item) => {
		if (item.lensType) {
			return typeList.indexOf(item?.lensType);
		}
		return item;
	});
	const sortedLensList = sortBy(sortedTypeList, (item) => {
		return brandList.indexOf(item.brand);
	});
	return sortedLensList.map((lens): OptionProps => {
		const { id, brand, lensType, tintedCheck, refractive, label } = lens;
		const color = labelColorList
			.find((list) => list.brand === brand)
			?.type.find((type) => type.name === lensType)?.color;
		const brandKR = lensBrandOption.find((obj) => obj.value === brand)?.label || '';
		const lensTypeKR = lensTypeOption.find((obj) => obj.value === lensType)?.label || '';
		const tintedCheckKR = tintedOption.find((obj) => obj.value === tintedCheck)?.label || '';
		return {
			label: `${brandKR} ${lensTypeKR} ${label || ''} ${refractive || ''} ${tintedCheckKR}`,
			value: id,
			color,
		};
	});
};

export const checkNFC = (value: string): string => {
	if (value.length !== value.normalize('NFC').length) {
		return value.normalize('NFC');
	}
	return value;
};

export const makeValidFrame = (productList: ProductInfoType[]): OptionProps[] => {
	const lineList = ['basic', 'regular', 'edge', 'editor', 'zest', 'mode'];
	const frameList = sortBy(
		productList
			.filter((product) => product.category === 'frame')
			.filter((product) => product.status === 'valid')
			.sort((a, b) => {
				if (a.label && b.label) {
					if (a.label > b.label) return 1;
					if (a.label < b.label) return -1;
					return 0;
				}
				return 0;
			}),
		(item) => {
			return lineList.indexOf(item.line);
		}
	);
	const colorCheck: any = {
		basic: '#A5C6FA',
		regular: '#A4DE91',
		edge: '#F8D87E',
		editor: '#F3AD88',
		zest: '#F1A2B7',
		mode: '#C8B1FA',
	};
	return frameList.map((frame): OptionProps => {
		return {
			value: frame.id,
			label: `${frame.label}(${frame.airtableLabel})`,
			color: colorCheck[`${frame.line}`] as string,
		};
	});
};

export const makeFrameModalSingleSelectOption = (
	productList: ProductInfoType[],
	watch: any,
	field?: string
): OptionType[] => {
	if (!field) {
		return [];
	}
	const clickedFrame = productList
		.filter((product) => product.category === 'frame')
		.find((product) => product.id === watch('name'));
	const clickedTemple = productList.find((product) => product.id === watch('temple'));
	let options: OptionType[] = [];
	options =
		(clickedFrame?.[`${field}`] as string[])
			?.map((item): OptionType => {
				const findObj = productList
					.filter((product) => product.status !== 'invalid')
					.find((product) => product.id === item);
				return {
					value: findObj?.id || '',
					label: findObj?.label || '',
					colorCode: findObj?.colorCode || [],
					color: findObj?.labelColor || '',
				};
			})
			.filter((option) => option.value !== '') || [];
	// 값이 옵션안에 없을 경우에 찾아서 넣어준다.
	if (field === 'frontColor') {
		if (!options.find((option) => option.value === watch('frontColor'))) {
			const beforeColor = productList.find((product) => product.id === watch('frontColor'));
			if (beforeColor) {
				options.push({ value: beforeColor.id, label: beforeColor.label, colorCode: beforeColor?.colorCode || [] });
			}
		}
	}
	if (field === 'tip' || field === 'templeColor') {
		options =
			(clickedTemple?.[`${field}`] as string[])
				?.map((item): OptionType => {
					const findObj = productList.find((product) => product.id === item && product.status !== 'invalid');
					return {
						value: findObj?.id || '',
						label: findObj?.label || '',
						colorCode: findObj?.colorCode || [],
						color: findObj?.labelColor || '',
					};
				})
				.filter((option) => option.value !== '') || [];
	}
	if (field === 'frontPartsColor') {
		options =
			productList
				.find((product) => product.id === watch('frontParts'))
				?.partsColor?.map((item) => {
					const findObj = productList.find((product) => product.id === item && product.status !== 'invalid');
					return {
						value: findObj?.id || '',
						label: findObj?.label || '',
						colorCode: findObj?.colorCode || [],
					};
				})
				.filter((option) => option.value !== '') || [];
	}
	if (field === 'tipColor') {
		options =
			productList
				.find((product) => product.id === watch('tip'))
				?.tipColor?.map((item) => {
					const findObj = productList.find((product) => product.id === item && product.status !== 'invalid');
					return {
						value: findObj?.id || '',
						label: findObj?.label || '',
						colorCode: findObj?.colorCode || [],
					};
				})
				.filter((option) => option.value !== '') || [];
	}
	if (field === 'templeParts') {
		options =
			(clickedTemple?.connectParts as string[])
				?.map((item): OptionType => {
					const findObj = productList.find((product) => product.id === item && product.status !== 'invalid');
					return {
						value: findObj?.id || '',
						label: findObj?.label || '',
						colorCode: findObj?.colorCode || [],
					};
				})
				.filter((option) => option.value !== '') || [];
	}

	if (field === 'templePartsColor') {
		options =
			productList
				.find((product) => product.id === watch('templeParts'))
				?.partsColor?.map((item) => {
					const findObj = productList.find((product) => product.id === item && product.status !== 'invalid');
					return {
						value: findObj?.id || '',
						label: findObj?.label || '',
						colorCode: findObj?.colorCode || [],
					};
				})
				.filter((option) => option.value !== '') || [];
	}
	if (field === 'bridgeColor') {
		options =
			productList
				.find((product) => product.id === watch('bridge'))
				?.bridgeColor?.map((item) => {
					const findObj = productList.find((product) => product.id === item && product.status !== 'invalid');
					return {
						value: findObj?.id || '',
						label: findObj?.label || '',
						colorCode: findObj?.colorCode || [],
					};
				})
				.filter((option) => option.value !== '') || [];
	}
	if (!options || options.length === 0) {
		options =
			(clickedTemple?.[`${field}`] as OptionType[])?.map((item): OptionType => {
				return {
					value: item.value,
					label: item.label,
					colorCode: item?.colorCode || [],
				};
			}) || [];
	}
	return options;
};

export const findUrlField = (location: Location) => {
	return location.pathname
		.split('/')
		.filter((value) => value)
		.map((value, index) => {
			if (index !== 0) {
				return value.replace(/^[a-z]/, (char) => char.toUpperCase());
			}
			return value;
		})
		.join('');
};
