import { API } from 'aws-amplify';
import ErrorCheck from '../util/ErrorCheck';
import * as queries from '../graphql/queries';
import { ListProductInfoQuery, ProductInfoResponse } from '../util/Interface';

const getProductInfos = async (): Promise<ProductInfoResponse[]> => {
	const response: ProductInfoResponse[] = [];
	let token = '';
	try {
		while (token != null) {
			const productInfoData = (await API.graphql({
				query: queries.listProductInfo,
				variables: {
					limit: 100,
					nextToken: token === '' ? null : token,
				},
			})) as ListProductInfoQuery;
			token = productInfoData.data.listProductInfo.nextToken;
			const value = productInfoData.data.listProductInfo.items;
			response.push(...value);
			if (token === null) {
				break;
			}
		}
		return response;
	} catch (err) {
		ErrorCheck(err);
		return [];
	}
};

export default getProductInfos;
