import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { ConditionType, CustomFieldData, CustomFieldType, SingSelectOption } from '../../util/Interface';
import CustomFieldTextInput from './CustomFieldTextInput';
import CustomFieldSingleSelect from './CustomFieldSingleSelect';
import CustomFieldDateInput from './CustomFieldDateInput';
import { productInfoListState } from '../../atom';
import CustomFieldMultiSelect from './CustomFieldMultiSelect';
import ArrayInput from './ArrayInput';
import { checkCondition } from '../../util/ReplaceValue';
import TriangleArrayInput from './TriangleArrayInput';
import AdditionArrayInput from './AdditionArrayInput';
import ProgressiveArrayInput from './ProgressiveArrayInput';
import PrismArrayInput from './PrismArrayInput';
import LensOptionArrayInput from './LensOptionArrayInput';
import CustomFieldCheckBox from './CustomFieldCheckBox';

function MapInput({ fields }: CustomFieldType) {
	const productInfoList = useRecoilValue(productInfoListState);
	const { watch } = useFormContext();
	if (!fields) return null;
	const makeRefOption = (conditions: ConditionType[], data: CustomFieldData): SingSelectOption[] | undefined => {
		const { condition } = data;
		if (checkCondition(conditions, watch)) {
			return productInfoList
				.filter((product) => product.category === condition[0].field && product.parts === condition[0].value)
				.map((product) => {
					const { id, label } = product;
					return {
						label,
						value: id,
					};
				});
		}
		return undefined;
	};
	const makeLensRefOption = (conditions: ConditionType[]): SingSelectOption[] | undefined => {
		if (checkCondition(conditions, watch)) {
			return productInfoList
				.filter((product) => product.category === 'lensOption')
				.map((product) => {
					const { id, label } = product;
					return {
						label,
						value: id,
					};
				});
		}

		return undefined;
	};
	return (
		<>
			{fields.map((input) => {
				const { type, field, label, option, blank, nullable, condition, data } = input;
				switch (type) {
					case 'singleLineText':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return (
								<CustomFieldTextInput
									key={field}
									field={field}
									label={label}
									type={type}
									blank={blank}
									nullable={nullable}
								/>
							);
						}
						return null;
					case 'singleSelect':
						if (!option || !condition) return null;
						if (checkCondition(condition, watch)) {
							return (
								<CustomFieldSingleSelect
									key={field}
									field={field}
									label={label}
									option={option}
									nullable={nullable}
									blank={blank}
									type={type}
								/>
							);
						}
						return null;
					case 'multiSelect':
						if (!option || !condition) return null;
						if (checkCondition(condition, watch)) {
							return (
								<CustomFieldMultiSelect
									key={field}
									field={field}
									label={label}
									option={option}
									nullable={nullable}
									blank={blank}
									type={type}
								/>
							);
						}
						return null;
					case 'checkBox':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return (
								<CustomFieldCheckBox
									key={field}
									field={field}
									label={label}
									option={option}
									nullable={nullable}
									blank={blank}
									type={type}
								/>
							);
						}
						return null;
					case 'datetime':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return (
								<CustomFieldDateInput
									key={field}
									field={field}
									label={label}
									blank={blank}
									nullable={nullable}
									type={type}
								/>
							);
						}
						return null;
					case 'ref':
						if (!condition || !data) return null;
						if (makeRefOption(condition, data)) {
							return (
								<CustomFieldMultiSelect
									key={field}
									blank={blank}
									nullable={nullable}
									field={field}
									label={label}
									type={type}
									option={makeRefOption(condition, data)}
								/>
							);
						}
						return null;
					case 'lensRef':
						if (!condition) return null;
						if (makeLensRefOption(condition)) {
							return (
								<CustomFieldMultiSelect
									key={field}
									blank={blank}
									nullable={nullable}
									field={field}
									label={label}
									type={type}
									option={makeLensRefOption(condition)}
								/>
							);
						}
						return null;
					case 'rectangleArray':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return <ArrayInput key={field} />;
						}
						return null;
					case 'triangleArray':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return <TriangleArrayInput key={field} />;
						}
						return null;
					case 'additionArray':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return <AdditionArrayInput key={field} />;
						}
						return null;
					case 'progressiveArray':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return <ProgressiveArrayInput key={field} />;
						}
						return null;
					case 'prismArray':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return <PrismArrayInput key={field} />;
						}
						return null;
					case 'lensOptionArray':
						if (!condition) return null;
						if (checkCondition(condition, watch)) {
							return <LensOptionArrayInput key={field} />;
						}
						return null;
					default:
						return null;
				}
			})}
		</>
	);
}

export default MapInput;
