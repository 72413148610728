import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { InputType } from '../../util/Interface';
import { generateRandomCode, makePhoneNumber } from '../../util/ReplaceValue';
import sendSMS from '../../query/sendSMS';
import { fetchUser } from '../../selector';

function PhoneBtnInput({ field, label, grid, setRandom }: InputType) {
	const {
		control,
		getValues,
		formState: { errors },
	} = useFormContext();

	const userInfo = useRecoilValue(fetchUser);
	const handleSMS = (number: string) => {
		const receiver = number.replaceAll('-', '');
		const randomNumber = generateRandomCode(4);
		if (setRandom !== undefined) {
			setRandom(randomNumber);
		}
		sendSMS(userInfo?.storeTel || '', receiver, randomNumber).then((value) =>
			value === 'success' ? alert('인증번호가 전송되었습니다.') : alert('인증번호 전송에 실패했습니다.')
		);
	};
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value } }) => (
					<TextField
						value={makePhoneNumber(value as string) || ''}
						variant="standard"
						size="small"
						label={label}
						onChange={(e) => onChange(makePhoneNumber(e.target.value))}
						fullWidth
						error={Boolean(errors[`${field}`] || false)}
						helperText={errors[`${field}`]?.message}
						InputProps={{
							disabled: getValues ? getValues('certification') : false,
							endAdornment: (
								<Button
									variant="outlined"
									size="small"
									style={{
										marginBottom: '5px',
										minWidth: 50,
									}}
									onClick={() => handleSMS((value as string) || '')}
									disabled={getValues ? getValues('certification') : false}
								>
									전송
								</Button>
							),
						}}
					/>
				)}
			/>
		</Grid>
	);
}

export default PhoneBtnInput;
