import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { InputType } from '../../util/Interface';
import { openAddressState } from '../../atom/Atom';

function AddressInput({ field, label, grid, variant, size }: InputType) {
	const { control, getValues } = useFormContext();
	const setOpenAddress = useSetRecoilState(openAddressState);
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value } }) => (
					<TextField
						variant={variant}
						onChange={onChange}
						value={
							value !== ''
								? `${value}`.concat(`${getValues('extraAddress') ? `(${getValues('extraAddress')})` : ''}`)
								: value
						}
						label={label}
						size={size}
						InputProps={{
							readOnly: true,
							endAdornment: (
								<Button
									variant="outlined"
									size="small"
									style={
										variant
											? {
													minWidth: 80,
													marginBottom: '5px',
											  }
											: {
													minWidth: 80,
											  }
									}
									onClick={() => setOpenAddress(true)}
								>
									주소찾기
								</Button>
							),
						}}
						fullWidth
					/>
				)}
			/>
		</Grid>
	);
}

export default AddressInput;
