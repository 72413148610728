import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { NestArrayProps } from '../../util/Interface';

function NestProgressiveArray({ nestIndex }: NestArrayProps) {
	const { control, setValue } = useFormContext();
	const { fields, remove, append } = useFieldArray({
		control,
		name: `lensOptionArray[${nestIndex}].progressiveRange`,
	});

	const rangeValue = useWatch({ control, name: `lensOptionArray[${nestIndex}].ranges` });

	useEffect(() => {
		if (rangeValue === 'progressive') {
			append({ progressiveMax: '', progressiveMin: '' });
			setValue(`lensOptionArray[${nestIndex}].ranges`, '');
		}
	}, [append, nestIndex, rangeValue, setValue]);

	return (
		<>
			{fields.map((item, index) => {
				return (
					<Grid container spacing={2} pt={2} key={item.id}>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].progressiveRange[${index}].progressiveMin`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].progressiveRange[${index}].progressiveMin`}
										label="Progressive 최소"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].progressiveRange[${index}].progressiveMax`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].progressiveRange[${index}].progressiveMax`}
										label="Progressive 최대"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].progressiveRange[${index}].progressiveUnit`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].progressiveRange[${index}].progressiveUnit`}
										label="Progressive 단위"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item>
							<Button onClick={() => remove(index)}>
								<DeleteIcon />
							</Button>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
}

export default NestProgressiveArray;
