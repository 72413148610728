import React from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

function PremiumCalendar() {
	return (
		<FullCalendar
			plugins={[resourceTimeGridPlugin]}
			resources={[]}
			schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_KEY}
		/>
	);
}

export default PremiumCalendar;
