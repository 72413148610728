import * as yup from 'yup';
import { LensType, ReserveType } from './Interface';

export const reserveValidation = yup.lazy((value: ReserveType) => {
	if (value.reserveType === 'attention') {
		return yup.object().shape({
			reserveType: yup.string().required('예약유형을 입력해주세요.'),
			reserveMedia: yup.string().required('예약경로를 입력해주세요.'),
		});
	}
	return yup.object().shape({
		reserveType: yup.string().required('예약유형을 입력해주세요.'),
		name: yup.string().required('성함을 입력해주세요.'),
		phone: yup.string().required('핸드폰 번호를 입력해주세요.'),
	});
});

export const lensValidation = yup.lazy((value: LensType) => {
	if (value.firstOption?.includes('mir') || value.secondOption?.includes('mir') || value.thirdOption?.includes('mir')) {
		return yup.object().shape({
			name: yup.string().required('제품명을 선택해주세요.'),
			lensCoating: yup.string().required('코팅을 선택해주세요.'),
			lensOrderType: yup.string().required('여벌/주문을 선택해주세요.'),
			lensCategory: yup.string().required('좌우유형을 선택해주세요.'),
			mirColor: yup.string().required('미러컬러를 선택해주세요.'),
		});
	}
	return yup.object().shape({
		name: yup.string().required('제품명을 선택해주세요.'),
		lensCoating: yup.string().required('코팅을 선택해주세요.,'),
		lensOrderType: yup.string().required('여벌/주문을 선택해주세요.,'),
		lensCategory: yup.string().required('좌우유형을 선택해주세요.,'),
	});
});

export const customerValidation = yup.object().shape({
	name: yup.string().required('이름을 입력해 주세요.'),
	gender: yup.string().required('성별을 입력해 주세요.'),
	birthday: yup.date().required('생년월일을 입력해 주세요.').nullable(),
	phone: yup.string().required('전화번호를 입력해 주세요.'),
	certificationNumber: yup.string().required('인증번호를 입력해 주세요.'),
	email: yup.string().email('이메일 형식이 아닙니다.'),
	infoAgree: yup.boolean().required('필수동의사항').oneOf([true], '필수동의사항'),
	asAgree: yup.boolean().required('필수동의사항').oneOf([true], '필수동의사항'),
	offerAgree: yup.boolean().required('필수동의사항').oneOf([true], '필수동의사항'),
	certification: yup.boolean().required('인증을 해주세요.').oneOf([true], '인증을 해주세요.'),
});

export const staffValidation = yup.object().shape({
	id: yup.string().required('아이디를 입려해 주세요.'),
	name: yup.string().required('이름을 입력해 주세요.'),
	labelKr: yup.string().required('한글아이디를 입력해 주세요.'),
	storeId: yup.string().required('근무지를 선택해 주세요.'),
});

export const storeValidation = yup.object().shape({
	id: yup
		.string()
		.required('아이디를 입력해 주세요.')
		.matches(/[a-z]{2}([-])[\w]{2}.*/, 'ex) kr-st-1'),
	code: yup
		.string()
		.required('코드를 입력해 주세요.')
		.matches(/^[a-zA-Z]*$/, '영문으로만 입력해주세요.')
		.length(2, '두 글자만 입력해주세요.')
		.uppercase(),
	name: yup.string().required('매장명을 입력해 주세요.'),
	storeType: yup.string().required('매장구분을 입력해 주세요.'),
});
