import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SubNav from '../../components/SubNav';
import { customerValidation } from '../../util/Validation';
import KakaoPost from '../../components/modal/KakaoPost';
import Signature from '../../components/modal/Signature';
import { fetchUser } from '../../selector';
import { customerInputsFir, customerInputsSec, customerInputsThr } from '../../util/Array';
import { createCustomer, updateCustomer, createAutoReserve } from '../../query';
import { CustomerResponse, CustomerType } from '../../util/Interface';
import ErrorCheck from '../../util/ErrorCheck';

import AgreeModal from '../../components/modal/AgreeModal';
import {
	LoadAutoCompleteInput,
	SingleSelectInput,
	TextInput,
	PhoneBtnInput,
	TextBtnInput,
	DateInput,
	CheckBoxInput,
	AddressInput,
} from '../../components/form';

function CustomerRegister() {
	const navigate = useNavigate();
	const [random, setRandom] = useState<string>('');
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedField, setSelectedField] = useState<string>('');

	const userInfo = useRecoilValue(fetchUser);
	const methods = useForm<CustomerType>({
		defaultValues: {
			id: undefined,
			name: '',
			phone: '',
			certificationNumber: '',
			certification: false,
			gender: '',
			birthday: null,
			email: '',
			address: '',
			extraAddress: '',
			detailAddress: '',
			infoAgree: true,
			offerAgree: true,
			adAgree: true,
			signature: null,
			customerType: '',
			rcgPath: '',
			preferContact: '',
			noReserve: false,
		},
		resolver: yupResolver(customerValidation),
	});

	const { handleSubmit, reset, setValue, watch } = methods;

	const onSubmit: SubmitHandler<CustomerType> = (data) => {
		if (!data.signature) {
			alert('고객 서명이 필요합니다.');
		} else {
			if (!data.id) {
				createCustomer(data, userInfo)
					.then(async (customer: CustomerResponse | null) => {
						if (data.noReserve) {
							await createAutoReserve(userInfo, customer)
								.then(() => {
									navigate('/store/customer');
								})
								.catch((e) => {
									ErrorCheck(e);
								});
						} else {
							navigate('/store/customer');
						}
					})
					.catch((e) => {
						ErrorCheck(e);
					});
			} else {
				updateCustomer(data, userInfo)
					.then(async (customer: CustomerResponse | null) => {
						if (data.noReserve) {
							await createAutoReserve(userInfo, customer).then(() => {
								navigate('/store/customer');
							});
						} else {
							navigate('/store/customer');
						}
					})
					.catch((e) => {
						ErrorCheck(e);
					});
			}
			reset();
		}
	};

	const handleCheckBoxClick = (field: string) => {
		setOpenModal(true);
		setSelectedField(field);
	};

	return (
		<Box>
			<Box px={5}>
				<SubNav />
				<Grid container spacing={1} mb={1}>
					<Grid item xs={12}>
						<Typography fontSize={28} fontWeight={300}>
							오늘 만나는 내일의 안경
						</Typography>
						<Typography fontSize={28}>우리는 브리즘입니다</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={1} alignItems="center" mb={1}>
					<Grid item xs={2}>
						<Typography fontSize={24} color="#0960E3">
							방문 고객님 작성
						</Typography>
					</Grid>
					<Grid item xs={10}>
						<Divider color="#000000" />
					</Grid>
				</Grid>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={3} alignItems="center" mb={1}>
							{customerInputsFir.map((input) => {
								const { options, type, field, label, grid } = input;
								switch (type) {
									case 'loadAutocomplete':
										if (!field || !label) return null;
										return (
											<LoadAutoCompleteInput
												key={`customerInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												type={type}
												variant="standard"
											/>
										);
									case 'phoneButton':
										if (!field || !label) return null;
										return (
											<PhoneBtnInput
												key={`customerInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												setRandom={setRandom}
												type={type}
											/>
										);
									case 'textButton':
										if (!field || !label) return null;
										return (
											<TextBtnInput
												key={`customerInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												type={type}
												random={random}
											/>
										);
									case 'singleSelect':
										if (!field || !label) return null;
										return (
											<SingleSelectInput
												key={`customerInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												options={options}
												type={type}
												variant="standard"
											/>
										);
									case 'date':
										if (!field || !label) return null;
										return (
											<DateInput
												key={`customerInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												type={type}
												variant="standard"
											/>
										);
									case 'text':
										if (!field || !label) return null;
										return (
											<TextInput
												key={`customerInput_${field}`}
												field={field}
												type={type}
												grid={grid}
												label={label}
												variant="standard"
											/>
										);
									case 'address':
										if (!field || !label) return null;
										return (
											<AddressInput
												key={`customerInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												type={type}
												variant="standard"
												size="small"
											/>
										);
									default:
										return null;
								}
							})}
						</Grid>
						<Grid container spacing={1} mb={5}>
							{customerInputsSec.map((input) => {
								const { field, label, grid, type } = input;
								return (
									<Grid item xs={grid} display="flex" alignItems="center" key={`customerInput_${field}`}>
										<CheckBoxInput field={field as string} label={label as string} grid={grid} type={type} withButton />
										{field !== 'noReserve' && (
											<>
												<Button style={{ fontSize: 12 }} onClick={() => handleCheckBoxClick(field as string)}>
													약관보기
													<ArrowRightIcon fontSize="small" />
												</Button>
												<Grid item xs={1} />
											</>
										)}
									</Grid>
								);
							})}
							<Grid item xs={7} />
							<Grid item xs={12}>
								<Typography fontSize={12} color="#9B9B9B">
									*개인 정보와 얼굴 사이즈 측정 및 스캔 데이터, 검안 데이터 등의 신체 정보에 대한 활용 동의를 해 주셔야
									서비스 진행이 가능합니다.
								</Typography>
							</Grid>
							<Grid item xs={9} />
							<Grid item xs={1} display="flex" justifyContent="end" alignItems="center">
								<Typography fontWeight="bold" fontSize={16}>
									{watch().name}
								</Typography>
							</Grid>
							<Grid item xs={1}>
								<Signature setValue={(value: File) => setValue('signature', value)} />
							</Grid>
						</Grid>
						<Grid container spacing={1} alignItems="center" mb={1}>
							<Grid item xs={2}>
								<Typography fontSize={24} color="#0960E3">
									안경사 기입란
								</Typography>
							</Grid>
							<Grid item xs={10}>
								<Divider color="#000000" />
							</Grid>
						</Grid>
						<Grid container spacing={5}>
							{customerInputsThr.map((input) => {
								const { type, options, field, label, grid } = input;
								switch (type) {
									case 'checkbox':
										return (
											<Grid key={`customerInput_${field}`} item xs={grid} display="flex" alignItems="center">
												<CheckBoxInput
													field={field as string}
													label={label as string}
													grid={grid}
													type={type}
													withButton
												/>
											</Grid>
										);
									case 'singleSelect':
										return (
											<SingleSelectInput
												key={`customerInput_${field}`}
												field={field}
												label={label}
												grid={grid}
												options={options}
												type={type}
												variant="standard"
												fontSize={14}
											/>
										);
									default:
										return null;
								}
							})}
							<Grid item xs={2} />
							<Grid item xs={4} display="flex" alignItems="flex-end">
								<Button variant="contained" type="submit" fullWidth>
									고객 등록
								</Button>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</Box>
			<KakaoPost setValue={(field: string, value: string) => setValue(field, value)} />
			<AgreeModal open={openModal} setOpen={setOpenModal} field={selectedField} />
		</Box>
	);
}

export default CustomerRegister;
