import { selector } from 'recoil';
import { API } from 'aws-amplify';
import moment from 'moment';
import { onCreReserveDay, selectedDate } from '../atom';
import fetchUser from './fetchUser';
import * as queries from '../graphql/queries';
import { convertReserve } from '../util/ReplaceValue';
import { ReserveType, fetchDayReservationQuery } from '../util/Interface';

export default selector<ReserveType[]>({
	key: 'fetchDayReservation',
	get: async ({ get }) => {
		const date = get(selectedDate);
		const storeId = get(fetchUser)?.storeId;
		get(onCreReserveDay);
		if (storeId === '' || storeId === undefined) return [];

		let token = '';
		const result = [];
		while (token != null) {
			const data = (await API.graphql({
				query: queries.listReservationByDayWithStore,
				variables: {
					reserveYMD: moment(date).format('yyyy-MM-DD'),
					storeId,
					limit: 100,
					nextToken: token === '' ? null : token,
				},
			})) as fetchDayReservationQuery;
			token = data.data.listReservationByDayWithStore.nextToken;
			const value: ReserveType[] = convertReserve(data.data.listReservationByDayWithStore.items)
				// 예약 일시, updatedAt에 따라서 변경
				.sort((a, b) => {
					if (a.reserveDate.toISOString().substring(0, 19) > b.reserveDate.toISOString().substring(0, 19)) {
						return 1;
					}
					if (a.reserveDate.toISOString().substring(0, 19) < b.reserveDate.toISOString().substring(0, 19)) {
						return -1;
					}
					if (a.reserveDate.toISOString().substring(0, 19) === b.reserveDate.toISOString().substring(0, 19)) {
						if (
							moment(a.createdAt).toISOString().substring(0, 19) > moment(b.createdAt).toISOString().substring(0, 19)
						) {
							return 1;
						}
						if (
							moment(a.createdAt).toISOString().substring(0, 19) < moment(b.createdAt).toISOString().substring(0, 19)
						) {
							return -1;
						}
						return 0;
					}
					return 0;
				})
				// 관심/문의(attention) 제거 filtering
				.filter((reserve) => reserve.reserveType !== 'attention');
			result.push(...value);
			if (token === null) {
				break;
			}
		}

		return result;
	},
});
