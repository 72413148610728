import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import confirmModal from '../../atom/confirmModal';
import { deleteReserve } from '../../query';
import ErrorCheck from '../../util/ErrorCheck';

interface ConfirmModalProps {
	purpose: string;
	id: string;
	close: () => void;
}

function DeleteModal(props: ConfirmModalProps) {
	const { purpose, id, close } = props;
	const [open, setOpen] = useRecoilState(confirmModal);

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = () => {
		deleteReserve(id)
			.then(() => {
				handleClose();
				if (close) {
					close();
				}
			})
			.catch((e) => {
				ErrorCheck(e);
			});
	};
	return (
		<Dialog open={open}>
			<DialogTitle
				sx={{
					fontWeight: 700,
					textAlign: 'center',
					padding: '24px',
				}}
			>
				{`${purpose} 삭제`}
			</DialogTitle>
			<DialogContent>
				<DialogContentText sx={{ fontSize: 16, fontWeight: 500 }}>
					입력하신 내용을 정말로 삭제하시겠습니까?
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'space-around', padding: '24px' }}>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<Button onClick={handleClose} fullWidth variant="outlined" size="large">
							취소
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" size="large" onClick={handleDelete}>
							삭제
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}

export default DeleteModal;
