import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridRowModel } from '@mui/x-data-grid-pro';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { API } from 'aws-amplify';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import SubNav from '../../components/SubNav';
import ReserveDayCol from '../../columns/ReserveDayCol';
import koText from '../../util/localizationKR';
import CustomToolbar from '../../components/CustomToolbar';
import NoDataOverlay from '../../components/NoDataOverlay';
import { ReservationResponse, ReserveType } from '../../util/Interface';
import { openReserveState } from '../../atom/Atom';
import { selectedStore, reserveDetail, selectedDate } from '../../atom';
import * as subscriptions from '../../graphql/subscriptions';
import ReserveModal from '../../components/modal/ReserveModal';
import fetchDayStoreReserve from '../../query/fetchDayStoreReserve';
import ErrorCheck from '../../util/ErrorCheck';
import { convertReserve, findUrlField, reserveTimeSequenceSort } from '../../util/ReplaceValue';
import { fetchUser } from '../../selector';

function AdminReserve() {
	const userInfo = useRecoilValue(fetchUser);
	const setOpen = useSetRecoilState(openReserveState);
	const setScheduleDetail = useSetRecoilState(reserveDetail);
	const date = useRecoilValue(selectedDate);
	const store = useRecoilValue(selectedStore);
	const [reservations, setReservations] = useState<ReserveType[]>([]);
	const location = useLocation();
	useEffect(() => {
		fetchDayStoreReserve(date, store)
			.then((res) => {
				if (res === null) {
					setReservations([]);
				} else {
					setReservations(res);
				}
			})
			.catch((e) => {
				ErrorCheck(e);
			});
	}, [date, store]);

	const onCreateReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateReservationByDay,
					variables: {
						storeId: store,
						reserveYMD: moment(date).format('yyyy-MM-DD'),
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onCreateReservationByDay as ReservationResponse;
					// attention 제거, sort 시간순서에 따라서 정렬하도록!
					if (item.reserveType !== 'attention') {
						const newArray = [...reservations];
						const reserve = convertReserve([item]);
						const newReserveList = reserveTimeSequenceSort([...newArray, ...reserve]); // 시간 순서대로 다시 정렬
						setReservations([...newReserveList]);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
					window.location.reload();
				},
			}),
		[date, reservations, store]
	);

	const onUpdateReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateReservationByDay,
					variables: {
						storeId: store,
						reserveYMD: moment(date).format('yyyy-MM-DD'),
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateReservationByDay as ReservationResponse;
					let newArray: ReserveType[] = [...reservations];
					const index = newArray.findIndex((reserve) => reserve.id === item.id);
					const reserve = convertReserve([item])[0];
					newArray[index] = reserve;
					newArray = reserveTimeSequenceSort(newArray);
					setReservations([...newArray]);
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[date, reservations, store]
	);

	const onDeleteReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onDeleteReservationByDay,
					variables: {
						storeId: store,
						reserveYMD: moment(date).format('yyyy-MM-DD'),
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onDeleteReservationByDay as ReservationResponse;
					const newArray = [...reservations];
					const index = newArray.findIndex((reserve) => reserve.id === item.id);
					if (index > -1) newArray.splice(index, 1);
					setReservations(newArray);
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[store, date, reservations]
	);

	useEffect(() => {
		if (store) {
			const create = onCreateReserve();
			const update = onUpdateReserve();
			const remove = onDeleteReserve();
			return () => {
				create.unsubscribe();
				update.unsubscribe();
				remove.unsubscribe();
			};
		}
		return undefined;
	}, [onCreateReserve, onDeleteReserve, onUpdateReserve, store]);

	const field = findUrlField(location);

	if (!userInfo) {
		return null;
	}
	return (
		<>
			<Box px={5}>
				<SubNav />
				<div style={{ height: '80vh', width: '100%' }}>
					<DataGridPro
						rows={(reservations as GridRowModel[]) || []}
						columns={ReserveDayCol()}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						style={{ borderLeft: 'none', borderRight: 'none' }}
						localeText={koText}
						disableColumnMenu
						density={userInfo.dataGridState?.[`${field}`]?.density || 'standard'}
						initialState={{
							columns: {
								...userInfo.dataGridState?.[`${field}`]?.columns,
								columnVisibilityModel: userInfo.dataGridState?.[`${field}`]?.columns?.columnVisibilityModel || {
									staff: false,
								},
							},
							filter: userInfo.dataGridState?.[`${field}`]?.filter,
						}}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay: NoDataOverlay,
						}}
						componentsProps={{
							toolbar: { field },
						}}
						onCellClick={(params) => {
							setScheduleDetail(params.row as ReserveType);
							setOpen(true);
						}}
						hideFooter
					/>
				</div>
			</Box>
			<ReserveModal />
		</>
	);
}

export default AdminReserve;
