import React, { useState } from 'react';
import { Box, Button, Popover, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { ko } from 'date-fns/esm/locale';
import { selectedDate } from '../atom';

function ReserveDate() {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [value, setValue] = useRecoilState(selectedDate);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<>
			<Button variant="outlined" onClick={handleClick} fullWidth style={{ color: 'black', borderColor: 'black' }}>
				{moment(new Date(String(value))).format('YY.MM.DD(dd)')}
				<Box ml={1} display="flex" alignItems="center">
					<DateRangeIcon />
				</Box>
			</Button>
			<Popover
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
					<StaticDatePicker
						displayStaticWrapperAs="desktop"
						label={moment(new Date(String(value))).format('YY.MM.DD')}
						value={value}
						onChange={(newValue) => {
							if (newValue !== null) {
								setValue(newValue);
								setAnchorEl(null);
							}
						}}
						renderInput={(params) => <TextField {...params} />}
						inputFormat="yy.MM.DD"
					/>
				</LocalizationProvider>
			</Popover>
		</>
	);
}

export default ReserveDate;
