import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import error from '../../images/error.png';

function NoAuthPage() {
	const navigate = useNavigate();
	return (
		<Grid
			container
			spacing={2}
			direction="column"
			alignItems="center"
			justifyContent="center"
			style={{ minHeight: '80vh' }}
		>
			<Grid item xs={6}>
				<Typography color="#0960E3" fontSize={32} fontWeight="bold">
					페이지에 대한 권한이 없습니다.
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<Typography color="#606060" fontSize={18}>
					잘못된 경로로 메뉴를 눌렀을 때나 의도치 않은
				</Typography>
				<Typography color="#606060" fontSize={18}>
					문제가 발생하더라도 걱정할 필요가 없습니다.
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<img src={error} alt="error" style={{ width: '243px', height: 'auto', objectFit: 'cover' }} />
			</Grid>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={3} lg={2}>
					<Button fullWidth variant="contained" onClick={() => navigate('/')}>
						메인메뉴로 돌아가기
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default NoAuthPage;
