import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { convertReserve } from '../util/ReplaceValue';
import { ReservationResponse } from '../util/Interface';
import ErrorCheck from '../util/ErrorCheck';

export interface fetchCustomerQuery {
	data: {
		listReservationByStore: {
			items: ReservationResponse[];
			nextToken: string;
		};
	};
}
interface fetchReserveProps {
	storeId: string;
	check?: string;
}

const fetchReserve = async ({ storeId, check }: fetchReserveProps) => {
	let token = '';
	if (check) {
		token = check;
	} else {
		token = '';
	}
	try {
		const data = (await API.graphql({
			query: queries.listReservationByStore,
			variables: {
				storeId,
				sortDirection: 'DESC',
				limit: 100,
				nextToken: token === '' ? null : token,
			},
		})) as fetchCustomerQuery;

		token = data.data.listReservationByStore.nextToken;
		return {
			reserve: convertReserve(data.data.listReservationByStore.items).filter(
				(reserve) => reserve.reserveType !== 'attention'
			),
			token,
		};
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default fetchReserve;
