import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { CustomerResponse, CustomerType } from '../util/Interface';
import { convertCustomer } from '../util/ReplaceValue';
import ErrorCheck from '../util/ErrorCheck';

export interface fetchCustomerSearchQuery {
	data: {
		listCustomerSearch: {
			items: CustomerResponse[];
			nextToken: string;
		};
	};
}

interface fetchCustomerProps {
	check?: string;
}

const fetchCustomer = async ({ check }: fetchCustomerProps) => {
	let token = '';
	if (check === '') token = '';
	try {
		const data = (await API.graphql({
			query: queries.listCustomerSearch,
			variables: {
				limit: 100,
				status: 'valid',
				nextToken: token === '' ? null : token,
			},
		})) as fetchCustomerSearchQuery;
		token = data.data.listCustomerSearch.nextToken;
		return convertCustomer(data.data.listCustomerSearch.items);
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default fetchCustomer;
