import { API } from 'aws-amplify';
import { ProductInfoType } from '../util/Interface';
import ErrorCheck from '../util/ErrorCheck';
import * as mutations from '../graphql/mutations';

const updateProductInfo = async (data: ProductInfoType) => {
	try {
		await API.graphql({
			query: mutations.updateProductInfo,
			variables: {
				input: data,
			},
		});
	} catch (err) {
		ErrorCheck(err);
	}
};

export default updateProductInfo;
