import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import CheckedSurveyChip from './CheckedSurveyChip';
import SurveyChip from './SurveyChip';

interface SurveyCheckbox {
	label: string;
}

function SurveyCheckbox(props: CheckboxProps & SurveyCheckbox) {
	const { label } = props;
	return (
		<Checkbox
			{...props}
			sx={{ margin: 0 }}
			disableRipple
			checkedIcon={<CheckedSurveyChip label={label} />}
			icon={<SurveyChip label={label} />}
		/>
	);
}

export default SurveyCheckbox;
