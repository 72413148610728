import React, { useState } from 'react';
import { Alert, Button, Grid, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import moment from 'moment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReserveDate from './ReserveDate';
import SearchBar from './SearchBar';
import SubNavList from './SubNavList';
import { openProductInfoState, openReserveState } from '../atom/Atom';
import ReserveDateRange from './ReserveDateRange';
import { fetchUser } from '../selector';
import { dayReserveState, openStaffModal, reserveDetail, storeModalState } from '../atom';
import { NavType } from '../util/Interface';
import ReserveStore from './ReserveStore';
import searchType from '../atom/searchType';

function SubNav() {
	const reserveNav = [
		{ label: '일간', value: 'day', link: '/store/reserve' },
		{ label: '스케줄러', value: 'schedule', link: '/store/reserve-schedule' },
		{ label: '전체', value: 'total', link: '/store/reserve-total' },
	];

	const customerNav = [
		{ label: '등록', value: 'register', link: '/store/customer-register' },
		{ label: '조회', value: 'check', link: '/store/customer' },
		{ label: '고객정보', value: 'info', link: '/store/customer-info' },
	];

	const orderNav: NavType[] = [
		{ label: '프레임', value: 'fOrder', link: '/store/frame-order' },
		{ label: '렌즈', value: 'lOrder', link: '/store/lens-order' },
		{ label: '진행상황', value: 'progress', link: '/store/progress' },
		{ label: '설계확인', value: 'modeling', link: '/store/modeling' },
		{ label: '입고확인', value: 'release', link: '/store/release' },
		{ label: '완성연락', value: 'contact', link: '/store/contact' },
		{ label: '제품수령', value: 'package', link: '/store/package' },
	];

	const productNav = [
		{ label: 'All', value: 'all', link: '/product/all' },
		{ label: '3DP', value: 'modeling', link: '/product/modeling' },
		{ label: 'Polishing', value: 'polishing', link: '/product/polishing' },
		{ label: 'Dyeing', value: 'dyeing', link: '/product/dyeing' },
		{ label: 'Dispatch', value: 'dispatch', link: '/product/dispatch' },
	];

	const adminReserveNav = [
		{ label: '일간', value: 'store', link: '/admin/reserve' },
		{ label: '전체', value: 'all', link: '/admin/reserve/all' },
	];

	const location = useLocation();

	const setSchedule = useSetRecoilState(openReserveState);
	const resetUser = useResetRecoilState(fetchUser);
	const [scheduleDetail, setScheduleDetail] = useRecoilState(reserveDetail);
	const setStaffModal = useSetRecoilState(openStaffModal);
	const setStoreModal = useSetRecoilState(storeModalState);
	const setProductModal = useSetRecoilState(openProductInfoState);
	const dayReservations = useRecoilValue(dayReserveState);
	const [searchTypeValue, setSearchTypeValue] = useRecoilState(searchType);
	const [copy, setCopy] = useState<boolean>(false);

	const handleCopy = () => {
		setCopy(true);
		const customCount = dayReservations
			.filter((reserve) => reserve.reserveType === 'custom' && !reserve.cancel)
			.length.toString();
		const asCount = dayReservations
			.filter((reserve) => reserve.reserveType === 'as' && !reserve.cancel)
			.length.toString();
		const packageCount = dayReservations
			.filter((reserve) => reserve.reserveType === 'package' && !reserve.cancel)
			.length.toString();
		const cancelCount = dayReservations.filter((reserve) => reserve.cancel).length.toString();
		const copyValue = `${customCount}\n${asCount}\n${packageCount}\n${cancelCount}`;

		navigator.clipboard.writeText(copyValue);
	};

	const handleCloseCopyAlert = () => {
		setCopy(false);
	};

	return (
		<>
			{/* 예약 서브 네비 */}
			{(location.pathname === '/store/reserve' ||
				location.pathname === '/store/reserve-schedule' ||
				location.pathname === '/store/reserve-total') && (
				<Grid container spacing={1} alignItems="center" py={2} mb={1}>
					{location.pathname === '/store/reserve' ? (
						<Grid item xs={2}>
							<Button
								size="small"
								variant="contained"
								style={{ minWidth: '130px' }}
								onClick={() => {
									resetUser();
									setScheduleDetail({
										...scheduleDetail,
										reserveDate: moment(new Date())
											.add(10 - (moment(new Date()).minute() % 10), 'minutes')
											.toDate(),
									});
									setSchedule(true);
								}}
							>
								예약하기
							</Button>
						</Grid>
					) : (
						<Grid item xs={2} />
					)}
					<SubNavList navList={reserveNav} />
					{location.pathname === '/store/reserve-total' && (
						<>
							<Grid item xs={3}>
								<SearchBar />
							</Grid>
							<Grid item xs={4}>
								<ReserveDateRange />
							</Grid>
						</>
					)}
					{location.pathname === '/store/reserve' && (
						<>
							<Grid item xs={3} />
							<Grid item xs={2} display="flex" alignItems="center">
								<Typography>보고서용 복사</Typography>
								<Button onClick={handleCopy}>
									<ContentCopyIcon />
								</Button>
								<Snackbar open={copy} autoHideDuration={2000} onClose={handleCloseCopyAlert}>
									<Alert onClose={handleCloseCopyAlert} severity="success" sx={{ width: '100%' }}>
										일일보고용 방문예약이 복사되었습니다.
									</Alert>
								</Snackbar>
							</Grid>
							<Grid item xs={2}>
								<ReserveDate />
							</Grid>
						</>
					)}
				</Grid>
			)}
			{/* 고객 서브 네비 */}
			{location.pathname.includes('customer') && (
				<Grid container spacing={1} alignItems="center" py={2} mb={1}>
					<Grid item xs={3} />
					<SubNavList navList={customerNav} />
					<Grid item xs={5} />
					<Grid item xs={1}>
						{location.pathname === '/store/customer' && (
							<Button variant="contained" fullWidth>
								결재
							</Button>
						)}
					</Grid>
				</Grid>
			)}
			{/* 관리자 예약서브 네비 */}
			{location.pathname.includes('/admin/reserve') && (
				<Grid container spacing={1} alignItems="center" py={2} mb={1}>
					<Grid item xs={2}>
						{location.pathname === '/admin/reserve' && (
							<Button
								size="small"
								variant="contained"
								style={{ minWidth: '130px' }}
								onClick={() => {
									resetUser();
									setScheduleDetail({
										...scheduleDetail,
										reserveDate: moment(new Date())
											.add(10 - (moment(new Date()).minute() % 10), 'minutes')
											.toDate(),
									});
									setSchedule(true);
								}}
							>
								예약하기
							</Button>
						)}
					</Grid>
					<SubNavList navList={adminReserveNav} />
					<Grid item xs={4} />
					{location.pathname === '/admin/reserve' && (
						<>
							<Grid item xs={2}>
								<ReserveStore />
							</Grid>
							<Grid item xs={2}>
								<ReserveDate />
							</Grid>
						</>
					)}
					{location.pathname === '/admin/reserve/all' && (
						<>
							{/* <Grid item xs={1}>
								<TextField
									label="타입"
									size="small"
									value={searchTypeValue}
									onChange={(e) => setSearchTypeValue(e.target.value)}
									select
									fullWidth
								>
									<MenuItem value="name">이름</MenuItem>
									<MenuItem value="phone">전화</MenuItem>
								</TextField>
							</Grid> */}
							<Grid item xs={4}>
								<SearchBar />
							</Grid>
						</>
					)}
				</Grid>
			)}
			{/* 관리자 직원서브 네비 */}
			{location.pathname.includes('/admin/staff') && (
				<Grid container spacing={1} alignItems="center" py={2} mb={1}>
					<Grid item xs={2}>
						<Button
							size="small"
							variant="contained"
							style={{ minWidth: '130px' }}
							onClick={() => {
								setStaffModal(true);
							}}
						>
							신규등록
						</Button>
					</Grid>
					<Grid item xs={3}>
						<SearchBar />
					</Grid>
				</Grid>
			)}
			{/* 관리자 매장서브 네비 */}
			{location.pathname.includes('/admin/store') && (
				<Grid container spacing={1} alignItems="center" py={2} mb={1}>
					<Grid item xs={2}>
						<Button
							size="small"
							variant="contained"
							style={{ minWidth: '130px' }}
							onClick={() => {
								setStoreModal(true);
							}}
						>
							신규등록
						</Button>
					</Grid>
				</Grid>
			)}
			{location.pathname.includes('/admin/product') && (
				<Grid container spacing={1} alignItems="center" py={2} mb={1}>
					<Grid item xs={2}>
						<Button
							size="small"
							variant="contained"
							style={{ minWidth: '130px' }}
							onClick={() => {
								setProductModal(true);
							}}
						>
							신규등록
						</Button>
					</Grid>
				</Grid>
			)}
			{/* 생산 서브 네비 */}
			{location.pathname.includes('product/') && (
				<Grid container spacing={1} alignItems="center" py={2} mb={1}>
					<Grid item xs={2} />
					<SubNavList navList={productNav} />
					<Grid item xs={1} />
					<Grid item xs={2}>
						<Button variant="contained" fullWidth>
							Color
						</Button>
					</Grid>
					<Grid item xs={2}>
						<SearchBar />
					</Grid>
				</Grid>
			)}
			{/* 설계 서브 네비 */}
			{location.pathname.includes('design') && (
				<Grid container spacing={1} py={2} mb={1}>
					<Grid item xs={8} />
					<Grid item xs={2}>
						<Button variant="contained" fullWidth>
							선택 설계건 다운로드
						</Button>
					</Grid>
					<Grid item xs={2}>
						<Button variant="contained" fullWidth>
							선택 설계건 업로드
						</Button>
					</Grid>
				</Grid>
			)}
			{/* 주문 서브 네비 */}
			{location.pathname.includes('order') && (
				<Grid container spacing={1} py={2} mb={1}>
					<Grid item xs={4} />
					<SubNavList navList={orderNav} />
				</Grid>
			)}
			{/* 권한 없음 스케줄러 */}
			{location.pathname === '/total-schedule' && (
				<Grid container spacing={1} py={2} mb={1}>
					<Grid item xs={10} />
					<Grid item xs={2}>
						<ReserveStore />
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default SubNav;
