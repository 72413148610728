import { GridColDef } from '@mui/x-data-grid-pro';
import { changeValueToLabel, makePhoneNumber } from '../util/ReplaceValue';

const CustomerCol = (): GridColDef[] => {
	return [
		{
			field: 'name',
			headerName: '이름',
			flex: 1,
		},
		{
			field: 'gender',
			headerName: '성별',
			flex: 1,
			renderCell: (params) => changeValueToLabel(params.value, 'gender')?.label,
		},
		{
			field: 'phone',
			headerName: '전화번호',
			flex: 1,
			renderCell: (params) => makePhoneNumber(params.value as string),
		},
		{
			field: 'address',
			headerName: '주소',
			flex: 3,
			valueGetter: (params) => {
				const { extraAddress, detailAddress, address } = params.row;
				if (address && extraAddress && detailAddress) {
					return `${address} (${extraAddress}) ${detailAddress}`;
				}
				return address;
			},
		},
		{
			field: 'note',
			headerName: '고객비고',
			flex: 2,
		},
		{
			field: 'care',
			headerName: '고객케어',
			flex: 2,
		},
		{
			field: 'rcgPath',
			headerName: '인지경로',
			flex: 1,
			renderCell: (params) => changeValueToLabel(params.value, 'rcgPath')?.label,
		},
	];
};

export default CustomerCol;
