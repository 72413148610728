import { API } from 'aws-amplify';
import { ReservationResponse, ReserveType, UserData } from '../util/Interface';
import * as mutations from '../graphql/mutations';
import { toServerHandleCancelReserve } from '../util/ReplaceValue';
import ErrorCheck from '../util/ErrorCheck';

type updateReserveQuery = {
	data: {
		updateReservation: ReservationResponse;
	};
};

const handleCancelReserve = async (values: ReserveType, userInfo: UserData | null, storeId: string) => {
	try {
		const staffId: string = userInfo?.id || '';
		if (values.id) {
			const reserve = (await API.graphql({
				query: mutations.updateReservation,
				variables: {
					input: toServerHandleCancelReserve(values, storeId, staffId, values?.customerId || ''),
				},
			})) as updateReserveQuery;
			return reserve.data.updateReservation;
		}
		return null;
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default handleCancelReserve;
