import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGridPro, GridRowModel } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import SubNav from '../../components/SubNav';
import koText from '../../util/localizationKR';
import CustomToolbar from '../../components/CustomToolbar';
import { ReserveType } from '../../util/Interface';
import { openReserveState } from '../../atom/Atom';
import { loadingState, loginUser, reserveDateRange, reserveDetail, searchValue } from '../../atom';
import reserveTotal from '../../atom/reserveTotal';
import fetchReserveByRange from '../../query/fetchReserveByRange';
import { fetchUser } from '../../selector';
import NoDataOverlay from '../../components/NoDataOverlay';
import { fetchReserve } from '../../query';
import ReserveTotalCol from '../../columns/ReserveTotalCol';
import ReserveModal from '../../components/modal/ReserveModal';
import ErrorCheck from '../../util/ErrorCheck';
import * as subscriptions from '../../graphql/subscriptions';
import { findUrlField } from '../../util/ReplaceValue';

function ReserveTotal() {
	const [reserve, setReserve] = useRecoilState(reserveTotal);
	const dateRange = useRecoilValue(reserveDateRange);
	const setOpen = useSetRecoilState(openReserveState);
	const setScheduleDetail = useSetRecoilState(reserveDetail);
	const userInfo = useRecoilValue(fetchUser);
	const searchData = useRecoilValue(searchValue);
	const [loading, setLoading] = useRecoilState(loadingState);
	const user = useRecoilValue(loginUser);
	const [token, setToken] = useState<string>('');

	const storeId: string | undefined = useMemo(() => userInfo?.storeId, [userInfo?.storeId]);

	const handleOnRowsScrollEnd = () => {
		if (reserve.length >= 100 && storeId && !dateRange[0] && !dateRange[1] && searchData === '') {
			fetchReserve({ storeId, check: token }).then((value) => {
				if (value) {
					setToken(value.token);
					setReserve([...reserve, ...value.reserve]);
				}
			});
		}
	};
	const getReserveDate = useCallback(() => {
		if (storeId) {
			const check = '';
			setLoading(true);
			fetchReserve({ storeId, check }).then((value) => {
				setReserve(value?.reserve || []);
				if (value?.token) {
					setToken(value.token);
				}
				setLoading(false);
			});
		}
	}, [setLoading, setReserve, storeId]);

	const onCreateReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({
				next: () => getReserveDate(),
				error: (err: any) => {
					if (user) {
						ErrorCheck(err);
						window.location.reload();
					}
				},
			}),
		[getReserveDate, storeId, user]
	);

	const onUpdateReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({ next: () => getReserveDate() }),
		[getReserveDate, storeId]
	);

	const onDeleteReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onDeleteReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({ next: () => getReserveDate() }),
		[getReserveDate, storeId]
	);

	useEffect(() => {
		getReserveDate();
	}, [getReserveDate]);

	useEffect(() => {
		if (dateRange[0] && dateRange[1] && storeId) {
			setLoading(true);
			fetchReserveByRange(storeId, dateRange[0], dateRange[1])
				.then((result) => {
					setReserve(result);
					setLoading(false);
				})
				.catch((e) => ErrorCheck(e));
		}
	}, [dateRange, setLoading, setReserve, storeId]);
	useEffect(() => {
		if (storeId) {
			const create = onCreateReserve();
			const update = onUpdateReserve();
			const remove = onDeleteReserve();
			return () => {
				create.unsubscribe();
				update.unsubscribe();
				remove.unsubscribe();
			};
		}
		return undefined;
	}, [onCreateReserve, onDeleteReserve, onUpdateReserve, storeId]);
	const location = useLocation();
	const field = findUrlField(location);
	return (
		<>
			<Box px={5}>
				<SubNav />
				<div style={{ height: '80vh', width: '100%' }}>
					<DataGridPro
						rows={reserve as GridRowModel[]}
						columns={ReserveTotalCol()}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						style={{ borderLeft: 'none', borderRight: 'none' }}
						localeText={koText}
						disableColumnMenu
						density={userInfo?.dataGridState?.[`${field}`]?.density || 'compact'}
						initialState={{
							columns: {
								columnVisibilityModel: userInfo?.dataGridState?.[`${field}`]?.columns?.columnVisibilityModel || {
									staff: false,
								},
							},
							filter: userInfo?.dataGridState?.[`${field}`]?.filter,
						}}
						disableColumnResize
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay: NoDataOverlay,
						}}
						componentsProps={{
							toolbar: { field },
						}}
						onCellClick={(params) => {
							setScheduleDetail(params.row as ReserveType);
							setOpen(true);
						}}
						hideFooter
						onRowsScrollEnd={handleOnRowsScrollEnd}
						loading={loading}
					/>
				</div>
			</Box>
			<ReserveModal />
		</>
	);
}

export default ReserveTotal;
