import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Box, Button, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Main() {
	const navigate = useNavigate();

	const handleLocation = (value: string) => {
		navigate(value);
	};

	return (
		<Container maxWidth="md">
			<Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Button fullWidth size="large" variant="contained" onClick={() => handleLocation('/store/reserve')}>
							안경사
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button fullWidth size="large" variant="contained" onClick={() => handleLocation('/total-schedule')}>
							예약현황
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button fullWidth size="large" variant="contained" onClick={() => handleLocation('/test')}>
							테스트
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button fullWidth size="large" variant="contained" onClick={() => handleLocation('/admin/reserve')}>
							관리자
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}

export default withAuthenticator(Main);
