import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { NestArrayProps } from '../../util/Interface';

function NestTriangleArray({ nestIndex }: NestArrayProps) {
	const { control, setValue } = useFormContext();
	const { fields, remove, append } = useFieldArray({
		control,
		name: `lensOptionArray[${nestIndex}].triangleRange`,
	});

	const rangeValue = useWatch({ control, name: `lensOptionArray[${nestIndex}].ranges` });

	useEffect(() => {
		if (rangeValue === 'triangle') {
			append({ sphMax: '', sphMin: '', cylMax: '', cylMin: '', sphLine: '' });
			setValue(`lensOptionArray[${nestIndex}].ranges`, '');
		}
	}, [append, nestIndex, rangeValue, setValue]);

	return (
		<>
			{fields.map((item, index) => {
				return (
					<Grid container spacing={2} pt={2} key={item.id}>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].triangleRange[${index}].sphMin`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].triangleRange[${index}].sphMin`}
										label="SPH 최소"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].triangleRange[${index}].sphMax`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].triangleRange[${index}].sphMax`}
										label="SPH 최대"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].triangleRange[${index}].cylMin`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].triangleRange[${index}].cylMin`}
										label="CYL 최소"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].triangleRange[${index}].cylMax`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].triangleRange[${index}].cylMax`}
										label="CYL 최대"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].triangleRange[${index}].sphLine`}
								render={({ field }) => (
									<FormControlLabel control={<Checkbox {...field} size="small" />} label="SPH 라인" />
								)}
							/>
						</Grid>
						<Grid item>
							<Button onClick={() => remove(index)}>
								<DeleteIcon />
							</Button>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
}

export default NestTriangleArray;
