import React, { Fragment } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from '@mui/material';
import { InputType } from '../../util/Interface';
import SurveyRadio from '../SurveyRadio';

function RadioInput({ field, label, options }: InputType) {
	const { control, resetField } = useFormContext();

	const generateRadioOptions = () => {
		return options?.map((singleOption) => (
			<Fragment key={singleOption.value}>
				<FormControlLabel
					disableTypography
					value={singleOption.value}
					sx={{ marginRight: 0 }}
					label=""
					control={<SurveyRadio label={singleOption.label} />}
				/>
			</Fragment>
		));
	};

	if (!field) {
		return null;
	}
	return (
		<FormControl component="fieldset">
			<FormLabel component="legend">{label}</FormLabel>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value } }) => (
					<RadioGroup
						row
						value={value || ''}
						onChange={(e) => {
							if (field === 'first') {
								resetField('ad');
								resetField('review');
							}
							onChange(e);
						}}
					>
						{generateRadioOptions()}
					</RadioGroup>
				)}
			/>
		</FormControl>
	);
}

export default RadioInput;
