import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridRowModel, gridClasses } from '@mui/x-data-grid-pro';
import 'moment/locale/ko';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { API } from 'aws-amplify';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import SubNav from '../../components/SubNav';
import koText from '../../util/localizationKR';
import ReserveDayCol from '../../columns/ReserveDayCol';
import { openReserveState } from '../../atom/Atom';
import { fetchUser } from '../../selector';
import { ReservationResponse, ReserveType } from '../../util/Interface';
import { loginUser, reserveDetail, selectedDate, dayReserveState } from '../../atom';
import CustomToolbar from '../../components/CustomToolbar';
import NoDataOverlay from '../../components/NoDataOverlay';
import * as subscriptions from '../../graphql/subscriptions';
import ReserveModal from '../../components/modal/ReserveModal';
import fetchDayStoreReserve from '../../query/fetchDayStoreReserve';
import ErrorCheck from '../../util/ErrorCheck';
import { convertReserve, findUrlField, reserveTimeSequenceSort } from '../../util/ReplaceValue';

const ReserveDataGridPro = styled(DataGridPro)(({ theme }) => ({
	[`& .${gridClasses.row}.past`]: {
		backgroundColor: theme.palette.grey[100],
		'&:hover, &.Mui-hovered': {
			backgroundColor: theme.palette.grey[200],
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
		'&.Mui-selected': {
			backgroundColor: theme.palette.grey[200],
			'&:hover, &.Mui-hovered': {
				backgroundColor: theme.palette.grey[200],
				'@media (hover: none)': {
					backgroundColor: theme.palette.grey[200],
				},
			},
		},
	},
}));

function Reserve() {
	const setOpen = useSetRecoilState(openReserveState);
	const setScheduleDetail = useSetRecoilState(reserveDetail);
	const [reservations, setReservations] = useRecoilState(dayReserveState);
	const userInfo = useRecoilValue(fetchUser);
	const storeId = userInfo?.storeId || '';
	const date = useRecoilValue(selectedDate);
	const user = useRecoilValue(loginUser);

	useEffect(() => {
		if (storeId !== '') {
			fetchDayStoreReserve(date, storeId as string)
				.then((res) => {
					if (res === null) {
						setReservations([]);
					} else {
						setReservations(res);
					}
				})
				.catch((e) => {
					ErrorCheck(e);
				});
		}
	}, [date, setReservations, storeId]);

	const onCreateReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateReservationByDay,
					variables: {
						storeId,
						reserveYMD: moment(date).format('yyyy-MM-DD'),
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onCreateReservationByDay as ReservationResponse;
					// attention 제거, sort 시간순서에 따라서 정렬하도록!
					if (item.reserveType !== 'attention') {
						const newArray = [...reservations];
						const reserve = convertReserve([item]);
						const newReserveList = reserveTimeSequenceSort([...newArray, ...reserve]);
						setReservations([...newReserveList]);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
					if (user) {
						window.location.reload();
					}
				},
			}),
		[date, reservations, setReservations, storeId, user]
	);

	const onUpdateReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateReservationByDay,
					variables: {
						storeId,
						reserveYMD: moment(date).format('yyyy-MM-DD'),
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateReservationByDay as ReservationResponse;
					let newArray: ReserveType[] = [...reservations];
					const index = newArray.findIndex((reserve) => reserve.id === item.id);
					const reserve = convertReserve([item])[0];
					newArray[index] = reserve;
					newArray = reserveTimeSequenceSort(newArray);
					setReservations([...newArray]);
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[date, reservations, setReservations, storeId]
	);

	const onDeleteReserve = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onDeleteReservationByDay,
					variables: {
						storeId,
						reserveYMD: moment(date).format('yyyy-MM-DD'),
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onDeleteReservationByDay as ReservationResponse;
					const newArray = [...reservations];
					const index = newArray.findIndex((reserve) => reserve.id === item.id);
					if (index > -1) newArray.splice(index, 1);
					setReservations(newArray);
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[date, reservations, setReservations, storeId]
	);

	useEffect(() => {
		if (storeId) {
			const create = onCreateReserve();
			const update = onUpdateReserve();
			const remove = onDeleteReserve();
			return () => {
				create.unsubscribe();
				update.unsubscribe();
				remove.unsubscribe();
			};
		}
		return undefined;
	}, [storeId, onCreateReserve, onUpdateReserve, onDeleteReserve]);
	const location = useLocation();
	const field = findUrlField(location);

	if (!userInfo) {
		return null;
	}
	return (
		<>
			<Box px={5}>
				<SubNav />
				<div style={{ height: '80vh', width: '100%' }}>
					<ReserveDataGridPro
						rows={reservations as GridRowModel[]}
						columns={ReserveDayCol()}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						style={{ borderLeft: 'none', borderRight: 'none' }}
						localeText={koText}
						disableColumnMenu
						density={userInfo.dataGridState?.[`${field}`]?.density || 'compact'}
						initialState={{
							columns: {
								...userInfo.dataGridState?.[`${field}`]?.columns,
								columnVisibilityModel: userInfo.dataGridState?.[`${field}`]?.columns?.columnVisibilityModel || {
									staff: false,
								},
							},
							filter: userInfo.dataGridState?.[`${field}`]?.filter,
						}}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay: NoDataOverlay,
						}}
						componentsProps={{
							toolbar: { field },
						}}
						onCellClick={(params) => {
							setScheduleDetail(params.row as ReserveType);
							setOpen(true);
						}}
						getRowClassName={(params) => {
							return moment(params.row.reserveDate).fromNow().includes('전') ? 'past' : '';
						}}
						hideFooter
					/>
				</div>
			</Box>
			<ReserveModal />
		</>
	);
}

export default Reserve;
