import { API } from 'aws-amplify';

import * as mutations from '../graphql/mutations';
import { ReservationResponse, ReserveType, UserData } from '../util/Interface';
import createCustomer from './createCustomer';
import { toServerReserve } from '../util/ReplaceValue';
import ErrorCheck from '../util/ErrorCheck';

export interface ToServerReserve {
	id?: string;
	storeId: string;
	staffId: string;
	customerId: string;
	reserveType: string;
	reserveDate: string;
	reserveMedia?: string;
	reserveYM: string; // localString
	reserveYMD: string; // localString
	name?: string;
	phone?: string;
	person?: number;
	cost?: number;
	cancel?: boolean;
	referrer?: string;
	channel?: string;
	detail?: string;
	note?: string;
}
export type createReserveQuery = {
	data: {
		createReservation: ReservationResponse;
	};
};

const createReserve = async (values: ReserveType, userInfo: UserData | null, storeId: string) => {
	try {
		const staffId: string = userInfo?.id || '';
		if (!values.customerId) {
			createCustomer(
				{
					name: values.name,
					phone: values.phone,
					preferContact: values.preferContact,
				},
				userInfo
			)
				.then(async (customer) => {
					const reserve = (await API.graphql({
						query: mutations.createReservation,
						variables: {
							input: toServerReserve(values, storeId, staffId, customer?.id || ''),
						},
					})) as createReserveQuery;
					return reserve.data.createReservation;
				})
				.catch((e) => {
					ErrorCheck(e);
					return null;
				});
			return null;
		}
		const reserve = (await API.graphql({
			query: mutations.createReservation,
			variables: {
				input: toServerReserve(values, storeId, staffId, values.customerId),
			},
		})) as createReserveQuery;
		return reserve.data.createReservation;
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default createReserve;
