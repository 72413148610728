import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { fetchReserveBySearchQuery } from '../util/Interface';
import { convertReserve, makePhoneNumber } from '../util/ReplaceValue';
import ErrorCheck from '../util/ErrorCheck';

interface FetchReserveBySearchProps {
	searchValue: string;
	fields?: string;
}

const fetchReserveBySearch = async ({ searchValue, fields }: FetchReserveBySearchProps) => {
	try {
		let search = '';
		if (/^[0-9]+$/g.test(searchValue)) {
			search = makePhoneNumber(searchValue) || '';
		} else {
			search = searchValue;
		}
		const data = (await API.graphql({
			query: queries.searchReservation,
			variables: {
				search,
				fields,
			},
		})) as fetchReserveBySearchQuery;
		return convertReserve(data.data.searchReservation.items);
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default fetchReserveBySearch;
