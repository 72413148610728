import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { InputType } from '../../util/Interface';

function LongTextInput({ field, label, grid }: InputType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid, isTouched } }) => (
					<TextField
						name={field}
						onChange={onChange}
						value={value || ''}
						label={label}
						size="small"
						inputRef={ref}
						onBlur={onBlur}
						InputLabelProps={{ style: { fontSize: 14 } }}
						fullWidth
						multiline
						rows={10}
						error={(invalid || isTouched) && Boolean(errors[`${field}`] || false)}
						helperText={(invalid || isTouched) && errors[`${field}`]?.message}
					/>
				)}
			/>
		</Grid>
	);
}

export default LongTextInput;
