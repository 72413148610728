import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useStyles } from './InfoAgree';

function AdAgree() {
	const classes = useStyles();
	const titles = [
		{ id: 1, value: '수집목적' },
		{ id: 2, value: '개인정보 항목' },
		{ id: 3, value: '전송방법' },
		{ id: 4, value: '보유기간' },
	];

	const contents = [
		{ id: 1, value: '고객공통' },
		{ id: 2, value: '고객명, 생년월일, 휴대전화번호,\ne-Mail 주소' },
		{ id: 4, value: '문자수신, 메일링 (뉴스레터) 서비스' },
		{ id: 5, value: '고객 개인정보 동의 철회시까지' },
	];

	return (
		<Box>
			<Typography fontWeight="bold" mb={1}>
				3. 홍보성 정보에 대한 수신동의
			</Typography>
			<Typography className={classes.subTitle} mb={1} fontWeight="bold">
				홍보성 정보 수신동의
			</Typography>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow className={classes.tableTitle}>
							{titles.map((obj) => (
								<TableCell key={obj.id} width="25%">
									<Typography fontWeight="bold">{obj.value}</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							{contents.map((row, index) => {
								const { id, value } = row;
								return (
									<TableCell
										key={id}
										className={`${index === 0 && classes.leftContents} ${classes.tableContents} ${
											index === 3 && classes.rightContents
										} ${classes.bottomContents}`}
									>
										<Typography className={classes.contentText} fontSize={15} py={1}>
											{value}
										</Typography>
									</TableCell>
								);
							})}
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Typography className={classes.caption} mt={1} fontSize={14}>
				* 귀하는 개인정보의 선택적인 수집, 사용, 제공 및 정보 전송에 대한 동의를 거부할 수 있습니다. 다만 동의하지 않을
				경우 관련 편의제공(이벤트, 경품제공 등) 안내 등 이용 목적에 따른 혜택에 제한이 있을 수 있습니다. 동의한 경우에도
				귀하는 동의를 철회하거나 마케팅 목적으로 귀하에게 연락하는 것을 중지하도록 요청할 수 있습니다.
			</Typography>
		</Box>
	);
}

export default AdAgree;
