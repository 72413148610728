import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import SubNav from '../../components/SubNav';
import koText from '../../util/localizationKR';
import CustomToolbar from '../../components/CustomToolbar';
import NoDataOverlay from '../../components/NoDataOverlay';
import StoreCol from '../../columns/admin/StoreCol';
import { storeListState, storeModalState, storeState } from '../../atom';
import StoreModal from '../../components/modal/StoreModal';
import { fetchUser } from '../../selector';
import { findUrlField } from '../../util/ReplaceValue';

function StoreManager() {
	const storeList = useRecoilValue(storeListState);
	const setStore = useSetRecoilState(storeState);
	const setStoreModal = useSetRecoilState(storeModalState);

	const userInfo = useRecoilValue(fetchUser);

	const location = useLocation();
	const field = findUrlField(location);

	if (!userInfo) {
		return null;
	}

	return (
		<>
			<Box px={5}>
				<SubNav />
				<div style={{ height: '80vh', width: '100%' }}>
					<DataGridPro
						rows={storeList || []}
						columns={StoreCol()}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						style={{ borderLeft: 'none', borderRight: 'none' }}
						localeText={koText}
						disableColumnMenu
						density={userInfo.dataGridState?.[`${field}`]?.density || 'standard'}
						initialState={{
							columns: {
								...userInfo.dataGridState?.[`${field}`]?.columns,
								columnVisibilityModel: userInfo.dataGridState?.[`${field}`]?.columns?.columnVisibilityModel,
							},
							filter: userInfo.dataGridState?.[`${field}`]?.filter,
						}}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay: NoDataOverlay,
						}}
						componentsProps={{
							toolbar: { field },
						}}
						onCellClick={(params) => {
							setStore(params.row);
							setStoreModal(true);
						}}
						hideFooter
					/>
				</div>
			</Box>
			<StoreModal />
		</>
	);
}

export default StoreManager;
