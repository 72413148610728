import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ko } from 'date-fns/esm/locale';
import { InputType } from '../../util/Interface';

function TimeInput({ field, label, grid }: InputType) {
	const { control } = useFormContext();
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value } }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
						<TimePicker
							value={value || ''}
							label={label}
							onChange={onChange}
							inputFormat="HH:mm"
							minutesStep={10}
							renderInput={(props) => (
								<TextField {...props} size="small" fullWidth sx={{ button: { padding: '4px' } }} />
							)}
							// OpenPickerButtonProps={{ sx: { '& *:focus': { outline: 'none' } } }}
						/>
					</LocalizationProvider>
				)}
			/>
		</Grid>
	);
}

export default TimeInput;
