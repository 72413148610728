import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, MenuItem, TextField } from '@mui/material';
import { CustomFieldType } from '../../util/Interface';

function MutateSingleSelect({ field, label, filter }: CustomFieldType) {
	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const makeOption = () => {
		if (filter) {
			const checkedFilter = filter.filter((filterObj) => {
				return filterObj.condition.find((conditionObj) => {
					return watch(conditionObj.field) === conditionObj.value;
				});
			});
			if (checkedFilter.length > 0) {
				return checkedFilter[0].option.map((obj) => {
					return (
						<MenuItem key={obj.value} value={obj.value}>
							{obj.label}
						</MenuItem>
					);
				});
			}
			return <MenuItem value="">미선택</MenuItem>;
		}
		return <MenuItem value="">미선택</MenuItem>;
	};
	return (
		<Grid item xs={2}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
					<TextField
						name={field}
						onChange={onChange}
						value={value || ''}
						label={label}
						inputRef={ref}
						select
						size="small"
						fullWidth
						error={invalid && Boolean(errors[`${field}`] || false)}
						helperText={invalid && errors[`${field}`]?.message}
					>
						{makeOption()}
					</TextField>
				)}
			/>
		</Grid>
	);
}

export default MutateSingleSelect;
