import React, { useEffect } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router';
import { ReserveType } from '../../util/Interface';
import { openReserveState } from '../../atom/Atom';
import { attArray, reserveInputs } from '../../util/Array';
import { confirmModal, reserveDetail, selectedStore } from '../../atom';
import { reserveValidation } from '../../util/Validation';
import { createReserve, handleCancelReserve, updateReserve } from '../../query';
import { fetchUser } from '../../selector';
import DeleteModal from './DeleteModal';
import ErrorCheck from '../../util/ErrorCheck';
import {
	TextInput,
	SingleSelectInput,
	AutoCompleteInput,
	LoadAutoCompleteInput,
	NumberInput,
	DateTimeInput,
} from '../form';

function ReserveModal() {
	const [openModal, setOpenModal] = useRecoilState(openReserveState);
	const [defaultValues, setDefaultValues] = useRecoilState(reserveDetail);
	const resetDefaultValues = useResetRecoilState(reserveDetail);
	const setConfirm = useSetRecoilState(confirmModal);
	const userInfo = useRecoilValue(fetchUser);
	const adminStoreId = useRecoilValue(selectedStore);
	const location = useLocation();
	const methods = useForm<ReserveType>({
		defaultValues,
		mode: 'all',
		reValidateMode: 'onChange',
		shouldFocusError: true,
		resolver: yupResolver(reserveValidation),
	});

	const { reset, handleSubmit, watch } = methods;

	// 리셋으로 랜더링 한번더
	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	const onSubmit: SubmitHandler<ReserveType> = (data) => {
		const { id, reserveType, reserveDate } = data;
		if (userInfo) {
			if (!id && reserveType !== 'attention') {
				if (location.pathname === '/admin/reserve') {
					createReserve(data, userInfo, adminStoreId).then();
				} else {
					createReserve(data, userInfo, userInfo.storeId).then();
				}
			} else if (id && reserveType !== 'attention') {
				updateReserve(data, userInfo).then();
			} else if (!id && reserveType === 'attention') {
				if (location.pathname === '/admin/reserve') {
					createReserve(
						{ reserveType, name: '워크인', reserveDate, storeId: adminStoreId },
						userInfo,
						adminStoreId
					).then();
				} else {
					createReserve(
						{ reserveType, name: '워크인', reserveDate, storeId: userInfo.storeId },
						userInfo,
						userInfo.storeId
					).then();
				}
			}
		}
		reset();
		resetDefaultValues();
		setOpenModal(false);
	};

	const handleClose = () => {
		reset();
		resetDefaultValues();
		setOpenModal(false);
	};

	const handleCancel = () => {
		if (userInfo) {
			const { note } = watch();
			const values = { ...defaultValues, note };
			if (location.pathname === '/admin/reserve') {
				handleCancelReserve(values, userInfo, adminStoreId)
					.then(() => {
						handleClose();
					})
					.catch((e) => ErrorCheck(e));
			} else {
				handleCancelReserve(values, userInfo, userInfo.storeId)
					.then(() => {
						handleClose();
					})
					.catch((e) => ErrorCheck(e));
			}
		}
	};

	const handleResCopy = () => {
		const copyValue = { ...defaultValues, id: '', name: defaultValues.name.concat(' 복사'), customerId: '' };
		if (userInfo) {
			if (location.pathname === '/admin/reserve') {
				createReserve(copyValue, userInfo, adminStoreId).then();
			} else {
				createReserve(copyValue, userInfo, userInfo.storeId).then();
			}
		}
		handleClose();
	};

	const handleDelete = () => {
		if (defaultValues.id) {
			setConfirm(true);
		}
	};

	return (
		<>
			<Dialog open={openModal} fullWidth maxWidth="lg">
				<DialogTitle>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Typography>{defaultValues.id ? '예약 스케줄 수정' : '예약 스케줄 추가'}</Typography>
						<Button onClick={handleClose}>
							<ClearOutlinedIcon color="action" />
						</Button>
					</Box>
				</DialogTitle>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<DialogContent>
							<Grid container spacing={2}>
								{(watch().reserveType !== 'attention' ? reserveInputs : attArray).map((input) => {
									const { options, type, field, label, grid } = input;
									switch (type) {
										case 'text':
											if (!field || !label) return null;
											return (
												<TextInput key={`reserveInput_${field}`} field={field} label={label} grid={grid} type={type} />
											);
										case 'singleSelect':
											if (!field || !label) return null;
											return (
												<SingleSelectInput
													key={`reserveInput_${field}`}
													field={field}
													label={label}
													options={options}
													grid={grid}
													type={type}
													size="small"
													fontSize={14}
												/>
											);
										case 'autocomplete':
											if (!field || !label) return null;
											return (
												<AutoCompleteInput
													key={`reserveInput_${field}`}
													field={field}
													label={label}
													grid={grid}
													type={type}
												/>
											);
										case 'loadAutocomplete':
											if (!field || !label) return null;
											return (
												<LoadAutoCompleteInput
													key={`reserveInput_${field}`}
													field={field}
													label={label}
													grid={grid}
													type={type}
												/>
											);
										case 'number':
											if (!field || !label) return null;
											return (
												<NumberInput
													key={`reserveInput_${field}`}
													field={field}
													label={label}
													grid={grid}
													type={type}
												/>
											);
										case 'datetime':
											if (!field || !label) return null;
											return (
												<DateTimeInput
													key={`reserveInput_${field}`}
													field={field}
													label={label}
													grid={grid}
													type={type}
												/>
											);
										default:
											return null;
									}
								})}
							</Grid>
						</DialogContent>
						<DialogActions>
							{defaultValues.id && (
								<Button variant="contained" onClick={handleResCopy} color="primary">
									예약복사
								</Button>
							)}
							{defaultValues.id && !defaultValues.cancel && (
								<Button variant="contained" onClick={handleCancel} color="primary">
									예약취소
								</Button>
							)}
							{defaultValues.id && defaultValues.cancel && (
								<Button variant="contained" onClick={handleCancel} color="primary">
									예약복구
								</Button>
							)}
							{defaultValues.id && (
								<Button variant="contained" onClick={handleDelete} color="primary">
									삭제
								</Button>
							)}
							<Button variant="contained" type="submit" color="primary">
								{defaultValues.id ? '수정' : '추가'}
							</Button>
						</DialogActions>
					</form>
				</FormProvider>
			</Dialog>
			<DeleteModal purpose="예약" id={defaultValues.id || ''} close={handleClose} />
		</>
	);
}

export default ReserveModal;
