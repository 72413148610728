import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRecoilState } from 'recoil';
import SignaturePad from 'react-signature-canvas';
import { openSignatureState } from '../../atom/Atom';

const useStyles = makeStyles(() => ({
	canvas: {
		background: '#FAFBFC',
		border: '1px solid #D4E1F2',
		borderRadius: '3px',
		width: '100%',
		height: '200px',
	},
}));

interface SignatureProps {
	setValue: (value: File) => void;
}

function Signature(props: SignatureProps) {
	const { setValue } = props;
	const classes = useStyles();

	const [open, setOpen] = useRecoilState(openSignatureState);
	const [imageURL, setImageURL] = useState<string | undefined>(undefined);
	const [pointsArray, setPointsArray] = useState(null);

	const signCanvas = useRef({}) as React.MutableRefObject<any>;

	const handleOpen = () => {
		setOpen(true);
		setTimeout(() => {
			if (pointsArray) {
				signCanvas.current.fromData(pointsArray);
			}
		});
	};
	const handleClose = () => {
		setOpen(false);
		setPointsArray(signCanvas.current.toData());
	};

	const handleSave = () => {
		setImageURL(signCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
	};

	const handleModalSave = () => {
		handleSave();
		handleClose();
	};

	const handleCancel = () => {
		setImageURL(undefined);
		signCanvas.current.clear();
		handleClose();
	};

	useEffect(() => {
		if (imageURL) {
			const blobBin = atob(imageURL?.split(',')[1]);
			const array = [];
			for (let i = 0; i < blobBin.length; i += 1) {
				array.push(blobBin.charCodeAt(i));
			}
			const file = new Blob([new Uint8Array(array)], { type: 'image/png' });
			const formData = new File([file], 'img', { type: 'image/png' });
			setValue(formData);
		}
	}, [imageURL, setValue]);

	return (
		<Box display="flex" alignItems="center">
			{imageURL ? (
				<Button onClick={handleOpen}>
					<img
						src={imageURL}
						alt="my signature"
						style={{
							display: 'block',
							width: '9.6vw',
							height: '4.5vh',
						}}
					/>
				</Button>
			) : (
				<Button onClick={handleOpen} fullWidth>
					서 명 란
				</Button>
			)}
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
				<DialogTitle>서 명</DialogTitle>
				<SignaturePad
					ref={signCanvas}
					canvasProps={{
						className: classes.canvas,
					}}
				/>
				<DialogActions>
					<Button onClick={handleCancel} color="primary">
						취소
					</Button>
					<Button onClick={handleModalSave} color="primary">
						저장
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

export default Signature;
