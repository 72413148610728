import { API } from 'aws-amplify';
import moment from 'moment';
import ErrorCheck from '../util/ErrorCheck';
import * as queries from '../graphql/queries';
import { fetchDayReservationQuery, ReserveType } from '../util/Interface';
import { convertReserve, reserveTimeSequenceSort } from '../util/ReplaceValue';

const fetchDayStoreReserve = async (date: Date, store: string) => {
	try {
		let token = '';
		const result: ReserveType[] = [];
		while (token != null) {
			const data = (await API.graphql({
				query: queries.listReservationByDayWithStore,
				variables: {
					reserveYMD: moment(date).format('yyyy-MM-DD'),
					storeId: store,
					limit: 100,
					nextToken: token === '' ? null : token,
				},
			})) as fetchDayReservationQuery;
			token = data.data.listReservationByDayWithStore.nextToken;
			const value: ReserveType[] = reserveTimeSequenceSort(
				convertReserve(data.data.listReservationByDayWithStore.items)
			)
				// 관심/문의(attention) 제거 filtering
				.filter((reserve) => reserve.reserveType !== 'attention');
			result.push(...value);
			if (token === null) {
				break;
			}
		}
		return result;
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};
export default fetchDayStoreReserve;
