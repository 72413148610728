import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useRecoilValue } from 'recoil';
import NestRectangleArray from './NestRectangleArray';
import NestTriangleArray from './NestTriangleArray';
import NestAdditionArray from './NestAdditionArray';
import NestPrismArray from './NestPrismArray';
import NestProgressiveArray from './NestProgressiveArray';
import { LensOptionArrayType } from '../../util/Interface';
import { productInfoListState } from '../../atom';

function LensOptionArrayInput() {
	const { control, setValue, getValues, watch } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'lensOptionArray',
	});
	const lensOption = useWatch({ control, name: 'lensOptions' }) as string[];
	const productInfoList = useRecoilValue(productInfoListState);
	const lensOptionArray = useWatch({ control, name: 'lensOptionArray' }) as LensOptionArrayType[] | undefined;
	useEffect(() => {
		const checkIdArray = lensOptionArray?.map((optionObj) => {
			return optionObj.oid;
		});

		lensOption?.forEach((optionId: string) => {
			if (!checkIdArray?.includes(optionId)) {
				append({ oid: optionId, price: '', ranges: '', pre: [], added: '', rx: false });
			}
		});
	}, [append, lensOption, remove]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const checkIdArray = lensOptionArray?.map((optionObj) => {
			return optionObj.oid;
		});
		checkIdArray?.forEach((optionId, index) => {
			if (!lensOption?.includes(optionId)) {
				remove(index);
			}
		});
	}, [lensOption, lensOptionArray, remove]);

	const lensRangeOption = [
		{
			value: 'rectangle',
			label: 'SPH-CYL 직사각형',
		},
		{
			value: 'triangle',
			label: 'SPH-CYL 삼각형',
		},
		{
			value: 'addition',
			label: '가입도',
		},
		{
			value: 'progressive',
			label: '누진대',
		},
		{
			value: 'prism',
			label: '프리즘',
		},
	];

	const makeOption = () => {
		return lensRangeOption.map((obj) => {
			return (
				<MenuItem key={obj.value} value={obj.value}>
					{obj.label}
				</MenuItem>
			);
		});
	};

	const makePreOption = (value: any) => {
		const option = productInfoList
			.filter((product) => product.category === 'lensOption')
			.filter((product) => lensOption.includes(product.id))
			.map((product) => {
				const { id, label } = product;
				return {
					label,
					value: id,
				};
			});
		return option?.map((obj) => {
			return (
				<MenuItem key={obj.value} value={obj.value}>
					<Checkbox checked={value?.indexOf(obj.value) > -1} />
					<ListItemText primary={obj.label} />
				</MenuItem>
			);
		});
	};

	const makeBaseColorOption = (value: any) => {
		return productInfoList
			.filter((product) => product.material === 'lensBase')
			.map((obj) => {
				return (
					<MenuItem key={obj.id} value={obj.id}>
						<Checkbox checked={value?.indexOf(obj.id) > -1} />
						<ListItemText primary={obj.label} />
					</MenuItem>
				);
			});
	};
	const makeMirColorOption = (value: any) => {
		return productInfoList
			.filter((product) => product.material === 'lensMir')
			.map((obj) => {
				return (
					<MenuItem key={obj.id} value={obj.id}>
						<Checkbox checked={value?.indexOf(obj.id) > -1} />
						<ListItemText primary={obj.label} />
					</MenuItem>
				);
			});
	};

	const removeArray = (index: number, id: string) => {
		const newLensOptions = (getValues('lensOptions') as string[])?.filter((option) => option !== id);
		setValue('lensOptions', newLensOptions);
	};

	return (
		<>
			{fields.map((item, index) => {
				return (
					<Grid container key={item.id} mt={2} ml={2} px={2} py={2} style={{ border: 'solid 1px #000000' }}>
						<Grid item xs={12}>
							<Box display="flex" justifyContent="space-between">
								<Typography>{`${
									productInfoList?.find((product) => product.id === watch('lensOptions')[index])?.label
								}`}</Typography>
								<Button onClick={() => removeArray(index, getValues(`lensOptionArray[${index}].oid`))}>
									<ClearOutlinedIcon color="action" />
								</Button>
							</Box>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<Controller
									render={({ field }) => (
										<TextField
											{...field}
											name={`lensOptionArray[${index}].price`}
											label="가격"
											size="small"
											fullWidth
										/>
									)}
									name={`lensOptionArray[${index}].price`}
								/>
							</Grid>
							<Grid item xs={2}>
								<Controller
									name={`lensOptionArray[${index}].added`}
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											name={`lensOptionArray[${index}].added`}
											label="추가금"
											size="small"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={2}>
								<Controller
									name={`lensOptionArray[${index}].pre`}
									control={control}
									render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
										<FormControl fullWidth size="small">
											<InputLabel>선행조건</InputLabel>
											<Select
												name={`lensOptionArray[${index}].pre`}
												onChange={onChange}
												value={value || []}
												label="선행조건"
												inputRef={ref}
												multiple
												renderValue={(selected) => {
													return productInfoList
														?.filter((product) => selected.includes(product.id))
														.map((product) => product.label)
														.join(', ');
												}}
											>
												{makePreOption(value)}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={2}>
								<Grid display="flex" alignItems="center">
									<Controller
										name={`lensOptionArray[${index}].rx`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<FormControlLabel
												control={<Checkbox size="small" checked={value} onChange={onChange} />}
												label="rx 유무"
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={2}>
								<Grid display="flex" alignItems="center">
									<Controller
										name={`lensOptionArray[${index}].baseColor`}
										control={control}
										render={({ field: { onChange, value, ref } }) => {
											return (
												<FormControl fullWidth size="small">
													<InputLabel>베이스컬러</InputLabel>
													<Select
														name={`lensOptionArray[${index}].baseColor`}
														onChange={onChange}
														value={value || []}
														label="베이스컬러"
														inputRef={ref}
														multiple
														renderValue={(selected) => {
															return productInfoList
																.filter((product) => product.material === 'lensBase')
																.filter((option) => selected.includes(option.id))
																.map((option) => option.label)
																.join(', ');
														}}
													>
														{makeBaseColorOption(value)}
													</Select>
												</FormControl>
											);
										}}
									/>
								</Grid>
							</Grid>
							<Grid item xs={2}>
								<Grid display="flex" alignItems="center">
									<Controller
										name={`lensOptionArray[${index}].mirColor`}
										control={control}
										render={({ field: { onChange, value, ref } }) => {
											return (
												<FormControl fullWidth size="small">
													<InputLabel>미러컬러</InputLabel>
													<Select
														name={`lensOptionArray[${index}].mirColor`}
														onChange={onChange}
														value={value || []}
														label="미러컬러"
														inputRef={ref}
														multiple
														renderValue={(selected) => {
															return productInfoList
																.filter((product) => product.material === 'lensMir')
																.filter((option) => selected.includes(option.id))
																.map((option) => option.label)
																.join(', ');
														}}
													>
														{makeMirColorOption(value)}
													</Select>
												</FormControl>
											);
										}}
									/>
								</Grid>
							</Grid>
							<Grid item xs={2}>
								<Controller
									name={`lensOptionArray[${index}].ranges`}
									control={control}
									render={({ field: { onChange, value, ref } }) => (
										<TextField
											name={`lensOptionArray[${index}].ranges`}
											onChange={onChange}
											value={value || ''}
											label="레인지 옵션"
											inputRef={ref}
											select
											size="small"
											fullWidth
										>
											{makeOption()}
										</TextField>
									)}
								/>
							</Grid>
						</Grid>
						<NestRectangleArray nestIndex={index} />
						<NestTriangleArray nestIndex={index} />
						<NestAdditionArray nestIndex={index} />
						<NestPrismArray nestIndex={index} />
						<NestProgressiveArray nestIndex={index} />
					</Grid>
				);
			})}
		</>
	);
}

export default LensOptionArrayInput;
