import React, { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Chip, Grid, MenuItem, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { fetchUser } from '../../selector';
import { InputType } from '../../util/Interface';
import { productInfoListState, storeListState } from '../../atom';
import { openFrameState } from '../../atom/Atom';
import { makeFrameModalSingleSelectOption } from '../../util/ReplaceValue';

function SingleSelectInput({ field, label, options, grid, variant, size }: InputType) {
	const {
		control,
		getValues,
		watch,
		setValue,
		resetField,
		formState: { errors },
	} = useFormContext();

	const userInfo = useRecoilValue(fetchUser);
	const { pathname } = useLocation();
	const storeList = useRecoilValue(storeListState);
	const productList = useRecoilValue(productInfoListState);
	const openFrame = useRecoilValue(openFrameState);

	const makeOption = () => {
		if (openFrame && !options) {
			const frameModalOptions = makeFrameModalSingleSelectOption(productList, watch, field);

			if (field?.includes('Color')) {
				return frameModalOptions.map((obj) => {
					const list = obj?.colorCode;
					return (
						<MenuItem key={obj?.value} value={obj?.value}>
							<Box
								sx={{
									width: '25px',
									height: '25px',
									background: list && list.length > 1 ? `linear-gradient( to right, ${list.join(',')} )` : list,
									borderRadius: '15px',
									marginRight: '10px',
								}}
							/>
							{obj.label}
						</MenuItem>
					);
				});
			}
			if (field === 'templeLength') {
				return frameModalOptions.map((obj) => (
					<MenuItem key={obj?.value} value={obj?.value}>
						{obj?.value}
					</MenuItem>
				));
			}
			return frameModalOptions.map((obj) => (
				<MenuItem key={obj?.value} value={obj?.value}>
					<Chip label={obj.label} sx={{ backgroundColor: obj?.color }} />
				</MenuItem>
			));
		}
		if (field === 'staff') {
			if (userInfo) {
				const { coworker } = userInfo;
				return coworker.map((obj) => (
					<MenuItem key={obj.value} value={obj?.value}>
						{obj?.label}
					</MenuItem>
				));
			}
		}
		if (field === 'reserveMedia') {
			if (!getValues || getValues('reserveType') === 'attention') {
				return options
					?.filter((obj) => obj.value.includes('att'))
					.map((obj) => (
						<MenuItem key={obj?.value} value={obj?.value}>
							<Chip label={obj.label} sx={{ backgroundColor: `${obj.color}` }} />
						</MenuItem>
					));
			}
			return options
				?.filter((obj) => !obj.value.includes('att'))
				.map((obj) => (
					<MenuItem key={obj?.value} value={obj?.value}>
						<Chip label={obj.label} sx={{ backgroundColor: `${obj.color}` }} />
					</MenuItem>
				));
		}
		if (field === 'storeId') {
			return storeList?.map((store) => {
				return (
					<MenuItem key={store.id} value={store.id}>
						<Chip label={store.name} sx={{ backgroundColor: `${store?.color}` }} />
					</MenuItem>
				);
			});
		}
		const lensCoatingLabelColor = [
			{ name: 'uv', color: '#FBEBBC' },
			{ name: 'bl', color: '#D2DFFC' },
			{ name: 'nir', color: '#F9E3D7' },
			{ name: 'av', color: '#D8F6C9' },
			{ name: 'bl', color: '#D2DFFC' },
		];

		if (field === 'lensCoating') {
			const coatingList = productList
				.find((product) => product.id === watch().name)
				?.lensOptionArray?.filter((lensOption) => !lensOption.pre)
				?.map((lensOption) => {
					const item = productList.find((product) => product.id === lensOption.oid);
					const color = lensCoatingLabelColor.find((colorObj) => colorObj.name === item?.id.split('-')[1])?.color;
					return {
						label: item?.label,
						value: item?.id,
						color,
					};
				});
			return coatingList?.map((obj) => (
				<MenuItem key={obj.value} value={obj.value}>
					<Chip label={obj.label} style={{ backgroundColor: obj?.color }} />
				</MenuItem>
			));
		}
		return options?.map((obj) => (
			<MenuItem key={obj?.value} value={obj?.value}>
				<Chip label={obj.label} sx={{ backgroundColor: `${obj.color}` }} />
			</MenuItem>
		));
	};

	const makeSelectProps = (fieldName: string) => {
		if (fieldName !== 'staff') {
			return {
				renderValue: (obj: any) => {
					if (fieldName === 'storeId') return storeList?.find((opt) => opt.id === obj)?.name;
					if (fieldName === 'lensCoating') return productList.find((product) => product.id === obj)?.label;
					if (fieldName === 'templeLength')
						return productList
							.find((product) => product.id === watch().temple)
							?.templeLength?.find((templeOption) => templeOption.value === obj)?.value;
					if (openFrame && !options) {
						return productList.find((product) => product.id === obj)?.label;
					}
					return options?.find((opt) => opt.value === obj)?.label;
				},
			};
		}

		return {};
	};

	const handleChange = (
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		onChange: (...event: any[]) => void
	) => {
		if (field === 'lensCoating') {
			const price = productList
				.find((product) => product.id === watch('name'))
				?.lensOptionArray?.find((option) => option.oid === e.target.value)?.price;
			if (price) {
				setValue('price', price);
			}
			resetField('firstOption');
		}
		if (field === 'temple') {
			resetField('tip');
			resetField('templeLength');
		}

		return onChange(e);
	};

	const checkDisabled = () => {
		if (pathname === '/store/reserve' && field === 'preferContact') {
			return true;
		}
		if (field === 'staff') return true;
		return undefined;
	};

	const handleValue = (value: any) => {
		if (field === 'staff') {
			return value || userInfo?.id || '';
		}
		return value || '';
	};

	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid } }) => {
					return (
						<TextField
							name={field}
							onChange={(e) => handleChange(e, onChange)}
							value={handleValue(value)}
							label={label}
							size={size}
							variant={variant}
							select
							inputRef={ref}
							onBlur={onBlur}
							SelectProps={makeSelectProps(field)}
							fullWidth
							error={invalid && Boolean(errors[`${field}`] || false)}
							helperText={invalid && errors[`${field}`] ? errors[`${field}`]?.message : ''}
							InputProps={{ disabled: checkDisabled() }}
						>
							{makeOption() || <MenuItem />}
						</TextField>
					);
				}}
			/>
		</Grid>
	);
}

export default SingleSelectInput;
