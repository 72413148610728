import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavType } from '../util/Interface';

interface subNavListProps {
	navList: NavType[];
}

function SubNavList(props: subNavListProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const { navList } = props;
	return (
		<>
			{navList.map((nav) => {
				const { label, link } = nav;
				return (
					<Grid key={`nav_${link}`} item xs={1}>
						<Button
							onClick={() => {
								navigate(`${link}`);
							}}
						>
							<Typography color={location.pathname === link ? '#0960E3' : '#B0B0B0'} fontSize={14}>
								{label}
							</Typography>
						</Button>
					</Grid>
				);
			})}
		</>
	);
}

export default SubNavList;
