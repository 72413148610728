import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { toServerUpdateReserve } from '../util/ReplaceValue';
import updateCustomer from './updateCustomer';
import { updateReserveQuery, ReserveType, UserData } from '../util/Interface';
import ErrorCheck from '../util/ErrorCheck';

const updateReserve = async (values: ReserveType | undefined, userInfo: UserData | null) => {
	// @todo frame 어떻게 변경해야할지 확인
	try {
		if (values && userInfo) {
			const { id } = userInfo;
			updateCustomer({ id: values.customerId, name: values.name, phone: values.phone }, userInfo)
				.then(async (customer) => {
					const reserve = (await API.graphql({
						query: mutations.updateReservation,
						variables: {
							input: toServerUpdateReserve(values, customer, id),
						},
					})) as updateReserveQuery;
					return reserve.data.updateReservation;
				})
				.catch((e) => ErrorCheck(e));
		}
	} catch (e) {
		ErrorCheck(e);
	}
};

export default updateReserve;
