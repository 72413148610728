import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, FormControlLabel } from '@mui/material';
import { InputType } from '../../util/Interface';
import SurveyCheckbox from '../SurveyCheckbox';

function MultiCheckboxInput({ field, options }: InputType) {
	const { control } = useFormContext();
	if (!field) {
		return null;
	}
	return (
		<Box>
			{options?.map((option) => {
				return (
					<FormControlLabel
						control={
							<Controller
								name={field as string}
								render={({ field: { onChange, value } }) => {
									return (
										<SurveyCheckbox
											name={option.value}
											label={option.label}
											checked={((value as string[]) || undefined)?.some((check) => check === option.value) || false}
											onChange={(event, checked) => {
												if (checked) {
													if (value === undefined) {
														onChange([event.target.name]);
													} else {
														onChange([...value, event.target.name]);
													}
												} else {
													onChange(
														(value as string[]).filter((check) => {
															return check !== event.target.name;
														})
													);
												}
											}}
										/>
									);
								}}
								control={control}
							/>
						}
						sx={{ marginRight: 0 }}
						label=""
						key={option.value}
					/>
				);
			})}
		</Box>
	);
}

export default MultiCheckboxInput;
