import React from 'react';
import { Radio, RadioProps } from '@mui/material';
import CheckedSurveyChip from './CheckedSurveyChip';
import SurveyChip from './SurveyChip';

interface SurveyRadio {
	label: string;
	/* number: string;
	setNumber: (value: string) => void; */
}

function SurveyRadio(props: RadioProps & SurveyRadio) {
	const { label } = props;

	return (
		<Radio
			{...props}
			disableRipple
			checkedIcon={<CheckedSurveyChip label={label} />}
			icon={<SurveyChip label={label} />}
		/>
	);
}

export default SurveyRadio;
