import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { fetchCustomerBySearchQuery } from '../util/Interface';
import { convertCustomer, makePhoneNumber } from '../util/ReplaceValue';
import ErrorCheck from '../util/ErrorCheck';

interface FetchCustomerBySearchProps {
	searchValue: string;
	fields?: string;
}

const fetchCustomerBySearch = async ({ searchValue, fields }: FetchCustomerBySearchProps) => {
	try {
		let search = '';
		if (/^[0-9]+$/g.test(searchValue)) {
			search = makePhoneNumber(searchValue) || '';
		} else {
			search = searchValue;
		}

		const data = (await API.graphql({
			query: queries.searchCustomer,
			variables: {
				search,
				fields,
			},
		})) as fetchCustomerBySearchQuery;
		return convertCustomer(data.data.searchCustomer.items);
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default fetchCustomerBySearch;
