import React, { useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import { useFormik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ko } from 'date-fns/esm/locale';
import { frameArray, frameAsArray } from '../../util/Array';
import { frameAsDataState, openAsFrameState } from '../../atom/Atom';
import Thumbnail from '../Thumbnail';

function FrameAsModal() {
	const [openAsFrame, setOpenAsFrame] = useRecoilState(openAsFrameState);
	const [frameAsDetail] = useRecoilState(frameAsDataState);
	const [detail, setDetail] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: frameAsDetail,
		onSubmit: (values, { resetForm }) => {
			resetForm({});
		},
		enableReinitialize: true,
	});
	const { values, setFieldValue, handleSubmit, resetForm } = formik;

	const handleClose = () => {
		resetForm({});
		setOpenAsFrame(false);
	};

	const makeComma = (value: string, field: string) => {
		setFieldValue(field, value.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
	};

	const frameASContextCheck = (array: string[]) => {
		const asContext: string[] = [];
		array.forEach((value: string) => {
			switch (value) {
				case 'hingeR':
					asContext.push('힌지R');
					break;
				case 'hingeL':
					asContext.push('힌지L');
					break;
				case 'nosePadR':
					asContext.push('코패드R');
					break;
				case 'nosePadL':
					asContext.push('코패드L');
					break;
				case 'lim':
					asContext.push('림');
					break;
				case 'bridgeD':
					asContext.push('브릿지');
					break;
				case 'slide':
					asContext.push('흘러내림');
					break;
				case 'pain':
					asContext.push('통증/압박');
					break;
				case 'balance':
					asContext.push('균형');
					break;
				case 'touch':
					asContext.push('닿음');
					break;
				case 'noReason':
					asContext.push('단순변심');
					break;
				case 'etc':
					asContext.push('기타(코멘트)');
					break;
				case 'colored':
					asContext.push('착색');
					break;
				case 'assemble':
					asContext.push('조립');
					break;
				case 'burble':
					asContext.push('버블');
					break;
				case 'grind':
					asContext.push('연마');
					break;
				case 'nosePad':
					asContext.push('코패드');
					break;
				case 'temple':
					asContext.push('템플');
					break;
				case 'bridgeP':
					asContext.push('브릿지');
					break;
				case 'screw':
					asContext.push('나사/파이프');
					break;
				default:
					break;
			}
		});
		return (asContext as string[]).join(',');
	};

	return (
		<Dialog open={openAsFrame} onClose={handleClose} fullWidth maxWidth="lg">
			<form onSubmit={handleSubmit}>
				<DialogTitle>프레임 AS</DialogTitle>
				<DialogContent>
					<Box py={1}>
						<Grid container columnSpacing={1} rowSpacing={1} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleClose}>
						취소
					</Button>
					<Button variant="contained" type="submit">
						입력
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

export default FrameAsModal;
