import { atom } from 'recoil';
import { StaffType } from '../util/Interface';

export default atom<StaffType>({
	key: 'staffDetail',
	default: {
		id: '',
		name: '',
		label: '',
		labelKr: '',
		cognitoId: '',
		phone: '',
		storeId: '',
		color: '',
		role: '',
		profile: '',
		status: '',
	},
});
