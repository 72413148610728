import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { NestArrayProps } from '../../util/Interface';

function NestPrismArray({ nestIndex }: NestArrayProps) {
	const { control, setValue } = useFormContext();
	const { fields, remove, append } = useFieldArray({
		control,
		name: `lensOptionArray[${nestIndex}].prismRange`,
	});

	const rangeValue = useWatch({ control, name: `lensOptionArray[${nestIndex}].ranges` });

	useEffect(() => {
		if (rangeValue === 'prism') {
			append({ prismMax: '', prismMin: '', prismUnit: '' });
			setValue(`lensOptionArray[${nestIndex}].ranges`, '');
		}
	}, [append, nestIndex, rangeValue, setValue]);

	return (
		<>
			{fields.map((item, index) => {
				return (
					<Grid container spacing={2} pt={2} key={item.id}>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].prismRange[${index}].prismMin`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].prismRange[${index}].prismMin`}
										label="PRISM 최소"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].prismRange[${index}].prismMax`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].prismRange[${index}].prismMax`}
										label="PRISM 최대"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name={`lensOptionArray[${nestIndex}].prismRange[${index}].prismUnit`}
								render={({ field }) => (
									<TextField
										{...field}
										name={`lensOptionArray[${nestIndex}].prismRange[${index}].prismUnit`}
										label="PRISM 단위"
										size="small"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item>
							<Button onClick={() => remove(index)}>
								<DeleteIcon />
							</Button>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
}

export default NestPrismArray;
