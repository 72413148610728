import React from 'react';
import { Chip } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { fetchUser } from '../selector';
import { StaffChipProps } from '../util/Interface';

function StoreStaffChip(props: StaffChipProps) {
	const { params } = props;
	const userInfo = useRecoilValue(fetchUser);

	const staff = userInfo?.coworker.find((staffInfo) => staffInfo.value === params.value);
	if (params.row.reserveType === 'custom') {
		if (params.row.cancel) {
			return null;
		}
		return <Chip sx={{ backgroundColor: staff?.color }} label={staff?.label} />;
	}
	if (params.row.reserveType === 'as' || params.row.reserveType === 'package') {
		if (params.value === '' || params.row.cancel) {
			return null;
		}
		return <Chip sx={{ backgroundColor: staff?.color }} label={staff?.label} />;
	}
	return null;
}

export default StoreStaffChip;
