import { API } from 'aws-amplify';
import { StaffType } from '../util/Interface';
import { toServerStaff } from '../util/ReplaceValue';
import * as mutations from '../graphql/mutations';
import ErrorCheck from '../util/ErrorCheck';

const createStaff = async (staffInputs: StaffType, userId: string) => {
	const input = toServerStaff(staffInputs, userId);
	try {
		await API.graphql({
			query: mutations.createStaff,
			variables: { input },
		});
	} catch (err) {
		ErrorCheck(err);
	}
};

export default createStaff;
