import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import ErrorCheck from '../util/ErrorCheck';

const deleteProductInfo = async (id: string) => {
	try {
		await API.graphql({
			query: mutations.deleteProductInfo,
			variables: {
				input: { id },
			},
		});
	} catch (err) {
		ErrorCheck(err);
	}
};

export default deleteProductInfo;
