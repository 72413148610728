import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Badge, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Thumbnail from '../Thumbnail';
import { InputType } from '../../util/Interface';

function ImageInput({ field, label, grid }: InputType) {
	const { control } = useFormContext();

	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value } }) => {
					return (
						<Badge
							badgeContent={!value ? '' : <CancelIcon color="primary" onClick={() => onChange(null)} />}
							style={{ width: '100%', backgroundColor: 'white' }}
						>
							<label htmlFor={field} style={{ width: '100%' }}>
								<Thumbnail file={value} label={label} />
								<input
									id={field}
									type="file"
									name={`${field}`}
									hidden
									accept="image/*"
									onChange={(e) => onChange(e.currentTarget.files?.[0])}
								/>
							</label>
						</Badge>
					);
				}}
			/>
		</Grid>
	);
}

export default ImageInput;
