import React, { ChangeEvent, useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import { API, Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import { useRecoilValue } from 'recoil';
import { fetchUser } from '../selector';
import { updateStaff } from '../query';
import * as subscriptions from '../graphql/subscriptions';
import ErrorCheck from '../util/ErrorCheck';
import { loginUser } from '../atom';

function NavAvatar() {
	const userInfo = useRecoilValue(fetchUser);
	const user = useRecoilValue(loginUser);
	const [profile, setProfile] = useState<string>('');
	const [subscribe, setSubscribe] = useState<any>(undefined);

	const onUpdateStaff = () =>
		(
			API.graphql({
				query: subscriptions.onUpdateStaff,
			}) as any
		).subscribe({
			next: ({ value }: any) => {
				const item = value.data.onUpdateStaff;
				setSubscribe(item);
			},
			error: (err: any) => {
				ErrorCheck(err);
				window.location.reload();
			},
		});

	useEffect(() => {
		const update = onUpdateStaff();
		return () => {
			update.unsubscribe();
		};
	}, [userInfo, user]);

	useEffect(() => {
		if (userInfo) {
			Storage.get(userInfo?.profile)
				.then((result) => {
					setProfile(result);
				})
				.catch((e) => {
					ErrorCheck(e);
				});
		}
	}, [userInfo, subscribe, user]);

	const handleFile = async (e: ChangeEvent<HTMLInputElement>) => {
		let fileInput = false;
		if (e.currentTarget.files?.[0]) {
			fileInput = true;
		}
		if (fileInput) {
			try {
				Resizer.imageFileResizer(
					e.currentTarget.files?.[0] as Blob,
					100,
					100,
					'PNG',
					100,
					0,
					async (uri) => {
						const profileObj = await Storage.put(`staff/${userInfo?.id}.png`, uri);
						if (userInfo && user) {
							const { id, name, status } = userInfo;
							updateStaff({
								id,
								name,
								status,
								profile: profileObj.key,
								cognitoId: user,
								editBy: user,
							})
								.then()
								.catch((err) => ErrorCheck(err));
						}
					},
					'file'
				);
			} catch (err) {
				ErrorCheck(err);
			}
		}
	};

	return (
		<label htmlFor="profile">
			<input id="profile" type="file" hidden onChange={(e) => handleFile(e)} />
			<Avatar alt={userInfo?.id} src={profile} sx={{ width: 30, height: 30, cursor: 'pointer' }} />
		</label>
	);
}

export default NavAvatar;
