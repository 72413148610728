/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "env": "release",
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_buqfqvOWZ",
    "aws_user_pools_web_client_id": "5qficnniqhtf79qm385tkmtil4",
    "aws_cognito_identity_pool_id": "ap-northeast-2:8f0c5ab4-ac77-48bb-9472-6d7282143d9b",
    "aws_appsync_graphqlEndpoint": "https://ltq56kk6ofgvjdvglcuj2cdkxy.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",//'API_KEY'
    //"aws_appsync_apiKey": "da2-vavcbts6svd2dlexc6omj5wk44",
    "aws_user_files_s3_bucket_region": "ap-northeast-2",
    "aws_user_files_s3_bucket": "breezm-erp-release",
    "oauth": {}
};

export default awsmobile;
