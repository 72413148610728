import { Storage } from 'aws-amplify';
import ErrorCheck from '../util/ErrorCheck';

const uploadS3 = async (file: File | null | undefined, url: string) => {
	try {
		return await Storage.put(url, file);
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default uploadS3;
