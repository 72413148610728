import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

type sendSMSQuery = {
	data: {
		sendSMS: 'success' | 'fail';
	};
};

const sendSMS = async (sender: string, receiver: string, randomNumber: string) => {
	try {
		const data = (await API.graphql({
			query: mutations.sendSMS,
			variables: {
				sender,
				receiver,
				contents: `[브리즘] 인증번호 [${randomNumber}]를 입력해 주세요.`,
			},
		})) as sendSMSQuery;

		const result: string[] = data.data.sendSMS.match(/(\w+)/g) as string[];

		if (result[3] === 'success') {
			return 'success';
		}
		return 'fail';
	} catch (err) {
		console.log({ err });
		return null;
	}
};

export default sendSMS;
