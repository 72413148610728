import React from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { productInfoListState } from '../../atom';

const ProductCol = (): GridColDef[] => {
	const productInfoList = useRecoilValue(productInfoListState);
	const category: string[] = productInfoList.map((product) => product.category);
	const categoryOption = category.filter((v, i) => category.indexOf(v) === i);

	return [
		{ field: 'id', headerName: '아이디', type: 'string', flex: 1 },
		{ field: 'label', headerName: '이름', type: 'string', flex: 1 },
		{ field: 'category', headerName: '카테고리', type: 'singleSelect', flex: 1, valueOptions: categoryOption },
		{ field: 'status', headerName: '상태', type: 'singleSelect', flex: 1 },
		{ field: 'note', headerName: '비고', type: 'string', flex: 1 },
	];
};

export default ProductCol;
