import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { getStaffQuery, StaffType } from '../util/Interface';
import ErrorCheck from '../util/ErrorCheck';
import { convertStaff } from '../util/ReplaceValue';

const getStaff = async () => {
	try {
		let token = '';
		const result: StaffType[] = [];
		while (token != null) {
			const data = (await API.graphql({
				query: queries.listStaff,
				variables: {
					limit: 100,
					nextToken: token === '' ? null : token,
				},
			})) as getStaffQuery;
			token = data.data.listStaff.nextToken;
			console.log('token', token);
			const value: StaffType[] = convertStaff(data.data.listStaff.items);
			result.push(...value);
			if (token === null) {
				break;
			}
		}
		return result;
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default getStaff;
