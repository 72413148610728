import { API } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../graphql/queries';
import { fetchRangeScheduleQuery, ReserveType } from '../util/Interface';
import { convertReserve } from '../util/ReplaceValue';

interface fetchSchedulerProps {
	storeId: string;
	dateArg: {
		start: Date;
		end: Date;
	};
}

const fetchScheduler = async (props: fetchSchedulerProps) => {
	try {
		const {
			storeId,
			dateArg: { start, end },
		} = props;
		const result: ReserveType[] = [];
		let token = '';
		while (token != null) {
			const data = (await API.graphql({
				query: queries.listReservationByStoreWithReserveDateRange,
				variables: {
					storeId,
					reserveDateFrom: moment(start).toISOString(),
					reserveDateTo: moment(end).toISOString(),
					limit: 100,
					nextToken: token === '' ? null : token,
				},
			})) as fetchRangeScheduleQuery;
			token = data.data.listReservationByStoreWithReserveDateRange.nextToken;
			const value: ReserveType[] = convertReserve(data.data.listReservationByStoreWithReserveDateRange.items).filter(
				(reserve) => reserve.reserveType !== 'attention'
			);
			result.push(...value);
			if (token === null) {
				break;
			}
		}
		return result;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export default fetchScheduler;
