import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridRowModel } from '@mui/x-data-grid-pro';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import SubNav from '../../components/SubNav';
import SearchBar from '../../components/SearchBar';

import NoDataOverlay from '../../components/NoDataOverlay';
import koText from '../../util/localizationKR';
import CustomerModal from '../../components/modal/CustomerModal';
import { openCustomerState } from '../../atom/Atom';
import { customerList, customerState, loadingState, searchValue } from '../../atom';
import { fetchCustomer } from '../../query';
import CustomToolbar from '../../components/CustomToolbar';
import { CustomerType } from '../../util/Interface';
import { findUrlField } from '../../util/ReplaceValue';
import { fetchUser } from '../../selector';
import { CustomerCol } from '../../columns';

function CustomerInfo() {
	const [customers, setCustomers] = useRecoilState(customerList);
	const setOpenCustomer = useSetRecoilState(openCustomerState);
	const searchData = useRecoilValue(searchValue);
	const loading = useRecoilValue(loadingState);
	const setCustomerState = useSetRecoilState(customerState);

	const handleOnRowsScrollEnd = () => {
		if (customers.length >= 100 && !searchData) {
			fetchCustomer({}).then((value) => {
				if (value) {
					setCustomers([...customers, ...value]);
				}
			});
		}
	};

	useEffect(() => {
		const check = '';
		fetchCustomer({ check }).then((result) => {
			if (result) {
				setCustomers(result);
			}
		});
	}, [setCustomers]);
	const userInfo = useRecoilValue(fetchUser);
	const location = useLocation();
	const field = findUrlField(location);

	if (!userInfo) {
		return null;
	}
	return (
		<>
			<Box px={5}>
				<SubNav />
				<SearchBar />
				<div style={{ height: '80vh', width: '100%' }}>
					<DataGridPro
						rows={customers as GridRowModel[]}
						columns={CustomerCol()}
						density={userInfo.dataGridState?.[`${field}`]?.density || 'standard'}
						initialState={{
							columns: {
								...userInfo.dataGridState?.[`${field}`]?.columns,
								columnVisibilityModel: userInfo.dataGridState?.[`${field}`]?.columns?.columnVisibilityModel,
							},
							filter: userInfo.dataGridState?.[`${field}`]?.filter,
						}}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay: NoDataOverlay,
						}}
						componentsProps={{
							toolbar: { field },
						}}
						pageSize={5}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						disableColumnMenu
						style={{
							border: 'none',
						}}
						localeText={koText}
						hideFooter
						loading={loading}
						onRowsScrollEnd={handleOnRowsScrollEnd}
						onCellClick={(params) => {
							setOpenCustomer(true);
							setCustomerState(params.row as CustomerType);
						}}
					/>
				</div>
			</Box>
			<CustomerModal />
		</>
	);
}

export default CustomerInfo;
