import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, MenuItem, TextField } from '@mui/material';

import { CustomFieldType } from '../../util/Interface';

function CustomFieldSingleSelect({ field, label, option }: CustomFieldType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const makeOption = () => {
		return option?.map((obj) => {
			return (
				<MenuItem key={obj.value} value={obj.value}>
					{obj.label}
				</MenuItem>
			);
		});
	};

	return (
		<Grid item xs={2}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
					<TextField
						name={field}
						onChange={onChange}
						value={value || ''}
						label={label}
						inputRef={ref}
						select
						size="small"
						fullWidth
						error={invalid && Boolean(errors[`${field}`] || false)}
						helperText={invalid && errors[`${field}`]?.message}
						// SelectProps={{ renderValue: (obj: any) => option?.find((opt) => opt.value === obj)?.label }}
					>
						{makeOption()}
					</TextField>
				)}
			/>
		</Grid>
	);
}

export default CustomFieldSingleSelect;
