import React, { useCallback, useEffect, useState } from 'react';

import FullCalendar, { DateSelectArg, EventClickArg, EventDropArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import koLocale from '@fullcalendar/core/locales/ko';
import moment from 'moment';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Box } from '@mui/material';
import { DatesSetArg, EventInput } from '@fullcalendar/common';
import { API } from 'aws-amplify';
import { openReserveState } from '../../atom/Atom';
import SubNav from '../../components/SubNav';

import { ReservationResponse, ReserveType } from '../../util/Interface';
import { loginUser, reserveDetail, loadingState } from '../../atom';
import { fetchScheduler, updateReserve } from '../../query';
import { fetchUser } from '../../selector';
import ReserveModal from '../../components/modal/ReserveModal';
import ErrorCheck from '../../util/ErrorCheck';
import * as subscriptions from '../../graphql/subscriptions';
import { convertReserve, convertReserveToSchedule } from '../../util/ReplaceValue';
import Loading from '../../components/Loading';

function Scheduler() {
	const setOpen = useSetRecoilState(openReserveState);
	const [scheduleDetail, setScheduleDetail] = useRecoilState(reserveDetail);
	const user = useRecoilValue(loginUser);
	const userInfo = useRecoilValue(fetchUser);
	const storeId = userInfo?.storeId;
	const [reserveList, setReserveList] = useState<ReserveType[] | null>(null);
	const [schedule, setSchedule] = useState<EventInput[]>([]);
	const [dateArg, setDateArg] = useState<{ start: Date; end: Date } | null>(null);
	const [loading, setLoading] = useRecoilState(loadingState);

	useEffect(() => {
		if (dateArg && storeId) {
			setLoading(true);
			fetchScheduler({ storeId, dateArg })
				.then((reserveRes) => {
					if (reserveRes && userInfo) {
						setReserveList(reserveRes);
						setSchedule(convertReserveToSchedule(reserveRes, userInfo.coworker));
						setLoading(false);
					}
				})
				.catch((err) => ErrorCheck(err));
		}
	}, [dateArg, setLoading, storeId, userInfo]);

	const addSchedule = (e: DateSelectArg) => {
		if (moment(e.start).hour() === 0) {
			setScheduleDetail({
				...scheduleDetail,
				reserveDate: new Date(moment(e.start).add(11, 'hour').format()),
			});
		} else {
			setScheduleDetail({
				...scheduleDetail,
				reserveDate: e.start,
			});
		}
		setOpen(true);
	};

	const editSchedule = (e: EventClickArg) => {
		const event = reserveList?.find((x) => x.id === e.event.id);
		setScheduleDetail(event as ReserveType);
		setOpen(true);
	};

	const handleDate = (arg: DatesSetArg) => {
		const { start, end } = arg;
		setDateArg({ start, end });
	};

	const handleEventDrop = (e: EventDropArg) => {
		if (reserveList) {
			let event = reserveList.find((x) => x.id === e.event.id);
			if (event) {
				event = { ...event, reserveDate: e.event.start as Date };
			}
			updateReserve(event, userInfo)
				.then()
				.catch((err) => {
					ErrorCheck(err);
				});
		}
	};

	const onCreateSchedule = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onCreateReservationByStore as ReservationResponse;
					if (userInfo && reserveList) {
						const newSchedule = convertReserveToSchedule(convertReserve([item]), userInfo.coworker);
						const newReserve = convertReserve([item]);
						setSchedule([...schedule, ...newSchedule]);
						setReserveList([...reserveList, ...newReserve]);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
					if (user) {
						window.location.reload();
					}
				},
			}),
		[reserveList, schedule, storeId, user, userInfo]
	);

	const onUpdateSchedule = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateReservationByStore as ReservationResponse;
					if (userInfo && reserveList) {
						const newArray = [...schedule];
						const reserveArray = [...reserveList];
						const index = newArray.findIndex(({ id }) => id === item.id);
						const reserveIndex = newArray.findIndex(({ id }) => id === item.id);
						const newSchedule = convertReserveToSchedule(convertReserve([item]), userInfo.coworker)[0];
						const newReserve = convertReserve([item])[0];
						newArray[index] = newSchedule;
						reserveArray[reserveIndex] = newReserve;
						setSchedule([...newArray]);
						setReserveList([...reserveArray]);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[reserveList, schedule, storeId, userInfo]
	);

	const onDeleteSchedule = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onDeleteReservationByStore,
					variables: {
						storeId,
					},
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					if (reserveList) {
						const item = value.data.onDeleteReservationByStore as ReservationResponse;
						const newArray = [...schedule];
						const reserveArray = [...reserveList];
						const index = newArray.findIndex(({ id }) => id === item.id);
						const reserveIndex = reserveArray.findIndex(({ id }) => id === item.id);
						if (index > -1) newArray.splice(index, 1);
						if (reserveIndex > -1) reserveArray.splice(reserveIndex, 1);
						setSchedule(newArray);
						setReserveList(reserveArray);
					}
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[reserveList, schedule, storeId]
	);

	useEffect(() => {
		const create = onCreateSchedule();
		const update = onUpdateSchedule();
		const remove = onDeleteSchedule();
		return () => {
			create.unsubscribe();
			update.unsubscribe();
			remove.unsubscribe();
		};
	}, [onCreateSchedule, onDeleteSchedule, onUpdateSchedule]);

	if (!userInfo) return null;
	const { storeOpenTime, storeCloseTime, storeDurationTime } = userInfo;

	return (
		<>
			<Box px={5}>
				<SubNav />
				{loading && <Loading />}
				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					headerToolbar={{
						left: 'timeGridWeek,timeGridDay,dayGridMonth',
						center: 'title',
						right: 'prev,next today',
					}}
					events={schedule}
					eventDrop={handleEventDrop}
					eventTextColor="black"
					initialView="timeGridWeek"
					buttonText={{
						today: '오늘',
						month: '월간',
						week: '주간',
						day: '일간',
					}}
					datesSet={handleDate}
					allDaySlot={false}
					slotMinTime={storeOpenTime}
					slotMaxTime={storeCloseTime}
					contentHeight="auto"
					droppable
					selectable
					editable
					selectLongPressDelay={25}
					slotDuration={storeDurationTime}
					select={addSchedule}
					eventClick={editSchedule}
					firstDay={1}
					locale={koLocale}
				/>
			</Box>
			<ReserveModal />
		</>
	);
}

export default Scheduler;
