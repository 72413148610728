import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams, Chip, Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { InputType } from '../../util/Interface';
import { makeLensIdOption, makeStaffObj, makeValidFrame } from '../../util/ReplaceValue';
import { fetchUser } from '../../selector';
import { productInfoListState } from '../../atom';
import { openFrameState } from '../../atom/Atom';

function AutoCompleteInput({ field, label, grid }: InputType) {
	const userInfo = useRecoilValue(fetchUser);
	const productList = useRecoilValue(productInfoListState);
	const openFrame = useRecoilValue(openFrameState);

	const {
		control,
		resetField,
		formState: { errors },
	} = useFormContext();
	const makeOption = () => {
		if (field?.includes('Staff')) {
			return userInfo?.coworker;
		}

		if (field === 'name') {
			if (openFrame) {
				return makeValidFrame(productList);
			}
			return makeLensIdOption(productList);
		}
		return [];
	};
	const makeValue = (value: string) => {
		if (field?.includes('Staff')) {
			return makeStaffObj(value as string, userInfo?.coworker) || null;
		}
		if (field === 'name') {
			if (openFrame) {
				return makeValidFrame(productList).find((obj) => obj.value === value);
			}
			return makeLensIdOption(productList).find((obj) => obj.value === value);
		}
		return { label: '', value: '' };
	};
	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { invalid } }) => {
					return (
						<Autocomplete
							options={makeOption() || []}
							size="small"
							value={makeValue(value) || null}
							isOptionEqualToValue={(option, obj) => option.value === obj.value}
							onChange={(e, obj) => {
								if (field === 'name') {
									resetField('lensCoating');
									resetField('baseColor');
									resetField('frontColor');
									resetField('frontParts');
									resetField('frontPartsColor');
									resetField('size');
									resetField('temple');
									resetField('templeColor');
									resetField('templeLength');
									resetField('templeParts');
									resetField('templePartsColor');
									resetField('nosepad');
									resetField('bridge');
									resetField('price');
								}
								onChange(obj?.value || '');
							}}
							renderInput={(params: AutocompleteRenderInputParams) => (
								<TextField
									name={field}
									{...params}
									label={label}
									fullWidth
									error={invalid && Boolean(errors[`${field}`] || false)}
									helperText={invalid && errors[`${field}`] ? errors[`${field}`]?.message : ''}
								/>
							)}
							renderOption={(props, option) => (
								<li {...props} key={option.value}>
									<Grid container>
										<Grid item xs={12}>
											<Chip
												label={option.label}
												style={{
													backgroundColor: `${option.color}`,
												}}
											/>
										</Grid>
									</Grid>
								</li>
							)}
							getOptionLabel={(option) => option.label || ''}
							ListboxProps={{
								style: {
									maxHeight: 45 * (userInfo?.staffCount || 0),
								},
							}}
						/>
					);
				}}
			/>
		</Grid>
	);
}

export default AutoCompleteInput;
