import { atom } from 'recoil';
import moment from 'moment';
import { FrameType, LensType } from '../util/Interface';

export interface registerProps {
	name: string;
	phoneNumber: string;
	certificationNumber: string;
	gender: string;
	birth: Date | null;
	email: string;
	address: string;
	subAddress: string;
	infoAgree: boolean;
	asAgree: boolean;
	personalAgree: boolean;
	signature: string;
}

export interface customerDetailProps {
	[index: string]: string | null | Date | boolean;
	name: string;
	phoneNumber: string;
	gender: string;
	birthday: Date | null;
	preferContact: string;
	email: string;
	address: string;
	infoAgree: boolean;
	asAgree: boolean;
	personalAgree: boolean;
	note: string;
	care: string;
}

export interface eyesDataProps {
	[index: string]: string | string[] | number | undefined;
	id?: string;
	rSph: number | '';
	rCyl: number | '';
	rAxi: number | '';
	rAdd: number | '';
	rInOut: string;
	rPrism1: number | '';
	rPd: number | '';
	rOh: number | '';
	rCv: number | '';
	rSo: string[];
	rUpDown: string;
	rPrism2: number | '';
	lSph: number | '';
	lCyl: number | '';
	lAxi: number | '';
	lAdd: number | '';
	lInOut: string;
	lPrism1: number | '';
	lPd: number | '';
	lOh: number | '';
	lCv: number | '';
	lSo: string[];
	lUpDown: string;
	lPrism2: number | '';
	questionnaire: string;
	optical: string;
	brand: string;
	power: string;
	lensType: string;
	lensDesign: string;
	progressive: string;
	coating: string;
	discolor: string;
	lensColor: string;
	cost: number | '';
	lensServiceType: string;
	lensStaff: string;
}

export interface frameDataProps {
	[index: string]: string | number | Date | File | undefined | null | string[];
	id?: string;
	frameType: string;
	name: string;
	size: string;
	color: string;
	partsColor: string;
	temple: string;
	templeLength: string;
	nosepad: string;
	bridge: string;
	price: number | '';
	post: string;
	hopeDate: Date;
	ecStaff: string;
	frameStaff: string;
	designRequest: string;
	produceRequest: string;
	customSide: File | null;
	relationId?: string;
}

export interface frameAsDataProps {
	[index: string]: string | number | Date | File | undefined | null | string[];
	frameAsType: string;
	frameAsContext: string[];
	frameAsComment: string;
}

export interface eyesAsDataProps {
	[index: string]: string | number | Date | File | undefined | null | string[];
	lensAsType: string;
	lensAsContext: string[];
	lensAsComment: string;
}

export interface frameAsDetail extends frameAsDataProps, frameDataProps {
	[index: string]: string | number | Date | File | undefined | null | string[];
}

export interface lensAsDetail extends eyesAsDataProps, eyesDataProps {
	[index: string]: string | string[] | number | undefined;
}

export type GRID_SIZES = 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const openFrameState = atom<boolean>({
	key: 'openFrameState',
	default: false,
});

export const openAsFrameState = atom<boolean>({
	key: 'openAsFrameState',
	default: false,
});

export const reserveDateState = atom<Date>({
	key: 'reserveDateState',
	default: new Date(),
});

export const openCustomerState = atom<boolean>({
	key: 'openCustomerState',
	default: false,
});

export const openLensState = atom<boolean>({
	key: 'openLensState',
	default: false,
});

export const openAsLensState = atom<boolean>({
	key: 'openAsLensState',
	default: false,
});

export const openDesignDetailState = atom<boolean>({
	key: 'openDesignDetailState',
	default: false,
});

export const openReserveState = atom<boolean>({
	key: 'openReserveState',
	default: false,
});

export const openAddressState = atom<boolean>({
	key: 'openAddressState',
	default: false,
});

export const openSignatureState = atom<boolean>({
	key: 'openSignatureState',
	default: false,
});

export const openProductInfoState = atom<boolean>({
	key: 'openProductInfoState',
	default: false,
});

export const frameDataState = atom<FrameType>({
	key: 'frameDataState',
	default: {
		serviceType: '',
		name: '',
		size: '',
		color: '',
		frontParts: '',
		frontPartsColor: '',
		temple: '',
		templeLength: '',
		templeParts: '',
		templePartsColor: '',
		nosepad: '',
		bridge: '',
		price: '',
		front: null,
		right: null,
		left: null,
		beforeFront: null,
		beforeRight: null,
		beforeLeft: null,
		hopeDate: moment(new Date()).add(12, 'day').toISOString(),
		designReq: [],
		ecStaff: '',
		frameStaff: '',
		delivery: '',
	},
});

export const frameAsDataState = atom<frameAsDetail>({
	key: 'frameAsDataState',
	default: {
		frameAsType: '',
		frameAsContext: [],
		frameAsComment: '',
		frameType: '',
		name: '',
		size: '',
		color: '',
		partsColor: '',
		temple: '',
		templeLength: '',
		nosepad: '',
		bridge: '',
		price: '',
		post: '',
		hopeDate: new Date(),
		ecStaff: '',
		frameStaff: '',
		designRequest: '',
		produceRequest: '',
		customSide: null,
		relationId: '',
	},
});

export const lensDataState = atom<LensType>({
	key: 'lensDataState',
	default: {
		name: '',
		lensCoating: '',
		lensOrderType: '',
		lensCategory: 'both',
		price: '',
	},
});

export const lensAsDataState = atom<lensAsDetail>({
	key: 'lensAsDataState',
	default: {
		lensAsType: '',
		lensAsContext: [],
		lensAsComment: '',
		rSph: '',
		rCyl: '',
		rAxi: '',
		rAdd: '',
		rInOut: '',
		rPrism1: '',
		rPd: '',
		rOh: '',
		rCv: '',
		rSo: [],
		rUpDown: '',
		rPrism2: '',
		lSph: '',
		lCyl: '',
		lAxi: '',
		lAdd: '',
		lInOut: '',
		lPrism1: '',
		lPd: '',
		lOh: '',
		lCv: '',
		lSo: [],
		lUpDown: '',
		lPrism2: '',
		questionnaire: '',
		optical: '',
		brand: '',
		power: '',
		lensType: '',
		lensDesign: '',
		progressive: '',
		coating: '',
		discolor: '',
		lensColor: '',
		cost: '',
		lensServiceType: '',
		lensStaff: '',
	},
});
