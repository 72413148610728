import React from 'react';
import { Box } from '@mui/material';
import SubNav from '../../components/SubNav';

function CustomerList() {
	return (
		<Box px={5}>
			<SubNav />
		</Box>
	);
}

export default CustomerList;
