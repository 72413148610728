import moment from 'moment';
import { API } from 'aws-amplify';
import { ReserveType, CustomerResponse, UserData } from '../util/Interface';
import * as mutations from '../graphql/mutations';
import { toServerReserve } from '../util/ReplaceValue';
import { createReserveQuery } from './createReserve';
import ErrorCheck from '../util/ErrorCheck';

const createAutoReserve = async (userInfo: UserData | null, values: CustomerResponse | null) => {
	const date: Date = new Date();
	const remainder = 10 - (moment(date).minute() % 10);
	const reserveDate = moment(date).add(remainder, 'minutes').toDate();
	try {
		const reserve = {
			reserveType: 'custom',
			reserveDate,
			name: values?.name || '',
			phone: values?.phone || '',
			reserveMedia: 'walk',
			person: 1,
			staff: userInfo?.id || '',
		} as ReserveType;

		const storeId: string = userInfo?.storeId || '';
		const staffId: string = userInfo?.id || '';
		const customerId: string = values?.id || '';

		const result = (await API.graphql({
			query: mutations.createReservation,
			variables: {
				input: toServerReserve(reserve, storeId, staffId, customerId),
			},
		})) as createReserveQuery;
		return result.data.createReservation;
	} catch (e) {
		ErrorCheck(e);
		return null;
	}
};

export default createAutoReserve;
