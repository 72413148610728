import React, { useEffect } from 'react';
import { Box, Button, Grid, Input } from '@mui/material';
import { API, Auth, Storage, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';

/* */
function CustomFieldEditor() {
	return <Grid item xs={12} />;
}

function Test() {
	/* let input = {"airtable":{"fields":[{"name":"PD","path":{"section":"face size values","key":"eye_width"}},{"name":"얼굴너비","path":{"section":"face size values","key":"temple_width"}}]}};
  console.log(input);
  let output = AWS.DynamoDB.Converter.input(input);
  console.log(output); */
	const [showCustomFieldEditor, setShowCustomFieldEditor] = React.useState(false);
	/* edward backend test start */
	async function fetchUser() {
		try {
			const data = await Auth.currentAuthenticatedUser();
			console.log(data);
		} catch (err) {
			// @todo : redirect to login page
			// "The user is not authenticated"
			console.log({ err });
		}
	}

	function subscribeToOnCreateTopic() {
		const subscription = (API.graphql(graphqlOperation(subscriptions.onCreateStore)) as any).subscribe(
			(eventData: any) => {
				console.log(eventData);
			}
		);

		return subscription;
	}

	useEffect(() => {
		fetchUser();

		const subscription = subscribeToOnCreateTopic();
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const toggleResult = () => {
		setShowCustomFieldEditor(!showCustomFieldEditor);
	};

	const searchReservation = async (name: string) => {
		try {
			console.log('test opensearch read');
			const data: any = await API.graphql({
				query: queries.searchReservation,
				variables: {
					search: name,
				},
			});
			console.log(data);
		} catch (err: any) {
			// @todo : catch 함수 하나 만들어서 한군데서 처리할 수 있으면 좋을 듯
			// err.message === no current user
			console.log({ err });
		}
	};

	const listReservationByDayWithStore = async () => {
		try {
			console.log('test dynamodb read');
			let count = 0;
			let token = '';
			while (token != null) {
				const data: any = await API.graphql({
					query: queries.listReservationByDayWithStore,
					variables: {
						reserveYMD: '2021-11-10',
						storeId: 'kr-st-1',
						limit: 100,
						nextToken: token === '' ? null : token,
					},
				}); // , variables: { nextToken: "" }
				count = data.data.listReservationByDayWithStore.items.length;
				token = data.data.listReservationByDayWithStore.nextToken;
				data.data.listReservationByDayWithStore.items.forEach((customer: any) => {
					console.log(customer.name);
				});
				if (token === null) {
					break;
				}
				console.log(data.data.listReservationByDayWithStore.items.length);
			}
		} catch (err: any) {
			// @todo : catch 함수 하나 만들어서 한군데서 처리할 수 있으면 좋을 듯
			// err.message === no current user
			console.log({ err });
		}
	};

	const listReservationByStore = async () => {
		try {
			console.log('test dynamodb read');
			const count = 0;
			const token = '';
			const data: any = await API.graphql({
				query: queries.listReservationByStore,
				variables: {
					storeId: 'kr-st-1',
					sortDirection: 'DESC',
					limit: 100,
				},
			});
			console.log(data.data.listReservationByStore.items);
		} catch (err: any) {
			// @todo : catch 함수 하나 만들어서 한군데서 처리할 수 있으면 좋을 듯
			// err.message === no current user
			console.log({ err });
		}
	};

	const listReservationByStoreWithReserveDateRange = async () => {
		try {
			console.log('test dynamodb read');
			let count = 0;
			let token = '';
			while (token != null) {
				const data: any = await API.graphql({
					query: queries.listReservationByStoreWithReserveDateRange,
					variables: {
						storeId: 'kr-st-1',
						reserveDateFrom: '2021-11-18T00:00:00.000Z',
						reserveDateTo: '2021-11-25T23:59:59.999Z',
						limit: 100,
						nextToken: token === '' ? null : token,
					},
				}); // , variables: { nextToken: "" }
				count = data.data.listReservationByStoreWithReserveDateRange.items.length;
				token = data.data.listReservationByStoreWithReserveDateRange.nextToken;
				data.data.listReservationByStoreWithReserveDateRange.items.forEach((reservation: any) => {
					console.log(reservation);
				});
				if (token === null) {
					break;
				}
				console.log(data.data.listCustomerSearch.items.length);
			}
		} catch (err: any) {
			// @todo : catch 함수 하나 만들어서 한군데서 처리할 수 있으면 좋을 듯
			// err.message === no current user
			console.log({ err });
		}
	};

	const listCustomerSearch = async () => {
		try {
			const filter = {
				or: [
					{
						name: {
							contains: '주제',
						},
					},
					{
						phone: {
							contains: '주제',
						},
					},
					{
						email: {
							contains: '주제',
						},
					},
				],
			};
			console.log('test dynamodb read');
			const count = 0;
			let token = '';
			while (token != null) {
				const data: any = await API.graphql({
					query: queries.listCustomerSearch,
					variables: {
						status: 'valid',
						filter,
						limit: 100,
						nextToken: token === '' ? null : token,
					},
				}); // , variables: { nextToken: "" }
				// count = data.data.listCustomerSearch.items.length;
				token = data.data.listCustomerSearch.nextToken;
				data.data.listCustomerSearch.items.forEach((customer: any) => {
					console.log(customer.name);
				});
				if (token === null) {
					break;
				}
				console.log(data.data.listCustomerSearch.items.length);
			}
		} catch (err: any) {
			// @todo : catch 함수 하나 만들어서 한군데서 처리할 수 있으면 좋을 듯
			// err.message === no current user
			console.log({ err });
		}
	};

	const listStaffSearch = async () => {
		try {
			const filter = {
				or: [
					{
						name: {
							contains: '춘',
						},
					},
					{
						phone: {
							contains: '춘',
						},
					},
					{
						email: {
							contains: '춘',
						},
					},
				],
			};
			console.log('test dynamodb read');
			const data: any = await API.graphql({
				query: queries.listStaffSearch,
				variables: { status: 'valid', filter, limit: 100 },
			}); // , variables: { nextToken: "" }
			console.log(data);
			console.log(data.data.listStaffSearch.items.length);
			// const data2 = await API.graphql({ query: queries.listStaffSearch, variables: { limit: 10, nextToken: data.data.listStaffSearch.nextToken } });//, variables: { nextToken: "" }
			// console.log(data2);
		} catch (err: any) {
			// @todo : catch 함수 하나 만들어서 한군데서 처리할 수 있으면 좋을 듯
			// err.message === no current user
			console.log({ err });
		}
	};

	const createStore = async () => {
		try {
			console.log('create storeList');
			const data = await API.graphql({
				query: mutations.createStore,
				variables: {
					input: {
						name: '비',
						storeType: 'store',
						status: 'valid',
						code: 'B',
						note: '',
					},
				},
			});
			console.log(data); // "{statusCode=200, body={success=true, data=021110217000005133}}"
		} catch (err) {
			console.log({ err });
		}
	};

	const sendSMS = async () => {
		try {
			console.log('test lambda call');
			const data = await API.graphql({
				query: mutations.sendSMS,
				variables: {
					sender: '01067426087',
					receiver: '01067426087',
					contents: 'test message',
				},
			});
			console.log(data); // "{statusCode=200, body={success=true, data=021110217000005133}}"
		} catch (err) {
			console.log({ err });
		}
	};

	const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files != null) {
			const file = event.target.files[0];
			const result = await Storage.put(file.name, file);
			console.log('upload result = ', result);
		}
	};

	const getS3Object = async () => {
		const result = await Storage.get('/customer/010-3609-6550');
		window.open(result);
		// const result = await Storage.get('kms-dg.pdf', { download: true });
		// downloadBlob(result.Body, 'kms-dg.pdf');
	};

	const listS3Object = async () => {
		await Storage.list('')
			.then((result) => console.log(result))
			.catch((err) => console.log(err));
	};

	const removeS3Object = async () => {
		const result = await Storage.remove('type.json');
		console.log(result);
	};

	const signOut = async () => {
		try {
			await Auth.signOut({ global: true });
			// @todo : redirect to login page
		} catch (error) {
			console.log('error signing out: ', error);
		}
	};

	/* edward backend test end */

	return (
		<Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => searchReservation('춘원')}>
						searchReservation
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => createStore()}>
						createStore
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => listReservationByStore()}>
						listReservationByStore
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						size="large"
						variant="contained"
						onClick={() => listReservationByStoreWithReserveDateRange()}
					>
						listReservationByStoreWithReserveDateRange
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => listReservationByDayWithStore()}>
						listReservationByDayWithStore
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => listStaffSearch()}>
						listStaffSearch
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => listCustomerSearch()}>
						listCustomerSearch
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => listS3Object()}>
						listObject
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => removeS3Object()}>
						removeObject(type.json)
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => getS3Object()}>
						getObject
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => sendSMS()}>
						sendSMS
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => signOut()}>
						logout
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth size="large" variant="contained" onClick={() => toggleResult()}>
						CustomField
					</Button>
				</Grid>
				{showCustomFieldEditor ? <CustomFieldEditor /> : null}
				<Grid item xs={12}>
					<Input fullWidth type="file" onChange={handleFileInputChange} />
				</Grid>
			</Grid>
		</Box>
	);
}

export default Test;
