import {
	GridColumnMenuProps,
	GridDimensions,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { isEqual, omit } from 'lodash';
import React from 'react';
import { fetchUser } from '../selector';
import { StaffType, UrlDataGrid } from '../util/Interface';
import { toServerStaff } from '../util/ReplaceValue';
import { updateStaff } from '../query';

type OwnerState = {
	field: string;
};

function CustomToolbar(props: GridColumnMenuProps & OwnerState) {
	const { field } = props;
	const apiRef = useGridApiContext();
	const dataGridState = apiRef.current.exportState();
	const { state } = apiRef.current;
	const userInfo = useRecoilValue(fetchUser);
	if (userInfo && field) {
		const dataGridStateObj = { ...userInfo.dataGridState };
		const result = {
			...dataGridStateObj,
			[`${field}`]: {
				...dataGridState,
				columns: { ...dataGridState.columns, dimensions: dataGridState.columns?.dimensions },
				density: state.density.value,
			},
		};
		const staff: StaffType = {
			...userInfo,
			dataGridState: result as UrlDataGrid,
		};
		const front = staff.dataGridState?.[`${field}`];
		const server = userInfo.dataGridState?.[`${field}`];
		let frontDimensions = {};
		let serverDimensions = {};
		if (front?.columns?.dimensions) {
			frontDimensions = Object.fromEntries(
				Object.entries(front?.columns?.dimensions).map((object) =>
					object.map((obj, index) => {
						if (index === 1) {
							return omit(obj as GridDimensions, 'flex');
						}
						return obj;
					})
				)
			);
		}
		if (server?.columns?.dimensions) {
			serverDimensions = Object.fromEntries(
				Object.entries(server?.columns?.dimensions).map((object) =>
					object.map((obj, index) => {
						if (index === 1) {
							return omit(obj as GridDimensions, 'flex');
						}
						return obj;
					})
				)
			);
		}
		const input = toServerStaff(staff, userInfo.id);
		/* if (!isEqual(frontDimensions, serverDimensions)) {
			console.log('throttle 시작');
		} */
		if (
			!isEqual(front?.columns?.columnVisibilityModel, server?.columns?.columnVisibilityModel) ||
			!isEqual(front?.filter, server?.filter) ||
			!isEqual(front?.density, server?.density)
		) {
			updateStaff(input)
				.then((res) => {
					if (res && res.dataGridState) {
						apiRef.current.restoreState(res.dataGridState[`${field}`]);
					}
				})
				.catch((error) => console.log(error));
		}
	}

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
		</GridToolbarContainer>
	);
}

export default CustomToolbar;
