import React, { KeyboardEvent, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';

import fetchReserveByCustomer from '../query/fetchReserveByCustomer';
import { fetchUser } from '../selector';
import {
	customerList,
	loadingState,
	reserveDateRange,
	reserveTotal,
	searchValue,
	staffListState,
	adminStaffState,
	storeListState,
} from '../atom';
import { fetchCustomer, fetchCustomerBySearch, fetchReserve, fetchReserveBySearch } from '../query';
import searchType from '../atom/searchType';

function SearchBar() {
	const [value, setValue] = useRecoilState(searchValue);
	const resetValue = useResetRecoilState(searchValue);
	const setReserve = useSetRecoilState(reserveTotal);
	const setCustomers = useSetRecoilState(customerList);
	const searchTypeValue = useRecoilValue(searchType);
	const userInfo = useRecoilValue(fetchUser);
	const resetRange = useResetRecoilState(reserveDateRange);
	const location = useLocation();
	const setLoading = useSetRecoilState(loadingState);
	// admin
	const staffList = useRecoilValue(staffListState);
	const setAdminStaff = useSetRecoilState(adminStaffState);
	const storeList = useRecoilValue(storeListState);

	useEffect(() => {
		resetValue();
	}, [resetValue]);

	const reserveTotalSearch = () => {
		if (location.pathname === '/store/reserve-total') {
			resetRange();
			if (userInfo !== null) {
				const { storeId } = userInfo;
				if (value === '' && storeId) {
					const check = '';
					setLoading(true);
					fetchReserve({ storeId, check }).then((res) => {
						setReserve(res?.reserve || []);
						setLoading(false);
					});
				} else {
					setLoading(true);
					// @todo fetchReserveBySearch 만들어서 변경해야함

					fetchReserveByCustomer(userInfo?.storeId || '', value).then((reserve) => {
						setReserve(reserve);
						setLoading(false);
					});
				}
			}
		} else if (location.pathname === '/admin/staff') {
			if (staffList && storeList) {
				const searchFromStaffList = staffList.map((staff) => {
					const findStore = storeList.find((store) => store.id === staff.storeId);
					return {
						...staff,
						storeName: findStore?.name || '',
					};
				});
				const searchResult = searchFromStaffList.filter(
					(staff) => staff.id.includes(value) || staff.name.includes(value) || staff.storeName.includes(value)
				);
				if (value === '') {
					setAdminStaff(staffList);
				} else {
					setAdminStaff(searchResult);
				}
			}
		} else if (location.pathname === '/store/customer-info') {
			if (value === '') {
				const check = '';
				setLoading(true);
				fetchCustomer({ check }).then((result) => {
					if (result) {
						setCustomers(result);
						setLoading(false);
					}
				});
			} else {
				setLoading(true);
				fetchCustomerBySearch({ searchValue: value }).then((SearchedCustomer) => {
					if (SearchedCustomer) {
						setCustomers(SearchedCustomer);
						setLoading(false);
					}
				});
			}
		} else if (location.pathname === '/admin/reserve/all') {
			if (value) {
				setLoading(true);
				fetchReserveBySearch({ searchValue: value, fields: 'name' }).then((result) => {
					if (result) {
						setReserve(result);
					} else {
						alert('실패, 다시 시도해주세요!');
					}
				});
				setLoading(false);
			}
		}
	};

	const getDate = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			reserveTotalSearch();
		}
	};

	const handleClick = () => {
		reserveTotalSearch();
	};

	return (
		<TextField
			size="small"
			value={value}
			onChange={(e) => setValue(e.target.value)}
			InputProps={{
				startAdornment: <SearchIcon />,
				endAdornment: (
					<Button
						variant="outlined"
						style={{
							minWidth: 50,
						}}
						size="small"
						onClick={handleClick}
					>
						검색
					</Button>
				),
				style: {
					paddingLeft: 4,
				},
			}}
			placeholder={location.pathname.includes('admin') ? '(이름, 근무지)' : '(고객명, 전화번호)'}
			fullWidth
			onKeyUp={(e: KeyboardEvent<HTMLDivElement>) => getDate(e)}
		/>
	);
}

export default SearchBar;
