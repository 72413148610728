import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { InputType } from '../../util/Interface';

function MultiSelect({ field, label, options }: InputType) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const makeOption = (value: string[]) =>
		options?.map((obj) => {
			return (
				<MenuItem key={obj.value} value={obj.value}>
					<Checkbox checked={value?.indexOf(obj.value) > -1} />
					<ListItemText primary={obj.label} />
				</MenuItem>
			);
		});
	if (!field) return null;
	return (
		<Grid item xs={2}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
					<FormControl fullWidth size="small">
						<InputLabel>{label}</InputLabel>
						<Select
							name={field}
							onChange={onChange}
							value={value || []}
							label={label}
							inputRef={ref}
							multiple
							renderValue={(selected) => {
								if (options) {
									return options
										.filter((option) => selected.includes(option.value))
										.map((option) => option.label)
										.join(', ');
								}
								return (selected as string[]).join(', ');
							}}
							error={invalid && Boolean(errors[`${field}`] || false)}
						>
							{makeOption(value as string[])}
						</Select>
					</FormControl>
				)}
			/>
		</Grid>
	);
}

export default MultiSelect;
