import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent, Grid, Box } from '@mui/material';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { frameArray } from '../../util/Array';
import { frameDataState, openFrameState } from '../../atom/Atom';
import { FrameType } from '../../util/Interface';
import { AutoCompleteInput, DateInput, SingleSelectInput, TextInput } from '../form';
import LongTextInput from '../form/LongTextInput';
import ImageInput from '../form/ImageInput';
import ReqArrayInput from '../form/ReqArrayInput';
import MultiSelect from '../form/MultiSelect';
import { productInfoListState } from '../../atom';

function FrameModal() {
	const [openFrame, setOpenFrame] = useRecoilState(openFrameState);
	const defaultValues = useRecoilValue(frameDataState);
	const resetDefaultValues = useResetRecoilState(frameDataState);
	const productList = useRecoilValue(productInfoListState);

	const methods = useForm<FrameType>({ defaultValues, mode: 'all' });

	const handleClose = () => {
		methods.reset();
		resetDefaultValues();
		setOpenFrame(false);
	};

	const onSubmit: SubmitHandler<FrameType> = (data) => {
		if (data.id) {
			// @todo  id 있을때
			console.log(data);
		} else {
			// @todo  id 없을때
			console.log(data);
		}
	};

	const watchCondition = (field: string | undefined): boolean => {
		if (openFrame) {
			if (field === 'bridge') {
				const bridge = productList.find((product) => product.id === methods.watch('name'))?.bridge;
				return (bridge && bridge.length > 0) || false;
			}
			if (field === 'bridgeColor') {
				const bridge = productList.find((product) => product.id === methods.watch('name'))?.bridge;
				return (bridge && bridge.length > 0) || false;
			}
			if (field === 'frontParts') {
				const parts = productList.find((product) => product.id === methods.watch('name'))?.frontParts;
				return (parts && parts.length > 0) || false;
			}
			if (field === 'frontPartsColor') {
				const parts = productList.find((product) => product.id === methods.watch('name'))?.frontParts;
				return (parts && parts.length > 0) || false;
			}
			if (field === 'tip') {
				const tip = productList.find((product) => product.id === methods.watch('temple'))?.tip;
				return (tip && tip.length > 0) || false;
			}
			if (field === 'tipColor') {
				const tip = productList.find((product) => product.id === methods.watch('temple'))?.tip;
				return (tip && tip.length > 0) || false;
			}
			if (field === 'templeParts') {
				const templeParts = productList.find((product) => product.id === methods.watch('temple'))?.connectParts;
				return (templeParts && templeParts.length > 0) || false;
			}
			if (field === 'templePartsColor') {
				const templeParts = productList.find((product) => product.id === methods.watch('temple'))?.connectParts;
				return (templeParts && templeParts.length > 0) || false;
			}
		}
		return true;
	};

	return (
		<Dialog open={openFrame} onClose={handleClose} fullWidth maxWidth="lg">
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<DialogTitle>
						<Typography fontSize={24}>프레임주문</Typography>
					</DialogTitle>
					<DialogContent>
						<Box py={1}>
							<Grid container columnSpacing={1} rowSpacing={2}>
								{frameArray.map((input, index) => {
									const { type, field, label, options, grid } = input;
									switch (type) {
										case 'text':
											return <TextInput key={field} type={type} grid={grid} field={field} label={label} />;
										case 'autoComplete':
											return <AutoCompleteInput key={field} type={type} grid={grid} field={field} label={label} />;
										case 'singleSelect':
											if (watchCondition(field)) {
												if (
													field === 'templeLength' &&
													!productList.find((product) => product.id === methods.watch('temple'))?.templeLength
												) {
													return <TextInput key={field} type={type} grid={grid} field={field} label={label} />;
												}
												return (
													<SingleSelectInput
														key={field}
														grid={grid}
														field={field}
														options={options}
														label={label}
														type={type}
														size="small"
													/>
												);
											}
											return null;
										case 'multiSelect':
											return (
												<MultiSelect
													key={field}
													type={type}
													grid={grid}
													options={options}
													field={field}
													label={label}
												/>
											);
										case 'blank':
											// eslint-disable-next-line react/no-array-index-key
											return <Grid key={`${type}_${index}`} item xs={grid} />;
										case 'longText':
											return <LongTextInput key={field} field={field} type={type} grid={grid} label={label} />;
										case 'date':
											return <DateInput key={field} type={type} grid={grid} field={field} size="small" label={label} />;
										case 'img':
											return <ImageInput key={field} type={type} grid={grid} field={field} label={label} />;
										case 'ref':
											if (!options) return null;
											return <ReqArrayInput key={field} options={options} grid={grid} type={type} />;
										default:
											return null;
									}
								})}
							</Grid>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={handleClose}>
							취소
						</Button>
						<Button variant="contained" type="submit">
							입력
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
}

export default FrameModal;
