import React, { useCallback, useEffect } from 'react';
import './App.css';
import { Container, Divider } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { Amplify, API, Hub } from 'aws-amplify';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import config from './aws-exports';
import Navigation from './components/Navigation';
import { loginUser, storeListState, staffListState, customFieldsState, productInfoListState } from './atom';
import { checkUser, getStore, getStaff, getCustomFields } from './query';
import { StoreType, StaffType, StaffResponse, StoreResponse, ProductInfoResponse } from './util/Interface';
import ErrorCheck from './util/ErrorCheck';
import * as subscriptions from './graphql/subscriptions';
import { convertProductInfo, convertStaff, convertStore } from './util/ReplaceValue';
import getProductInfos from './query/getProductInfos';

function App(props: { children: React.ReactNode }) {
	const { children } = props;
	const navigate = useNavigate();
	const [user, setUser] = useRecoilState(loginUser);
	const [storeList, setStoreList] = useRecoilState(storeListState);
	const [staffList, setStaffList] = useRecoilState(staffListState);
	const [customFields, setCustomFields] = useRecoilState(customFieldsState);
	const [productInfoList, setProductInfoList] = useRecoilState(productInfoListState);

	const onCreateStaff = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateStaff,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					if (staffList) {
						const item = value.data.onCreateStaff as StaffResponse;
						const newStaffArray = convertStaff([item]);
						setStaffList([...staffList, ...newStaffArray]);
					}
				},
				error: (err: any) => {
					if (user) {
						ErrorCheck(err);
						window.location.reload();
					}
				},
			}),
		[setStaffList, staffList, user]
	);

	const onUpdateStaff = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateStaff,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateStaff as StaffResponse;
					if (item.status !== 'valid') {
						if (staffList) {
							const newArray = [...staffList];
							const index = newArray.findIndex((staff) => staff.id === item.id);
							if (index > -1) newArray.splice(index, 1);
							setStaffList(newArray);
						}
					} else if (staffList) {
						const newArray = [...staffList];
						const index = newArray.findIndex((staff) => staff.id === item.id);
						const staff = convertStaff([item])[0];
						newArray[index] = staff;
						setStaffList([...newArray]);
					}
				},
				error: (err: any) => {
					if (user) {
						ErrorCheck(err);
					}
				},
			}),
		[setStaffList, staffList, user]
	);

	useEffect(() => {
		const create = onCreateStaff();
		const update = onUpdateStaff();
		return () => {
			create.unsubscribe();
			update.unsubscribe();
		};
	}, [onCreateStaff, onUpdateStaff]);

	const onCreateStore = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateStore,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					if (storeList) {
						const item = value.data.onCreateStore as StoreResponse;
						const newStore = convertStore([item]);
						setStoreList([...storeList, ...newStore]);
					}
				},
				error: (err: any) => {
					if (user) {
						ErrorCheck(err);
						window.location.reload();
					}
				},
			}),
		[setStoreList, storeList, user]
	);
	const onUpdateStore = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateStore,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateStore as StoreResponse;
					if (storeList) {
						const newArray = [...storeList];
						const index = newArray.findIndex((store) => store.id === item.id);
						const store = convertStore([item])[0];
						newArray[index] = store;
						setStoreList([...newArray]);
					}
				},
				error: (err: any) => {
					ErrorCheck(err);
				},
			}),
		[setStoreList, storeList]
	);

	useEffect(() => {
		const create = onCreateStore();
		const update = onUpdateStore();
		return () => {
			create.unsubscribe();
			update.unsubscribe();
		};
	}, [onCreateStore, onUpdateStore]);

	const onCreateProduct = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onCreateProductInfo,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					if (productInfoList) {
						const item = value.data.onCreateProductInfo as ProductInfoResponse;
						const newStore = convertProductInfo([item]);
						setProductInfoList([...productInfoList, ...newStore]);
					}
				},
				error: (err: any) => {
					if (user) {
						ErrorCheck(err);
						window.location.reload();
					}
				},
			}),
		[setProductInfoList, productInfoList, user]
	);

	const onUpdateProduct = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onUpdateProductInfo,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onUpdateProductInfo as ProductInfoResponse;
					if (productInfoList) {
						const newArray = [...productInfoList];
						const index = newArray.findIndex((productInfo) => productInfo.id === item.id);
						const product = convertProductInfo([item])[0];
						newArray[index] = product;
						setProductInfoList([...newArray]);
					}
				},
				error: (err: any) => {
					ErrorCheck(err);
				},
			}),
		[productInfoList, setProductInfoList]
	);

	const onDeleteProduct = useCallback(
		() =>
			(
				API.graphql({
					query: subscriptions.onDeleteProductInfo,
				}) as any
			).subscribe({
				next: ({ value }: any) => {
					const item = value.data.onDeleteProductInfo as ProductInfoResponse;
					const newArray = [...productInfoList];
					const index = newArray.findIndex((product) => product.id === item.id);
					if (index > -1) newArray.splice(index, 1);
					setProductInfoList(newArray);
				},
				error: (error: any) => {
					ErrorCheck(error);
				},
			}),
		[productInfoList, setProductInfoList]
	);

	useEffect(() => {
		const create = onCreateProduct();
		const update = onUpdateProduct();
		const remove = onDeleteProduct();
		return () => {
			create.unsubscribe();
			update.unsubscribe();
			remove.unsubscribe();
		};
	}, [onCreateProduct, onDeleteProduct, onUpdateProduct]);

	Amplify.configure(config);

	const signIn = useCallback(() => {
		checkUser(navigate)
			.then((staff: { username: string }) => {
				if (staff.username) {
					setUser(staff.username);
				}
			})
			.catch((err) => {
				ErrorCheck(err);
			});
		getStore().then((store: StoreType[] | null) => {
			if (store) {
				setStoreList(store);
			}
		});
		getStaff().then((staff: StaffType[] | null) => {
			if (staff) {
				setStaffList(staff);
			}
		});
		getCustomFields().then((customFieldsData) => {
			setCustomFields(customFieldsData);
		});
		getProductInfos().then((productInfoData) => {
			setProductInfoList(convertProductInfo(productInfoData));
		});
	}, [navigate, setCustomFields, setProductInfoList, setStaffList, setStoreList, setUser]);

	const listener = useCallback(
		(data: any) => {
			switch (data.payload.event) {
				case 'signIn':
					// set Staff table
					signIn();
					break;
				case 'signUp':
					console.log('user signed up');
					break;
				case 'signOut':
					navigate('/');
					break;
				case 'signIn_failure':
					console.log('user sign in failed');
					break;
				case 'configured':
					console.log('the Auth module is configured');
					break;
				default:
					console.log('Something went wrong, look at data object', data);
			}
		},
		[navigate, signIn]
	);

	useEffect(() => {
		Hub.listen('auth', listener);
		return () => {
			Hub.remove('auth', listener);
		};
	}, [listener]);

	useEffect(() => {
		if (user === null) {
			signIn();
		}
	}, [signIn, user]);

	LicenseInfo.setLicenseKey(`${process.env.REACT_APP_DATA_GRID_KEY}`);
	return (
		<>
			<Navigation />
			<Divider />
			<Container maxWidth="xl" sx={{ padding: '0!important' }}>
				{children}
			</Container>
		</>
	);
}

export default App;
