import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { openProductInfoState } from '../../atom/Atom';
import { customFieldsState, productDetailState } from '../../atom';
import { ProductInfoType } from '../../util/Interface';
import { checkCondition, toServerProductInfo } from '../../util/ReplaceValue';
import CustomFieldTextInput from '../form/CustomFieldTextInput';
import CustomFieldSingleSelect from '../form/CustomFieldSingleSelect';
import CustomFieldDateInput from '../form/CustomFieldDateInput';
import MapInput from '../form/MapInput';
import MutateSingleSelect from '../form/MutateSingleSelect';
import { createProductInfo, updateProductInfo, deleteProductInfo } from '../../query';
import ErrorCheck from '../../util/ErrorCheck';
import { fetchUser } from '../../selector';
import { CustomFieldMultiSelect } from '../form';

function ProductInfoModal() {
	const [openModal, setOpenModal] = useRecoilState(openProductInfoState);
	const customFields = useRecoilValue(customFieldsState);
	const defaultValues = useRecoilValue(productDetailState);
	const resetDefaultValues = useResetRecoilState(productDetailState);
	const methods = useForm<ProductInfoType>({ defaultValues, mode: 'all' });
	const userInfo = useRecoilValue(fetchUser);
	const { watch, reset } = methods;

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	const handleClose = () => {
		reset();
		resetDefaultValues();
		setOpenModal(false);
	};

	const onSubmit: SubmitHandler<ProductInfoType> = (data) => {
		if (!userInfo) return;
		const productInfoResponse = toServerProductInfo(data, userInfo.id);
		if (data.updatedAt || data.createdAt) {
			updateProductInfo(productInfoResponse)
				.then()
				.catch((err) => {
					ErrorCheck(err);
				});
		} else {
			createProductInfo(productInfoResponse)
				.then()
				.catch((err) => {
					ErrorCheck(err);
				});
		}
		handleClose();
	};

	const handleDelete = (id: string) => {
		deleteProductInfo(id).then();
		handleClose();
	};

	return (
		<Dialog open={openModal} fullWidth maxWidth="lg">
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography>{defaultValues.updatedAt ? '제품 수정' : '제품 등록'}</Typography>
					<Button onClick={handleClose}>
						<ClearOutlinedIcon color="action" />
					</Button>
				</Box>
			</DialogTitle>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid container spacing={2}>
							{customFields.map((input) => {
								const { type, field, label, option, blank, nullable, filter, fields, condition } = input;
								switch (type) {
									case 'singleLineText':
										if (condition) {
											if (checkCondition(condition, watch)) {
												return (
													<CustomFieldTextInput
														key={field}
														field={field}
														label={label}
														type={type}
														blank={blank}
														nullable={nullable}
													/>
												);
											}
											return null;
										}
										return (
											<CustomFieldTextInput
												key={field}
												field={field}
												label={label}
												type={type}
												blank={blank}
												nullable={nullable}
											/>
										);
									case 'singleSelect':
										if (!option) return null;
										if (condition) {
											if (checkCondition(condition, watch)) {
												return (
													<CustomFieldSingleSelect
														key={field}
														field={field}
														label={label}
														option={option}
														type={type}
														blank={blank}
														nullable={nullable}
													/>
												);
											}
											return null;
										}
										return (
											<CustomFieldSingleSelect
												key={field}
												field={field}
												label={label}
												option={option}
												type={type}
												blank={blank}
												nullable={nullable}
											/>
										);
									case 'multiSelect':
										if (condition) {
											if (checkCondition(condition, watch)) {
												return (
													<CustomFieldMultiSelect
														blank={blank}
														nullable={nullable}
														field={field}
														option={option}
														label={label}
														type={type}
													/>
												);
											}
										}
										return null;
									case 'datetime':
										return (
											<CustomFieldDateInput
												key={field}
												field={field}
												label={label}
												type={type}
												blank={blank}
												nullable={nullable}
											/>
										);
									case 'map':
										return (
											<MapInput
												field={field}
												key={field}
												blank={blank}
												nullable={nullable}
												fields={fields}
												label={label}
												type={type}
											/>
										);
									case 'mutateSingleSelect':
										return (
											<MutateSingleSelect
												key={field}
												blank={blank}
												nullable={nullable}
												field={field}
												label={label}
												filter={filter}
												type={type}
											/>
										);
									default:
										return null;
								}
							})}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" type="submit" color="primary">
							{defaultValues.updatedAt ? '수정' : '입력'}
						</Button>
						{defaultValues.updatedAt && (
							<Button variant="contained" color="primary" onClick={() => handleDelete(defaultValues.id)}>
								삭제
							</Button>
						)}
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
}

export default ProductInfoModal;
