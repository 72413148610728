import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Chip } from '@mui/material';

interface StoreChipProps {
	params: GridRenderCellParams;
}

function StoreChip(props: StoreChipProps) {
	const { params } = props;
	if (params.value?.includes('점')) {
		return <Chip sx={{ backgroundColor: '#D2DFFC' }} label={params.value} />;
	}
	if (params.value === '콥틱') {
		return <Chip sx={{ backgroundColor: '#F8D87E' }} label={params.value} />;
	}
	if (params.value === '더메이크') {
		return <Chip sx={{ backgroundColor: '#A4DE91' }} label={params.value} />;
	}
	return null;
}

export default StoreChip;
