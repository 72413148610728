import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Chip, Grid, MenuItem, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { InputType } from '../../util/Interface';
import { productInfoListState } from '../../atom';

function DynamicSingleSelect({ field, label, grid }: InputType) {
	const productList = useRecoilValue(productInfoListState);
	const {
		control,
		watch,
		resetField,
		formState: { errors },
	} = useFormContext();
	if (!field) return null;
	const makeOption = () => {
		const optionColor = [
			{ name: 'disc', color: '#CCCCCC' },
			{ name: 'mir', color: '#CCF4E9' },
			{ name: 'pol', color: '#F9DBF4' },
			{ name: 'prism', color: '#D6EFFB' },
			{ name: 'sc', color: '#EBE2FC' },
		];
		if (field === 'firstOption') {
			const firstOptions = productList
				.find((product) => product.id === watch().name)
				?.lensOptionArray?.filter((lensOption) => lensOption.pre)
				.filter((option) => option.pre.includes(watch().lensCoating))
				?.map((lensOption) => {
					const findLensOption = productList.find((product) => product.id === lensOption.oid);
					return {
						label: findLensOption?.label,
						value: findLensOption?.id,
					};
				});
			if (firstOptions && firstOptions.length !== 0) {
				firstOptions.push({ label: '취소', value: '' });
			}
			return firstOptions?.map((obj) => {
				const color = optionColor.find((colorObj) => colorObj.name === obj.value?.split('-')[1])?.color;
				return (
					<MenuItem key={obj.value} value={obj.value}>
						<Chip label={obj.label} style={{ backgroundColor: color }} />
					</MenuItem>
				);
			});
		}
		if (field === 'secondOption') {
			const secondOptions = productList
				.find((product) => product.id === watch().name)
				?.lensOptionArray?.filter((lensOption) => lensOption.pre)
				.filter((option) => option.pre.includes(watch().firstOption))
				?.map((lensOption) => {
					const findLensOption = productList.find((product) => product.id === lensOption.oid);
					return {
						label: findLensOption?.label,
						value: findLensOption?.id,
					};
				})
				?.filter((option) => option.value !== watch('firstOption'));
			if (secondOptions && secondOptions.length !== 0) {
				secondOptions.push({ label: '취소', value: '' });
			}
			return secondOptions?.map((obj) => {
				const color = optionColor.find((colorObj) => colorObj.name === obj.value?.split('-')[1])?.color;
				return (
					<MenuItem key={obj.value} value={obj.value}>
						<Chip label={obj.label} style={{ backgroundColor: color }} />
					</MenuItem>
				);
			});
		}
		if (field === 'thirdOption') {
			const thirdOptions = productList
				.find((product) => product.id === watch().name)
				?.lensOptionArray?.filter((lensOption) => lensOption.pre)
				.filter((option) => option.pre.includes(watch().firstOption) && option.pre.includes(watch().secondOption))
				?.map((lensOption) => {
					const findLensOption = productList.find((product) => product.id === lensOption.oid);
					return {
						label: findLensOption?.label,
						value: findLensOption?.id,
					};
				})
				?.filter((option) => option.value !== watch('firstOption') && option.value !== watch('secondOption'));
			if (thirdOptions && thirdOptions.length !== 0) {
				thirdOptions.push({ label: '취소', value: '' });
			}
			return thirdOptions?.map((obj) => {
				const color = optionColor.find((colorObj) => colorObj.name === obj.value?.split('-')[1])?.color;
				return (
					<MenuItem key={obj.value} value={obj.value}>
						<Chip label={obj.label} style={{ backgroundColor: color }} />
					</MenuItem>
				);
			});
		}

		if (field === 'baseColor') {
			const { name, firstOption, secondOption, thirdOption } = watch();
			const clickedLensCoating = productList
				?.find((product) => product.id === name)
				?.lensOptionArray?.find((lensOption) => {
					return lensOption.oid === watch().lensCoating;
				})
				?.baseColor?.map((colorId) => productList.find((product) => product.id === colorId))
				.map((option) => {
					return {
						id: option?.id,
						label: option?.label,
						colorCode: option?.colorCode,
					};
				});

			const clickedOption = productList
				?.find((product) => product.id === name)
				?.lensOptionArray?.find((lensOption) => {
					return lensOption.oid === firstOption || lensOption.oid === secondOption || lensOption.oid === thirdOption;
				})
				?.baseColor?.map((colorId) => {
					return productList.find((product) => product.id === colorId);
				})
				.map((option) => {
					return {
						value: option?.id,
						label: option?.label,
						colorCode: option?.colorCode,
					};
				});
			const clickedFirstOption = productList
				?.find((product) => product.id === name)
				?.lensOptionArray?.find((lensOption) => {
					return lensOption.oid === firstOption;
				})
				?.baseColor?.map((colorId) => {
					return productList.find((product) => product.id === colorId);
				})
				.map((option) => {
					return {
						value: option?.id,
						label: option?.label,
						colorCode: option?.colorCode,
					};
				});
			const clickedSecondOption = productList
				?.find((product) => product.id === name)
				?.lensOptionArray?.find((lensOption) => {
					return lensOption.oid === secondOption;
				})
				?.baseColor?.map((colorId) => {
					return productList.find((product) => product.id === colorId);
				})
				.map((option) => {
					return {
						value: option?.id,
						label: option?.label,
						colorCode: option?.colorCode,
					};
				});
			const clickedThirdOption = productList
				?.find((product) => product.id === name)
				?.lensOptionArray?.find((lensOption) => {
					return lensOption.oid === thirdOption;
				})
				?.baseColor?.map((colorId) => {
					return productList.find((product) => product.id === colorId);
				})
				.map((option) => {
					return {
						value: option?.id,
						label: option?.label,
						colorCode: option?.colorCode,
					};
				});
			if (clickedOption && clickedOption.length !== 0) {
				clickedOption.push({ label: '취소', value: '', colorCode: [] });
			}
			if (clickedLensCoating && clickedLensCoating.length !== 0) {
				clickedLensCoating.push({ label: '취소', id: '', colorCode: [] });
			}

			if (!clickedLensCoating) {
				return clickedOption?.map((obj) => {
					return (
						<MenuItem key={obj?.value} value={obj?.value}>
							<Box
								sx={{
									width: '25px',
									height: '25px',
									background:
										obj?.colorCode?.length && obj?.colorCode?.length > 1
											? `linear-gradient( to bottom, ${obj?.colorCode?.join(',')} )`
											: obj?.colorCode?.join(','),
									borderRadius: '15px',
									marginRight: '10px',
								}}
							/>
							{obj.label}
						</MenuItem>
					);
				});
			}
			console.log(clickedFirstOption, clickedSecondOption, clickedThirdOption);
			if (clickedFirstOption || clickedSecondOption || clickedThirdOption) {
				if (clickedFirstOption) {
					return clickedFirstOption?.map((obj) => {
						return (
							<MenuItem key={obj?.value} value={obj?.value}>
								<Box
									sx={{
										width: '25px',
										height: '25px',
										background:
											obj?.colorCode?.length && obj?.colorCode?.length > 1
												? `linear-gradient( to bottom, ${obj?.colorCode?.join(',')} )`
												: obj?.colorCode?.join(','),
										borderRadius: '15px',
										marginRight: '10px',
									}}
								/>
								{obj.label}
							</MenuItem>
						);
					});
				}
				if (clickedSecondOption) {
					return clickedSecondOption?.map((obj) => {
						return (
							<MenuItem key={obj?.value} value={obj?.value}>
								<Box
									sx={{
										width: '25px',
										height: '25px',
										background:
											obj?.colorCode?.length && obj?.colorCode?.length > 1
												? `linear-gradient( to bottom, ${obj?.colorCode?.join(',')} )`
												: obj?.colorCode?.join(','),
										borderRadius: '15px',
										marginRight: '10px',
									}}
								/>
								{obj.label}
							</MenuItem>
						);
					});
				}
				if (clickedThirdOption) {
					return clickedThirdOption?.map((obj) => {
						return (
							<MenuItem key={obj?.value} value={obj?.value}>
								<Box
									sx={{
										width: '25px',
										height: '25px',
										background:
											obj?.colorCode?.length && obj?.colorCode?.length > 1
												? `linear-gradient( to bottom, ${obj?.colorCode?.join(',')} )`
												: obj?.colorCode?.join(','),
										borderRadius: '15px',
										marginRight: '10px',
									}}
								/>
								{obj.label}
							</MenuItem>
						);
					});
				}
			}
			return clickedLensCoating?.map((obj) => {
				return (
					<MenuItem key={obj?.id} value={obj?.id}>
						<Box
							sx={{
								width: '25px',
								height: '25px',
								background:
									obj?.colorCode?.length && obj?.colorCode?.length > 1
										? `linear-gradient( to bottom, ${obj?.colorCode?.join(',')} )`
										: obj?.colorCode?.join(','),
								borderRadius: '15px',
								marginRight: '10px',
							}}
						/>
						{obj.label}
					</MenuItem>
				);
			});
		}
		if (field === 'mirColor') {
			const mirColorOptions = productList
				?.find((product) => product.id === watch().name)
				?.lensOptionArray?.find((lensOption) => lensOption.pre)
				?.mirColor?.map((colorId) => productList.find((product) => product.id === colorId));
			return mirColorOptions?.map((obj) => {
				return (
					<MenuItem key={obj?.id} value={obj?.id}>
						<Box
							sx={{
								width: '25px',
								height: '25px',
								background:
									obj?.colorCode?.length && obj?.colorCode?.length > 1
										? `linear-gradient( to bottom, ${obj?.colorCode?.join(',')} )`
										: obj?.colorCode?.join(','),
								borderRadius: '15px',
								marginRight: '10px',
							}}
						/>
						{obj?.label}
					</MenuItem>
				);
			});
		}
		return null;
	};

	const makeSelectProps = () => {
		return {
			renderValue: (obj: any) => {
				return productList.find((product) => product.id === obj)?.label;
			},
		};
	};

	if (!makeOption()?.length) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid } }) => {
					return (
						<TextField
							name={field}
							onChange={(e) => {
								if (field === 'firstOption') {
									resetField('secondOption');
									resetField('thirdOption');
								}
								if (field === 'secondOption') {
									resetField('thirdOption');
								}
								return onChange(e);
							}}
							value={value || ''}
							label={label}
							select
							inputRef={ref}
							onBlur={onBlur}
							size="small"
							SelectProps={makeSelectProps()}
							fullWidth
							error={invalid && Boolean(errors[`${field}`] || false)}
							helperText={invalid && errors[`${field}`] ? errors[`${field}`]?.message : ''}
						>
							{makeOption()}
						</TextField>
					);
				}}
			/>
		</Grid>
	);
}

export default DynamicSingleSelect;
