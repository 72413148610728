import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { customerArray } from '../../util/Array';
import { openCustomerState } from '../../atom/Atom';
import { customerState } from '../../atom';
import { CustomerType } from '../../util/Interface';
import { AddressInput, CheckBoxInput, DateInput, PhoneInput, SingleSelectInput, TextInput } from '../form';
import KakaoPost from './KakaoPost';
import { updateCustomer } from '../../query';
import { fetchUser } from '../../selector';
import ErrorCheck from '../../util/ErrorCheck';

function CustomerModal() {
	const [openCustomer, setOpenCustomer] = useRecoilState(openCustomerState);
	const userInfo = useRecoilValue(fetchUser);
	const defaultValues = useRecoilValue(customerState);

	const methods = useForm<CustomerType>({ defaultValues, mode: 'all' });
	const { reset } = methods;

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	const handleClose = () => {
		setOpenCustomer(false);
	};

	const onSubmit: SubmitHandler<CustomerType> = (data) => {
		updateCustomer(data, userInfo)
			.then(() => setOpenCustomer(false))
			.catch((err) => ErrorCheck(err));
	};

	return (
		<>
			<Dialog open={openCustomer} onClose={handleClose} fullWidth maxWidth="lg">
				<DialogTitle>고객정보</DialogTitle>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<DialogContent>
							<Box py={1}>
								<Grid container rowSpacing={2} columnSpacing={1}>
									{customerArray.map((row, index) => {
										const { grid, label, options, type, field } = row;
										switch (type) {
											case 'text':
												return (
													<TextInput key={field} type={type} grid={grid} field={field} label={label} size="small" />
												);
											case 'singleSelect':
												if (!options) return null;
												return (
													<SingleSelectInput
														key={field}
														type={type}
														grid={grid}
														field={field}
														options={options}
														label={label}
														size="small"
													/>
												);
											case 'address':
												return (
													<AddressInput key={field} type={type} grid={grid} field={field} label={label} size="small" />
												);
											case 'checkBox':
												return <CheckBoxInput key={field} type={type} grid={grid} field={field} label={label} />;
											case 'date':
												return (
													<DateInput key={field} type={type} grid={grid} field={field} label={label} size="small" />
												);
											case 'phone':
												return <PhoneInput key={field} type={type} grid={grid} field={field} label={label} />;
											case 'blank':
												// eslint-disable-next-line react/no-array-index-key
												return <Grid item key={`${field}_${index}`} xs={grid} />;
											default:
												return null;
										}
									})}
								</Grid>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>취소</Button>
							<Button type="submit">수정</Button>
						</DialogActions>
					</form>
				</FormProvider>
			</Dialog>
			<KakaoPost setValue={methods.setValue} />
		</>
	);
}

export default CustomerModal;
