import React from 'react';
import { Box, Typography } from '@mui/material';

interface SurveyChipProps {
	label: string;
}

function CheckedSurveyChip(props: SurveyChipProps) {
	const { label } = props;
	return (
		<Box sx={{ border: '1px solid #C9C9C9', borderRadius: '22px', px: 2, py: 1, background: '#0A3AB6' }}>
			<Typography fontWeight={600} color="#FFFFFF">
				{label}
			</Typography>
		</Box>
	);
}

export default CheckedSurveyChip;
