import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { useRecoilValue } from 'recoil';
import { InputType } from '../../util/Interface';
import { checkNFC, convertAsType, convertCustomType } from '../../util/ReplaceValue';
import { productInfoListState } from '../../atom';

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

function TextInput({ field, label, grid, variant }: InputType) {
	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext();
	const productList = useRecoilValue(productInfoListState);
	const disableCheck = (fieldName: string) => {
		const disableFieldList = ['customerNote', 'care'];
		if (disableFieldList.includes(fieldName)) {
			return {
				disabled: true,
			};
		}
		return {};
	};

	const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(function NumberFormatCustom(
		props,
		ref
	) {
		const { onChange, ...other } = props;

		return (
			<NumberFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator
				isNumericString
			/>
		);
	});

	const handleValue = (value: any) => {
		if (field === 'price') {
			const price =
				productList
					.find((product) => product.id === watch('name'))
					?.lensOptionArray?.find((option) => option.oid === watch('lensCoating'))?.price || '0';
			const firstOptionPrice = productList
				.find((product) => product.id === watch('name'))
				?.lensOptionArray?.find((option) => option.oid === watch('firstOption'))?.price;
			const firstOptionAdded = productList.find((product) => product.id === watch('firstOption'))?.added || 0;
			const secondOptionAdded = productList.find((product) => product.id === watch('secondOption'))?.added || 0;
			const thirdOptionAdded = productList.find((product) => product.id === watch('thirdOption'))?.added || 0;
			let resultPrice = String(
				Number(price) + Number(firstOptionAdded) + Number(secondOptionAdded) + Number(thirdOptionAdded)
			);
			if (firstOptionPrice) {
				resultPrice = String(Number(firstOptionPrice) + Number(secondOptionAdded) + Number(thirdOptionAdded));
			}
			if (watch('lensCategory') !== 'both') {
				resultPrice = String(Number(resultPrice) / 2);
			}
			if (resultPrice === '0') {
				return value || '';
			}
			return resultPrice;
		}
		if (field === 'customerNote') {
			const request = value?.request;
			const customType = convertCustomType(value?.customType);
			const asType = convertAsType(value?.asType);
			const smsg = value?.smsg;
			return `${[customType, asType, request, smsg].filter((el) => el).join(', ')}`;
		}
		return value || '';
	};

	if (!field) return null;
	return (
		<Grid item xs={grid}>
			<Controller
				name={field}
				control={control}
				defaultValue=""
				render={({ field: { value, onChange, ref, onBlur }, fieldState: { invalid, isTouched } }) => (
					<TextField
						name={field}
						variant={variant}
						onChange={(e) => {
							const { value: str } = e.target;
							e.target.value = checkNFC(str);
							onChange(e);
						}}
						value={handleValue(value)}
						label={label}
						size="small"
						inputRef={ref}
						onBlur={onBlur}
						fullWidth
						error={(invalid || isTouched) && Boolean(errors[`${field}`] || false)}
						helperText={(invalid || isTouched) && errors[`${field}`]?.message}
						InputProps={field !== 'price' ? disableCheck(field) : { inputComponent: NumberFormatCustom as any }}
					/>
				)}
			/>
		</Grid>
	);
}

export default TextInput;
