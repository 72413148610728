import React from 'react';
import { useRecoilValue } from 'recoil';
import { fetchUser } from '../selector';
import NoAuthPage from '../page/error/NoAuthPage';

function RequireStore({ children }: { children: JSX.Element }) {
	const userInfo = useRecoilValue(fetchUser);
	/* const admin = ['river', 'edward', 'andy', 'jaedy',]; */
	if (userInfo) {
		/* if (!(userInfo.storeType === 'store') && !admin.includes(userInfo.id)) {
			return <NoAuthPage />;
		} */
		return children;
	}
	return <NoAuthPage />;
}

export default RequireStore;
