import { API } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../graphql/queries';
import { ReserveType, ReservationResponse } from '../util/Interface';
import { convertReserve, makePhoneNumber } from '../util/ReplaceValue';

export type fetchReserveByCustomerQuery = {
	data: {
		listReservationByStoreWithReserveDateRange: {
			items: ReservationResponse[];
			nextToken: string;
		};
	};
};

const fetchReserveByCustomer = async (storeId: string, searchValue: string) => {
	const result: ReserveType[] = [];
	let token = '';
	let search = '';

	if (/^[0-9]+$/g.test(searchValue)) {
		search = makePhoneNumber(searchValue) || '';
	} else {
		search = searchValue;
	}
	const filter = {
		or: [
			{
				name: {
					contains: search,
				},
			},
			{
				phone: {
					contains: search,
				},
			},
		],
	};
	while (token != null) {
		const data = (await API.graphql({
			query: queries.listReservationByStoreWithReserveDateRange,
			variables: {
				storeId,
				filter,
				reserveDateFrom: moment('2019-01-01').toISOString(),
				reserveDateTo: moment('2040-01-01').toISOString(),
				limit: 100,
				nextToken: token === '' ? null : token,
			},
		})) as fetchReserveByCustomerQuery;
		token = data.data.listReservationByStoreWithReserveDateRange.nextToken;
		const value: ReserveType[] = convertReserve(data.data.listReservationByStoreWithReserveDateRange.items).filter(
			(reserve) => reserve.reserveType !== 'attention'
		);
		result.push(...value);
		if (token === null) {
			break;
		}
	}
	return result;
};

export default fetchReserveByCustomer;
